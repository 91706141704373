import { useAppDispatch, useAppSelector, } from '../../store/store';
import Step from '../../models/enums/Step';
import SellerEligibility from '../SellerEligibility/SellerEligibility';
import MessageTypeOptions from '../../models/enums/MessageTypeOptions';
import GeneralMessage from '../GeneralMessage/GeneralMessage';
import { useTranslation } from 'react-i18next';
import ProcessCompleted from '../ProcessCompleted/ProcessCompleted';
import LandingPage from '../LandingPage/LandingPage';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import LegalRepresentative from '../LegalRepresentative/LegalRepresentative';
import BeneficialOwner from '../BeneficialOwner/BeneficialOwner';
import Documents from '../Documents/Documents';
import VideoIdentification from "../VideoIdentification/VideoIdentification";
import Contract from '../FrameworkAgreement/FrameworkAgreement';
import Organisation from '../Organisation/Organisation';
import style from './Main.module.scss';
import { updateProcessId, updateStatus, updateStep } from '../../reducers/kycProcess';
import ProcessRejected from '../ProcessRejected/ProcessRejected';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthenticationClient } from '../../authentication/AuthenticationProvider';
import Loading from '../../componentsLibrary/Loading/Loading';
import userService from '../../services/user.service';
import { updateIsEligible, updatePreEligibilityStatus, updateSellerId } from '../../reducers/sellerEligibility';
import kycProcessService from '../../services/kycProcess.service';
import ProcessStatuses from '../../models/enums/ProcessStatuses';
import SellerPreEligibilityStatus from '../../models/enums/SellerPreEligibilityStatus';
import { ISellerPreEligibilityRequest } from '../../models/ISellerPreEligibilityRequest';
import sellerService from '../../services/seller.service';
import clientConfigurations from '../../configuration/clientConfigurations.json';

export default function Main() {
    const { step } = useAppSelector((state) => state.process);
    const { isEligible, sellerId } = useAppSelector((state) => state.sellerEligibility);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(true);
    const authenticationClient = useAuthenticationClient();
    
  
    useEffect(() => {
        setLoading(true);
       
        const sellerIdQueryParam: string = clientConfigurations.sellerIdQueryParam;
        const source: string = clientConfigurations.partnerSourceName;
        const sellerIdFromQueryParam = searchParams.get(sellerIdQueryParam);
    
        // If no seller in the route, redirect to 404
        if (!sellerIdFromQueryParam) {
            setLoading(false);
            return navigate('/not-found');
        }
    
        // If user not authorized for seller from url, show 404 page
        let currentUser = authenticationClient.getCurrentUser();
        //TODO: to be removed when check is done on the API for UI side
        userService.isAuthorized(currentUser?.email as string, sellerIdFromQueryParam).then((isAuthorized) => {
            if (isAuthorized) {
                // If seller from route different from the one in state, clear state
                if (sellerIdFromQueryParam !== sellerId) {
                    dispatch({ type: "CLEAR_STATE" });
                    dispatch(updateSellerId(sellerIdFromQueryParam));
                }

                // If we have a process, then seller is already eligible and preeligibile
                kycProcessService.getBySellerId(sellerIdFromQueryParam).then((response) => {
                    // Set preeligibility as true in state
                    dispatch(updatePreEligibilityStatus(SellerPreEligibilityStatus.Eligible));
                                    
                    // Set isEligible as true in state
                    dispatch(updateIsEligible(true));

                    // Set processId and process in state
                    dispatch(updateProcessId(response.processId));
                    dispatch(updateStatus(response.status));

                    if (response.status === ProcessStatuses.Rejected) {
                        dispatch(updateStep(Step.ProcessRejected));
                    }
                    else if (response.status === ProcessStatuses.Completed) {
                        dispatch(updateStep(Step.Organisation));
                    } else {
                        dispatch(updateStep(Step.LandingPage));
                    }
                }).catch((err) => {
                    if (err.response.status !== 404) {
                        setLoading(false);
                        return navigate('/not-found');
                    }
                        
                    // No process found 404, we check preEligibility
                    let sellerPreEligibilityRequest: ISellerPreEligibilityRequest = {
                        externalId: sellerIdFromQueryParam,
                        source
                    };
                    sellerService.get(sellerPreEligibilityRequest).then((status) => {       
                        dispatch(updatePreEligibilityStatus(status));
                    
                        // If seller pre-eligible, redirect to seller-eligibility page
                        if (status.toString() === "Eligible") {
                            dispatch(updateStep(Step.LandingPage));                    
                        } else if (status.toString() === "NotEligible") {
                            dispatch(updateStep(Step.PreEligibilityRejected));
                        } else {
                            dispatch(updateStep(Step.PreEligibilityPending));
                        }
                    }).catch((err) => {
                        // If seller pre-eligibility call fails, not-found
                        return navigate('/not-found');
                    }).finally(() =>  setLoading(false))
                });

            } else {
                setLoading(false);
                return navigate('/not-found');
            }
        }).catch((err) => {
            return navigate('/not-found');
        }).finally(() =>  setLoading(false));
    },[])

    return <>
        {step === Step.SellerEligibility && <SellerEligibility />}
        {step === Step.SellerEligibilityRejected && <GeneralMessage
            type={MessageTypeOptions.Warning}
            messages={[t('sellerEligibilityPage.warningMessage'), t('sellerEligibilityPage.apologyMessage')]}
            backButtonVisible={!isEligible}
            onBackClick={() => dispatch(updateStep(Step.SellerEligibility))}
        />}
        {step === Step.PreEligibilityPending && <GeneralMessage
            type={MessageTypeOptions.Warning}
            messages={[t('sellerEligibilityPage.sellerVerificationInProgress'), t('sellerEligibilityPage.pleaseReturnLater')]}
        />}
        {step === Step.PreEligibilityRejected && <GeneralMessage
            type={MessageTypeOptions.Warning}
            messages={[t('sellerEligibilityPage.youAreNotEligible'), t('sellerEligibilityPage.apologyMessage')]}
        />}
        {step === Step.ProcessCompleted &&  <ProcessCompleted />}
        {step === Step.ProcessRejected &&  <ProcessRejected />}
        {step === Step.LandingPage && <LandingPage />}
        {((step === Step.Organisation) 
            || (step === Step.LegalRepresentative) 
            || (step === Step.BeneficialOwner)
            || (step === Step.Document)
            || (step === Step.Video)
            || (step === Step.Contract)) && 
            <div className={style.main}>
                <NavigationMenu />
                {step === Step.Organisation && <Organisation />}
                {step === Step.LegalRepresentative && <LegalRepresentative />}
                {step === Step.BeneficialOwner && <BeneficialOwner />}
                {step === Step.Document && <Documents />}
                {step === Step.Video && <VideoIdentification />}
                {step === Step.Contract && <Contract />}
            </div>}
        <Loading show={loading} overlay />;
    </>
}