import clsx from 'clsx';
import style from './Form.module.scss';

export default function Form(props: any) {
    return (
        <form onSubmit={props.onSubmit} className={clsx(style.form, props.formClasses)}>
            {props.children}
        </form>
    )
};
