import ActionType from "./enums/ActionType";

export interface IBeneficialOwner {
  id: string,
  partnerOrganisationId: string,
  givenName: string,
  familyName: string,
  birthDate: string,
  nationality: string,
  votingShare: number,
  isLegalRepresentative: boolean,
  actionType?: ActionType,
}

export const NewBeneficialOwner: IBeneficialOwner = {
  birthDate: "",
  givenName: "",
  familyName: "",
  nationality: "",
  id: "",
  votingShare: 0,
  isLegalRepresentative: false,
  actionType: ActionType.Added,
  partnerOrganisationId: ""
}