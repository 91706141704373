//base component: https://github.com/reactjs/react-modal

import { FC, SelectHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import Button from '../Button/Button';

import style from './DialogModal.module.scss';

interface IModalProps extends SelectHTMLAttributes<HTMLSelectElement> {
    id: string,
    modalContentLabel?: string,
    info?: string,
    modalIsOpen: boolean,
    closeModal: any
}

const DialogModal: FC<IModalProps> = (props: IModalProps) => {
    const { t } = useTranslation();

    function createMarkup() {
        return { __html: t(props.info || "") }
    };

    return (
        <Modal
            id={props.id}
            isOpen={props.modalIsOpen}
            onRequestClose={props.closeModal}
            className={[style.content, style.overlay].join(" ")}
            contentLabel={props.modalContentLabel}
            overlayClassName={style.modalOverlay}
        >
            <span className={style.modalContent} dangerouslySetInnerHTML={createMarkup()}></span>
            <div className={style.modalFooter}>
                <Button onClick={props.closeModal}>
                    {t('general.close')}
                </Button>
            </div>
        </Modal>
    )
};
export default DialogModal;
