import IAuthenticationClient from "../../models/IAuthenticationClient";
import IUser from "../../models/IUser";

class AzureAuthenticationClient implements IAuthenticationClient {
   
    isAuthenticated(): boolean {
        return false;
    }

    getCurrentUser(): IUser | undefined {
        return undefined;
    }

    login(): void {
        
    }

    logout(): void {
      
    }

    getToken(): string | undefined {
        return undefined;
    }

    updateToken(successCallback: any): any {
       return successCallback;
    }
}

export default AzureAuthenticationClient;