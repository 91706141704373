enum ProcessStatuses {
  /// Process started, pending User action.
  Draft = 0,

  /// Data submitted by User
  ConfirmedByUser = 1,

  /// Data submitted to Solaris.
  SubmittedToProvider = 2,

  /// Legal indetification passed. pending Video Identifications.
  LegalIdentificationPassed = 3,

  /// Approved By Solaris. Legal and Video Indentifications passed.
  ApprovedByProvider = 4,

  /// Process Rejected or Expired.
  Rejected = 5,

  /// After the Framework agreement was signed. Process considered Completed.
  Completed = 6,

  /// New process not yet created
  New = 7,
}

export default ProcessStatuses;
