import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik, FormikProps } from 'formik';
import { Accordion } from 'react-accessible-accordion';
import { isEqual } from 'lodash';

import { useAppSelector, useAppDispatch } from '../../store/store';
import { updateStep } from '../../reducers/kycProcess';
import { processBeneficialOwners } from '../../reducers/beneficialOwner';

import ButtonType from '../../models/enums/ButtonType';
import Step from '../../models/enums/Step';
import { IBeneficialOwners } from '../../models/IBeneficialOwners';
import { IBeneficialOwner, NewBeneficialOwner } from '../../models/IBeneficialOwner';
import { AtLeastOneUboRuleError, VotingShareRuleError, VotingShareRuleForFullPercentageError, validationSchemaForBeneficialOwnersPage as validationSchema } from '../../validations/beneficialOwnersPageValidations';
import { IBeneficialOwnersList } from '../../models/IBeneficialOwnersList';

import BeneficialOwnerItem from '../BeneficialOwnerItem/BeneficialOwnerItem';
import FormPage from '../../componentsLibrary/FormPage/FormPage';
import Form from '../../componentsLibrary/Form/Form';
import FormFooter from '../../componentsLibrary/Form/FormFooter';
import Button from '../../componentsLibrary/Button/Button';
import SwitchBox from '../../componentsLibrary/SwitchBox/SwitchBox';
import organisationService from '../../services/organisation.service';
import beneficialOwnerService from '../../services/beneficialOwner.service';
import Loading from '../../componentsLibrary/Loading/Loading';
import { mapToListModel } from '../../mappers/beneficialOwner.mapper';
import { sanitizeObject } from '../../helpers/helpers';

import style from './BeneficialOwner.module.scss';

export default function BeneficialOwner() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    let [organisationId, setOrganisationId] = useState("");
    const { processId, dataReadonly } = useAppSelector((state) => state.process);
    const { sellerId } = useAppSelector((state) => state.sellerEligibility);
    const [loading, setLoading] = useState(true);
    let [beneficialOwnerList, setBeneficialOwnersList] = useState<IBeneficialOwners>({ beneficialOwners: [], contractsOutsideCompany: false, partnerOrganisationId: sellerId as string });
    let [beneficialOwnersToBeRemoved, setBeneficialOwnersToBeRemoved] = useState<IBeneficialOwner[]>([]);

    useEffect(() => {
        setLoading(true);

        beneficialOwnerService.getAll(processId).then((response) => {
            let payload = mapToListModel(response);
            let ubos: IBeneficialOwners = structuredClone(payload) as IBeneficialOwners;
            if (!ubos) {
                ubos = {
                    beneficialOwners: [],
                    contractsOutsideCompany: false,
                    partnerOrganisationId: sellerId as string // check we need to fetch org
                };
            }

            if (ubos.beneficialOwners.length === 0) {
                ubos.beneficialOwners.push(NewBeneficialOwner);
            }

            ubos.contractsOutsideCompany ??= false;

            organisationService.get(processId).then((response) => {
                setOrganisationId(response.id);
            });

            setBeneficialOwnersList(ubos);
            setLoading(false);
        }).catch((err) => {
            toast.error(err.response?.data?.title);
        }).finally(() => setLoading(false));
    }, []);


    function addBeneficialOwner(props: FormikProps<IBeneficialOwners>) {
        return () => {
            props.values.beneficialOwners = [...props.values.beneficialOwners, NewBeneficialOwner]
            props.setValues(props.values);
        };
    }

    function removeBeneficialOwner(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, props: FormikProps<IBeneficialOwners>, index: number, arrayHelpers: any): void {
        event.stopPropagation();

        if (dataReadonly) {
            return;
        }
        if (props.values.beneficialOwners[index].id) {
            beneficialOwnersToBeRemoved.push(props.values.beneficialOwners[index]);
            setBeneficialOwnersToBeRemoved(beneficialOwnersToBeRemoved);
        }

        arrayHelpers.remove(index);
    }

    function navigateToLegalRepPage() {
        dispatch(updateStep(Step.LegalRepresentative));
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>, props: FormikProps<IBeneficialOwners>): void {
        event.preventDefault();
        props.handleSubmit(event);

        //Rule: at least one UBO must have voting share percentage >= 25%
        if (props.errors.beneficialOwners === VotingShareRuleError) {
            toast.error(t('notificationMessages.votingShareRuleNotValid'));
        }

        //Rule: there should be at least one UBO
        if (props.errors.beneficialOwners === AtLeastOneUboRuleError) {
            toast.error(t('notificationMessages.atLeastOneBeneficialOwner'));
        }

        //Rule: Sum of all percentages should be maximum 100
        if (props.errors.beneficialOwners === VotingShareRuleForFullPercentageError) {
            toast.error(t('notificationMessages.sumOfAllPercentagesShouldBeMaximum100'));
        }
    }

    const onSubmit = (beneficialOwners: IBeneficialOwners): void => {
        if (dataReadonly) {
            dispatch(updateStep(Step.Document));
            return;
        }

        setLoading(true);

        if (isEqual(beneficialOwnerList, beneficialOwners)) {
            dispatch(updateStep(Step.Document));
            setLoading(false);
            return;
        }

        let request: IBeneficialOwnersList = {
            beneficialOwners,
            beneficialOwnersToBeRemoved: beneficialOwnersToBeRemoved,
            processId,
            organisationId
        };

        let requestSanitized = sanitizeObject(request) as IBeneficialOwnersList;

        dispatch(processBeneficialOwners(requestSanitized)).then(() => {
            dispatch(updateStep(Step.Document));
            return;
        }).catch((err) => {
            toast.error(`${t("general.somethingWentWrong")} ${t("notificationMessages.pleaseTryAgain")}`);
        })
            .finally(() => setLoading(false));
    }

    return <div className={style.beneficialOwner}>
        <Formik
            initialValues={beneficialOwnerList}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                onSubmit(values);
                actions.setSubmitting(false);
            }}
            enableReinitialize={true}
        >
            {props => {
                return (
                    <FormPage title={t('beneficialOwnerPage.title')} info={t('beneficialOwnerPage.info')} titleClasses={clsx(style.title)}>
                        <Loading show={loading} />
                        <Button type={ButtonType.Button} onClick={addBeneficialOwner(props)} classes={clsx(style.addButton, { [style.disabled]: dataReadonly })} disabled={dataReadonly} >
                            <span className={style.buttonLabel}>{t('beneficialOwnerPage.addBeneficialOwner')}</span>
                        </Button>
                        <Form onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event, props)} formClasses={style.beneficialOwnerForm} disabled={dataReadonly}>
                            <Accordion className={style.accordion} allowMultipleExpanded allowZeroExpanded preExpanded={[0]}>
                                <FieldArray name="beneficialOwners">
                                    {({ ...arrayHelpers }) => (
                                        <>
                                            {!loading &&
                                                props.values.beneficialOwners.map((beneficialOwner: IBeneficialOwner, index: number) => {
                                                    return <BeneficialOwnerItem
                                                        BeneficialOwner={beneficialOwner}
                                                        index={index}
                                                        beneficialOwnersCount={props.values.beneficialOwners.length}
                                                        removeBeneficialOwner={removeBeneficialOwner}
                                                        key={index}
                                                        formikProps={props}
                                                        arrayHelpers={arrayHelpers}
                                                        disabled={dataReadonly}
                                                    />;
                                                })}
                                        </>
                                    )}
                                </FieldArray>
                            </Accordion>
                            {props.values.beneficialOwners.length > 0 &&
                                <div className={style.row}>
                                    <span className={style.label}>{t("beneficialOwnerPage.contractsOutsideCompany")}</span>
                                    <div className={style.switchBoxWrapper}>
                                        <p className={style.inline}>{t('general.no')}</p>
                                        <SwitchBox checked={props.values.contractsOutsideCompany} name="contractsOutsideCompany" id="contractsOutsideCompany" sliderStyle={style.slider} onChange={props.handleChange} disabled={dataReadonly} />
                                        <p className={style.inline}>{t('general.yes')}</p>
                                    </div>
                                </div>
                            }
                            <FormFooter>
                                <Button type={ButtonType.Button} onClick={navigateToLegalRepPage} color="secondary">{t('general.back')}</Button>
                                <Button type={ButtonType.Submit}>{t('general.next')}</Button>
                            </FormFooter>
                        </Form>
                    </FormPage>
                )
            }}
        </Formik>
    </div>
}