import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import TypeOfRepresentationOptions from "../models/enums/TypeOfRepresentationOptions";
import { getKeyByValue } from "../helpers/helpers";

export const validationSchemaForLegalRepresentativePage = yup.object().shape({
  legalRepresentatives: yup.array().of(
    yup.object().shape({
      salutation: yup
        .string()
        .required("notificationMessages.thisFieldIsRequired"),
      familyName: yup
        .string()
        .required("notificationMessages.thisFieldIsRequired"),
      givenName: yup
        .string()
        .required("notificationMessages.thisFieldIsRequired"),
      nationality: yup
        .string()
        .required("notificationMessages.thisFieldIsRequired"),
      birthCity: yup
        .string()
        .required("notificationMessages.thisFieldIsRequired"),
      birthDate: yup
        .date()
        .required("notificationMessages.thisFieldIsRequired")
        .nullable(),
      address: yup.object().shape({
        postalCode: yup
          .number()
          .typeError("general.onlyNumbersAllowed")
          .required("notificationMessages.thisFieldIsRequired"),
        city: yup
          .string()
          .required("notificationMessages.thisFieldIsRequired") 
          .matches(
            /^\p{L}+(-?\s?\p{L}+)*$/u,
            "notificationMessages.thisFieldAcceptsOnlyLettersHyphensAndSpaces"
          ),
        countryCode: yup
          .string()
          .required("notificationMessages.thisFieldIsRequired"),
        streetName: yup
          .string()
          .required("notificationMessages.thisFieldIsRequired"),
        streetNumber: yup
          .string()
          .required("notificationMessages.thisFieldIsRequired"),
      }),
      email: yup
        .string()
        .nullable()
        .email("notificationMessages.emailIsNotValid")
        .required("notificationMessages.thisFieldIsRequired"),
      mobileNumber: yup
        .string()
        .required("notificationMessages.thisFieldIsRequired")
        .nullable()
        .test(
          "mobileNumber",
          "notificationMessages.phoneNumberIsNotValid",
          (value) => isValidPhoneNumber(value ?? "")
        ),
      representationType: yup
        .string()
        .required("notificationMessages.thisFieldIsRequired")
        .test(
          "representationType",
          "notificationMessages.onlyAloneValueIsCurrentlySupported",
          (value) =>
            value ===
            getKeyByValue(
              TypeOfRepresentationOptions.ALONE,
              TypeOfRepresentationOptions
            )
        ),
    })
  ),
});
