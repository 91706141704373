import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./index";
import documentsService from "../services/documents.service";
import IDocuments from '../models/IDocuments';
import IDocumentRequest from '../models/IDocumentRequest';
import IDocument from '../models/IDocument';

const initialState: IDocuments = {
  documents: [],
  contractsOutsideCompany: false,
  kycProcessId: "",
  extraDocuments: false
};

export const getAllMappedDocuments = createAsyncThunk(
  "documents/getAllMappedDocuments",
  async (request: IDocumentRequest) => {
    return await documentsService.getAllMappedDocuments(request);
  }
);

export const deleteFile = createAsyncThunk(
  "documents/delete",
  async (documentId: string) => {
    return await documentsService.delete(documentId);
  }
);

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    updateDocument: (state, action: PayloadAction<IDocument>) => {
      const updateModel = action.payload;

      let document = state.documents.find((document) => document.documentType === updateModel.documentType);
      if (document === undefined) {
        return;
      }

      const index = state.documents.findIndex((document) => document.documentType === updateModel.documentType);
      state.documents[index] = updateModel;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMappedDocuments.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      return state;
    });
  },
});

export const { updateDocument } = documentsSlice.actions;

export const selectDocuments = (state: RootState) => state.documents;

export default documentsSlice.reducer;
