import clsx from 'clsx';
import { ChangeEvent } from 'react';
import style from './SwitchBox.module.scss';

interface IPropsSwitchBox {
    id: string,
    checked: boolean,
    onChange?(e: ChangeEvent<HTMLInputElement>): any | undefined,
    sliderStyle?: string,
    name: string,
    inputStyle?: string,
    disabled?: boolean
}

const SwitchBox = (props: IPropsSwitchBox) => {
    return <label className={style.switch}>
        <input 
            type="checkbox" 
            id={props.id} 
            checked={props.checked} 
            onChange={props.onChange} 
            name={props.name} 
            className={clsx(style.input, props.inputStyle, {[style.disabled]: props.disabled})} 
            disabled={props.disabled}
        />
        <span className={clsx(style.slider, style.round, props.sliderStyle, {[style.disabled]: props.disabled})}></span>
    </label>
};

export default SwitchBox;