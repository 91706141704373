import clsx from 'clsx';
import style from './Section.module.scss';

export default function FormSection(props: any) {
    return (
        <div className={clsx(style.section, props.sectionClasses)}>
            <p className={clsx(style.title, props.sectionTitleClasses)}>{props.title}</p>
            <div className={clsx(style.details, props.sectionDetailClasses)}>
                {props.children}
            </div>
        </div>
    )
};
