import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Formik, FormikProps } from 'formik';
import { isEqual } from 'lodash';

import { useAppSelector, useAppDispatch } from '../../store/store';
import { getAzureAddress } from '../../reducers/azureAddress';
import { updateStep, updateProcessId } from '../../reducers/kycProcess';
import { validateAddress } from '../../validations/validations';
import { validationSchemaForOrganisation as validationSchema } from '../../validations/organisationPageValidations';
import { filterSectorsByLegalForm } from '../../helpers/sectorOptionsFilteredValues';
import organisationService from '../../services/organisation.service';

import Step from '../../models/enums/Step';
import TaxCountryOptions from '../../models/enums/TaxCountryOptions';
import CrsCompanyTypeOptions from '../../models/enums/CrsCompanyTypeOptions';
import { NaceCodeOptions } from '../../models/enums/NaceCodeOptions';
import RegistrationIssuerOptions from '../../models/enums/RegistrationIssuerOptions';
import CountryOptions from '../../models/enums/CountryOptions';
import ButtonType from '../../models/enums/ButtonType';
import { IOrganisation } from '../../models/IOrganisation';
import { IAzureAddressResponse } from '../../models/IAzureAddress';
import NumberOfCustomerOptions from '../../models/enums/NumberOfCustomerOptions';
import Format from '../../models/enums/Format';
import Symbol from '../../models/enums/Symbol';
import GermanLegalForms from '../../models/enums/GermanLegalForms';

import { IMultiselectOption, ISelectOption } from '../../models/IMultiselectOption';

import Form from '../../componentsLibrary/Form/Form';
import FormPage from '../../componentsLibrary/FormPage/FormPage';
import FormFooter from '../../componentsLibrary/Form/FormFooter';
import Column from '../../componentsLibrary/Column/Column';
import TextInput from '../../componentsLibrary/TextInput/TextInput';
import Dropdown from '../../componentsLibrary/Dropdown/Dropdown';
import Calendar from '../../componentsLibrary/Calendar/Calendar';
import TextArea from '../../componentsLibrary/TextArea/TextArea';
import Button from '../../componentsLibrary/Button/Button';
import FormSection from '../../componentsLibrary/Section/Section';
import CustomMultiselect from '../../componentsLibrary/Multiselect/CustomMultiselect';
import IOrganisationResponse from '../../models/IOrganisationResponse';
import { mapToModel, mapToPostRequest, mapToPutRequest } from '../../mappers/organisation.mapper';
import Loading from '../../componentsLibrary/Loading/Loading';
import { sanitizeObject } from '../../helpers/helpers';
import { AddressValidationResult } from '../../models/IAddress';

export default function Organisation() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    let { dataReadonly } = useAppSelector((state) => state.process);
    const { processId } = useAppSelector((state) => state.process);
    const { sellerId } = useAppSelector((state) => state.sellerEligibility);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredSectorOptions, setFilteredSectorOptions] = useState<string[]>([]);
    let [organisation, setOrganisation] = useState<IOrganisation>({
        address: {
            city: "",
            countryCode: "",
            streetName: "",
            streetNumber: "",
            postalCode: "",
        },
        hasOutsideAgreements: false,
        outsideAgreementsAnsweredAt: "",
        processId: "",
        crsCompanyType: "",
        nrOfCustomers: "",
        foundationDate: "",
        legalForm: "",
        naceCode: "",
        name: "",
        registrationIssuer: "",
        registrationNumber: "",
        sector: "",
        taxCountryCode: "",
        businessPurpose: "",
        combinedMonthlyLoanInstallments: "",
        lastYearGrossProfit: "",
        turnover: "",
        taxConfirmation: "",
        partnerOrganisationId: ""
    });

    const taxConfirmation = "SINGLE";

    useEffect(() => {
        setLoading(true);
        dispatch(updateStep(Step.Organisation));

        if (processId) {
            organisationService.get(processId).then((organisation: IOrganisationResponse) => {
                setOrganisation(mapToModel(organisation));
                let sectorOptionValues: string[] = filterSectorsByLegalForm(organisation.legalForm);
                setFilteredSectorOptions(sectorOptionValues);
            })
                .catch((error) => toast.error("general.somethingWentWrong"))
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    async function onSubmit(organisationModel: IOrganisation) {
        if (dataReadonly) {
            dispatch(updateStep(Step.LegalRepresentative));
            return;
        }
        setLoading(true);

        if (isEqual(organisation, organisationModel)) {
            dispatch(updateStep(Step.LegalRepresentative));
            setLoading(false);
            return;
        }

        organisationModel.processId = processId;
        organisationModel.taxConfirmation = taxConfirmation;
        organisationModel.partnerOrganisationId = (organisationModel.partnerOrganisationId === "" ? sellerId : organisationModel.partnerOrganisationId) as string

        let organisationSanitized = sanitizeObject(organisationModel) as IOrganisation;

        dispatch(getAzureAddress(organisationSanitized.address)).then((responseAddress) => {
            let validatedAddress: AddressValidationResult = validateAddress(organisationSanitized.address, responseAddress.payload as IAzureAddressResponse, responseAddress.type);

            if (!!!validatedAddress.isValid) {
                setLoading(false);

                //we want to display the error, then the info, so the following order (info, then error) counts, as they are dispalyed in reverse order
                if (validatedAddress.address !== null) {
                    toast.info(`${t('notificationMessages.theBestMatchWeFound')}: 
                    ${validatedAddress.address.postalCode}, 
                    ${validatedAddress.address.streetName} 
                    ${validatedAddress.address.streetNumber}, 
                    ${validatedAddress.address.city}, 
                    ${CountryOptions[organisationSanitized.address.countryCode.toLocaleUpperCase()]}`); //TODO: replace this with returned API value once we implement the translations on CountryOptions values; we need to take the value from CountryOptions instead of the API one, as we do not translate CountryOptions values yet
                }

                toast.error(t('notificationMessages.addressNotFound') as string);
                return;
            }
            if (organisationSanitized?.id) {
                let organisationPutRequest = mapToPutRequest(organisationSanitized);
                organisationService.put(organisationPutRequest).then((org) => {
                    dispatch(updateProcessId(org.processId));
                    dispatch(updateStep(Step.LegalRepresentative));
                    return;
                }).catch((err) => toast.error(`${t("general.somethingWentWrong")} ${t("notificationMessages.pleaseTryAgain")}`))
                    .finally(() => setLoading(false));
            } else {
                let organisationPostRequest = mapToPostRequest(organisationSanitized);
                organisationService.post(organisationPostRequest).then((org) => {
                    dispatch(updateProcessId(org.processId));
                    dispatch(updateStep(Step.LegalRepresentative));
                    return;
                }).catch((err) => toast.error(`${t("general.somethingWentWrong")} ${t("notificationMessages.pleaseTryAgain")}`))
                    .finally(() => setLoading(false));
            }
        });
    };

    const onLegalFormChange = (event: React.ChangeEvent<HTMLSelectElement>, formikProps: FormikProps<IOrganisation>) => {
        let sectorOptionValues: string[] = filterSectorsByLegalForm(event.target.value);
        setFilteredSectorOptions(sectorOptionValues);
        formikProps.setFieldValue('sector', Object.keys(sectorOptionValues)[0])
        formikProps.handleChange(event);
    }

    function onNaceCodeInputChange(selectedItem: ISelectOption, formikProps: FormikProps<IOrganisation>): void {
        formikProps.setFieldValue("naceCode", selectedItem?.value ?? "");
    }

    function getNaceCodeOptions(naceCode: string) {
        let result: any = null;

        NaceCodeOptions.find((n) => {
            const option = n.options.find((m) => m.value === naceCode);

            if (option) {
                result = option;
            }
        }
        );

        return result;
    }

    const maximumDate = ()=>{
        let date = new Date();
        date.setUTCFullYear(date.getUTCFullYear() - 2);
        return date;
    }

    return (
        <Formik
            initialValues={organisation}
            validationSchema={validationSchema}
            validateOnChange={!dataReadonly}
            validateOnBlur={!dataReadonly}
            onSubmit={(values, actions) => {
                onSubmit(values);
                actions.setSubmitting(false);
            }}
            enableReinitialize={true}
        >
            {props => {
                return (
                    <FormPage title={t('organisationPage.title')}>
                        <Loading show={loading} />
                        <Form onSubmit={(event: any) => props.handleSubmit(event)}>
                            <FormSection title={t('organisationPage.details')}>
                                <Column>
                                    <TextInput
                                        id='name'
                                        name='name'
                                        label={t('organisationPage.companyName')}
                                        value={props.values.name}
                                        maxLength={255}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.name) && props.touched.name}
                                        errorMessage={t(props.errors.name as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <CustomMultiselect
                                        id='naceCode'
                                        name='naceCode'
                                        label={t('organisationPage.naceCode.label')}
                                        options={NaceCodeOptions}
                                        info={t('organisationPage.naceCode.info')}
                                        value={getNaceCodeOptions(props.values.naceCode) as IMultiselectOption}
                                        onChange={event => onNaceCodeInputChange(event, props)}
                                        error={Boolean(props.errors.naceCode) && props.touched.naceCode}
                                        errorMessage={t(props.errors.naceCode as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <Dropdown
                                        id='legalForm'
                                        name='legalForm'
                                        label={t('organisationPage.legalForm.label')}
                                        options={GermanLegalForms}
                                        info={t('organisationPage.legalForm.info')}
                                        value={props.values.legalForm}
                                        onChange={event => onLegalFormChange(event, props)}
                                        error={Boolean(props.errors.legalForm) && props.touched.legalForm}
                                        errorMessage={t(props.errors.legalForm as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <Dropdown
                                        id='sectorCode'
                                        name='sector'
                                        label={t('organisationPage.sector.label')}
                                        options={filteredSectorOptions}
                                        info={t('organisationPage.sector.info')}
                                        value={props.values.sector}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.sector) && props.touched.sector}
                                        errorMessage={t(props.errors.sector as string)}
                                        required
                                        selectTooltip={props.values.legalForm === "" ? t('organisationPage.sector.tooltip') : ""}
                                        disabled={props.values.legalForm === "" || dataReadonly}
                                    />
                                </Column>
                                <Column>
                                    <TextArea
                                        id='purpose'
                                        name='businessPurpose'
                                        label={t('organisationPage.businessPurpose.label')}
                                        info={t('organisationPage.businessPurpose.info')}
                                        value={props.values.businessPurpose}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.businessPurpose) && props.touched.businessPurpose}
                                        errorMessage={t(props.errors.businessPurpose as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <Calendar
                                        id='foundationDate'
                                        name='foundationDate'
                                        label={t('organisationPage.foundationDate.label')}
                                        info={t('organisationPage.foundationDate.info')}
                                        value={props.values.foundationDate || null}
                                        onChange={(value) => {
                                            props.setFieldValue('foundationDate', value);
                                        }}
                                        error={Boolean(props.errors.foundationDate) && props.touched.foundationDate}
                                        errorMessage={t(props.errors.foundationDate as string)}
                                        required
                                        disabled={dataReadonly}
                                        maximumDate={maximumDate()}
                                    />
                                </Column>
                            </FormSection>
                            <FormSection title={t('organisationPage.headquarterInformation')}>
                                <Column>
                                    <TextInput
                                        id='address.postalCode'
                                        name='address.postalCode'
                                        label={t("general.postalCode")}
                                        maxLength={10}
                                        value={props.values.address.postalCode.toString()}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.address?.postalCode) && props.touched.address?.postalCode}
                                        errorMessage={t(props.errors.address?.postalCode as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <TextInput
                                        id='address.city'
                                        name='address.city'
                                        label={t("general.city")}
                                        maxLength={35}
                                        value={props.values.address.city.toString()}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.address?.city) && props.touched.address?.city}
                                        errorMessage={t(props.errors.address?.city as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <Dropdown
                                        id='address.country'
                                        name='address.countryCode'
                                        label={t("general.country")}
                                        options={CountryOptions}
                                        value={props.values.address.countryCode}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.address?.countryCode) && props.touched.address?.countryCode}
                                        errorMessage={t(props.errors.address?.countryCode as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                </Column>
                                <Column>
                                    <TextInput
                                        id='address.streetName'
                                        name='address.streetName'
                                        label={t("general.streetName")}
                                        maxLength={35}
                                        value={props.values.address.streetName}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.address?.streetName) && props.touched.address?.streetName}
                                        errorMessage={t(props.errors.address?.streetName as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <TextInput
                                        id='address.streetNumber'
                                        name='address.streetNumber'
                                        label={t("general.streetNumber")}
                                        maxLength={35}
                                        value={props.values.address.streetNumber}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.address?.streetNumber) && props.touched.address?.streetNumber}
                                        errorMessage={t(props.errors.address?.streetNumber as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                </Column>
                            </FormSection>
                            <FormSection title={t('organisationPage.registerAndTaxInformation')}>
                                <Column>
                                    <TextInput
                                        id='registrationNumber'
                                        name='registrationNumber'
                                        label={t('organisationPage.registrationNumber.label')}
                                        info={t('organisationPage.registrationNumber.info')}
                                        maxLength={60}
                                        value={props.values.registrationNumber}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.registrationNumber) && props.touched.registrationNumber}
                                        errorMessage={t(props.errors.registrationNumber as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <Dropdown
                                        id='registrationIssuer'
                                        name='registrationIssuer'
                                        label={t('organisationPage.registrationIssuer.label')}
                                        options={RegistrationIssuerOptions}
                                        info={t('organisationPage.registrationIssuer.info')}
                                        value={props.values.registrationIssuer}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.registrationIssuer) && props.touched.registrationIssuer}
                                        errorMessage={t(props.errors.registrationIssuer as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                </Column>
                                <Column>
                                    <Dropdown
                                        id='taxCountryCode'
                                        name='taxCountryCode'
                                        label={t('organisationPage.taxCountry.label')}
                                        options={TaxCountryOptions}
                                        info={t('organisationPage.taxCountry.info')}
                                        value={props.values.taxCountryCode}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.taxCountryCode) && props.touched.taxCountryCode}
                                        errorMessage={t(props.errors.taxCountryCode as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                </Column>
                            </FormSection>
                            <FormSection title={t('organisationPage.financialInformation')}>
                                <Column>
                                    <Dropdown
                                        id='nrOfCustomers'
                                        name='nrOfCustomers'
                                        label={t('organisationPage.nrOfCustomers.label')}
                                        info={t('organisationPage.nrOfCustomers.info')}
                                        options={NumberOfCustomerOptions}
                                        value={props.values.nrOfCustomers}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.nrOfCustomers) && props.touched.nrOfCustomers}
                                        errorMessage={t(props.errors.nrOfCustomers as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <TextInput
                                        id='sumOfCurrentBusinessYearLoanInstallments'
                                        name='combinedMonthlyLoanInstallments'
                                        label={t('organisationPage.sumOfCurrentBusinessYearLoanInstallments.label')}
                                        info={t('organisationPage.sumOfCurrentBusinessYearLoanInstallments.info')}
                                        value={props.values.combinedMonthlyLoanInstallments}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.combinedMonthlyLoanInstallments) && props.touched.combinedMonthlyLoanInstallments}
                                        errorMessage={t(props.errors.combinedMonthlyLoanInstallments as string)}
                                        required
                                        format={Format.Numeric}
                                        symbol={Symbol.Euro}
                                        disabled={dataReadonly}
                                    />
                                    <TextInput
                                        id='turnover'
                                        name='turnover'
                                        label={t('organisationPage.turnover.label')}
                                        info={t('organisationPage.turnover.info')}
                                        value={props.values.turnover}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.turnover) && props.touched.turnover}
                                        errorMessage={t(props.errors.turnover as string)}
                                        required
                                        format={Format.Numeric}
                                        symbol={Symbol.Euro}
                                        disabled={dataReadonly}
                                    />
                                </Column>
                                <Column>
                                    <Dropdown
                                        id='crsCompanyType'
                                        name='crsCompanyType'
                                        label={t('organisationPage.crsCompanyType.label')}
                                        options={CrsCompanyTypeOptions}
                                        info={t('organisationPage.crsCompanyType.info')}
                                        value={props.values.crsCompanyType}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.crsCompanyType) && props.touched.crsCompanyType}
                                        errorMessage={t(props.errors.crsCompanyType as string)}
                                        required
                                        disabled={dataReadonly}
                                    />
                                    <TextInput
                                        id='lastYearGrossProfit'
                                        name='lastYearGrossProfit'
                                        label={t('organisationPage.lastYearGrossProfit.label')}
                                        info={t('organisationPage.lastYearGrossProfit.info')}
                                        value={props.values.lastYearGrossProfit}
                                        onChange={props.handleChange}
                                        error={Boolean(props.errors.lastYearGrossProfit) && props.touched.lastYearGrossProfit}
                                        errorMessage={t(props.errors.lastYearGrossProfit as string)}
                                        required
                                        format={Format.Numeric}
                                        symbol={Symbol.Euro}
                                        disabled={dataReadonly}
                                    />
                                </Column>
                            </FormSection>
                            <FormFooter>
                                <Button type={ButtonType.Submit}>
                                    {t('general.next')}
                                </Button>
                            </FormFooter>
                        </Form>
                    </FormPage >
                )
            }}
        </Formik>
    )
}