import { getIsoDateTime, getRangeFromValue, getValueFromRange } from "../helpers/helpers";
import { IOrganisation } from "../models/IOrganisation";
import IOrganisationPostRequest from "../models/IOrganisationPostRequest";
import IOrganisationPutRequest from "../models/IOrganisationPutRequest";
import IOrganisationResponse from "../models/IOrganisationResponse";

export function mapToPostRequest(organisation: IOrganisation): IOrganisationPostRequest {
    let isoDateTime = getIsoDateTime(new Date(organisation.foundationDate));
 
    return {      
        partnerOrganisationId: `mdm://organisation@sparta/${organisation.partnerOrganisationId}`,
        name: organisation.name,
        sector: organisation.sector, 
        naceCode: organisation.naceCode,
        legalForm: organisation.legalForm, 
        foundationDate: isoDateTime,
        businessPurpose: organisation.businessPurpose,
        registrationNumber: organisation.registrationNumber,
        registrationIssuer: organisation.registrationIssuer, 
        taxCountryCode: organisation.taxCountryCode,
        taxConfirmation: organisation.taxConfirmation,
        address: organisation.address,
        turnover: Number(organisation.turnover?.replaceAll(" ","")),
        nrOfCustomers: getValueFromRange(organisation.nrOfCustomers),
        combinedMonthlyLoanInstallments: Number(organisation.combinedMonthlyLoanInstallments?.replaceAll(" ","")),
        monthlyGrossProfit: Number(organisation.lastYearGrossProfit?.replaceAll(" ","")),
        crsCompanyType: organisation.crsCompanyType,
        isFatcaRelevant: organisation.isFatcaRelevant
    }
}

export function mapToPutRequest(organisation: IOrganisation): IOrganisationPutRequest {
    let isoDateTime = getIsoDateTime(new Date(organisation.foundationDate));
   
    return {
        id: organisation.id as string,
        name: organisation.name,
        sector: organisation.sector,
        naceCode: organisation.naceCode,
        legalForm: organisation.legalForm,
        foundationDate: isoDateTime,
        businessPurpose: organisation.businessPurpose,
        registrationNumber: organisation.registrationNumber,
        registrationIssuer: organisation.registrationIssuer,
        taxCountryCode: organisation.taxCountryCode,
        taxConfirmation: organisation.taxConfirmation,
        address: organisation.address,
        turnover: Number(organisation.turnover?.replaceAll(" ","")),
        nrOfCustomers: getValueFromRange(organisation.nrOfCustomers),
        combinedMonthlyLoanInstallments: Number(organisation.combinedMonthlyLoanInstallments?.replaceAll(" ","")),
        monthlyGrossProfit: Number(organisation.lastYearGrossProfit?.replaceAll(" ","")),
        crsCompanyType: organisation.crsCompanyType,
        isFatcaRelevant: organisation.isFatcaRelevant
    }
}


export function mapToModel(organisation: IOrganisationResponse): IOrganisation {
    return {
        id: organisation.id,
        name: organisation.name,
        sector: organisation.sector,
        naceCode: organisation.naceCode,
        legalForm:  organisation.legalForm, 
        foundationDate: organisation.foundationDate,
        businessPurpose: organisation.businessPurpose,
        registrationNumber: organisation.registrationNumber,
        registrationIssuer: organisation.registrationIssuer,
        taxCountryCode: organisation.taxCountryCode,
        taxConfirmation: organisation.taxConfirmation,
        address: organisation.address,
        turnover: organisation.turnover?.toString(),
        nrOfCustomers: getRangeFromValue(organisation.nrOfCustomers),
        combinedMonthlyLoanInstallments: organisation.combinedMonthlyLoanInstallments?.toString(),
        lastYearGrossProfit: organisation.monthlyGrossProfit?.toString(),
        crsCompanyType: organisation.crsCompanyType,
        isFatcaRelevant: organisation.isFatcaRelevant,
        processId: organisation.processId,
        partnerOrganisationId: organisation.partnerOrganisationId
    }
}
