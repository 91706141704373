import { getIsoDateTime } from "../helpers/helpers";
import ActionType from "../models/enums/ActionType";
import { IBeneficialOwner } from "../models/IBeneficialOwner";
import IBeneficialOwnerGetAllResponse from "../models/IBeneficialOwnerGetAllResponse";
import IBeneficialOwnerPostRequest from "../models/IBeneficialOwnerPostRequest";
import IBeneficialOwnerPutRequest from "../models/IBeneficialOwnerPutRequest";
import IBeneficialOwnerResponse from "../models/IBeneficialOwnerResponse";
import { IBeneficialOwners } from "../models/IBeneficialOwners";

export function mapToPostRequest(beneficialOwner: IBeneficialOwner): IBeneficialOwnerPostRequest {
  let isoDateTime = getIsoDateTime(new Date(beneficialOwner.birthDate));

    return {
        votingShare: beneficialOwner.votingShare,
        givenName: beneficialOwner.givenName,
        familyName: beneficialOwner.familyName,
        birthDate: isoDateTime,
        nationality: beneficialOwner.nationality
    }
  }
  
  export function mapToPutRequest(beneficialOwner: IBeneficialOwner): IBeneficialOwnerPutRequest {
  let isoDateTime = getIsoDateTime(new Date(beneficialOwner.birthDate));

    return { 
        id: beneficialOwner.id,
        votingShare: beneficialOwner.votingShare,
        givenName: beneficialOwner.givenName,
        familyName: beneficialOwner.familyName,
        birthDate: isoDateTime,
        nationality: beneficialOwner.nationality
    };
  }
  
  export function mapToModel(beneficialOwner: IBeneficialOwnerResponse): IBeneficialOwner {
    return { 
        id: beneficialOwner.id,
        isLegalRepresentative: beneficialOwner.isLegalRepresentative,
        partnerOrganisationId: beneficialOwner.partnerOrganisationid,
        votingShare: beneficialOwner.votingShare ?? 0,
        givenName: beneficialOwner.givenName,
        familyName: beneficialOwner.familyName,
        birthDate: beneficialOwner.birthDate,
        nationality: beneficialOwner.nationality,
        actionType: ActionType.Updated
    };
  }
  
  export function mapToListModel(beneficialOwnerList: IBeneficialOwnerGetAllResponse): IBeneficialOwners {
    return {
        partnerOrganisationId: beneficialOwnerList.partnerOrganisationId,
        contractsOutsideCompany: beneficialOwnerList.contractsOutsideCompany,
        beneficialOwners: beneficialOwnerList.beneficialOwners.map(mapToModel)
    }
  }