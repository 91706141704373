import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';
import { ITimelineProgress, IStepProgress } from '../models/ITimelineProgress';

const initialState: ITimelineProgress = {
    Organisation: {
        completionPercentage: 0,
        isError: false,
    },
    LegalRepresentatives: {
        completionPercentage: 0,
        isError: false,
    },
    BeneficialOwners: {
        completionPercentage: 0,
        isError: false,
    },
    VideoIdentification: {
        completionPercentage: 0,
        isError: false,
    },
    Documents: {
        completionPercentage: 0,
        isError: false,
    },
    ContractSign: {
        completionPercentage: 0,
        isError: false,
    }
}

const timelineProgressSlice = createSlice({
    name: 'timelineProgress',
    initialState,
    reducers: {
        updateOrganisationProgress: (state, action: PayloadAction<IStepProgress>) => {
            state.Organisation = action.payload;
        },
        updateLegalRepresentativesProgress: (state, action: PayloadAction<IStepProgress>) => {
            state.LegalRepresentatives = action.payload;
        },
        updateBeneficialOwnersProgress: (state, action: PayloadAction<IStepProgress>) => {
            state.BeneficialOwners = action.payload;
        },
        updateDocumentsProgress: (state, action: PayloadAction<IStepProgress>) => {
            state.Documents = action.payload;
        },
        updateVideoIdentificationProgress: (state, action: PayloadAction<IStepProgress>) => {
            state.VideoIdentification = action.payload;
        },
        updateContractSignProgress: (state, action: PayloadAction<IStepProgress>) => {
            state.ContractSign = action.payload;
        },
    }
});

export const {updateOrganisationProgress,
    updateBeneficialOwnersProgress,
    updateLegalRepresentativesProgress,
    updateContractSignProgress,
    updateDocumentsProgress,
    updateVideoIdentificationProgress } = timelineProgressSlice.actions

export const selectTimelineProgress = (state: RootState) => state.timelineProgress

export default timelineProgressSlice.reducer