import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react';

import DialogModal from '../DialogModal/DialogModal';
import { toNumericSpacedFormat } from '../../helpers/helpers';
import Format from '../../models/enums/Format';
import Symbol from '../../models/enums/Symbol';

import InfoImage from '../../assets/img/info.png';
import style from './TextInput.module.scss';
import clsx from 'clsx';


interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string,
    info?: string,
    modalContentLabel?: string,
    value: any,
    maxLength?: number,
    name: string,
    disabled?: boolean,
    onChange?(e: ChangeEvent<HTMLInputElement>): any | undefined,
    error?: boolean,
    errorMessage?: string,
    inputClasses?: string,
    errorMessageClasses?: string,
    format?: Format,
    symbol?: Symbol
}

const TextInput: FC<IInputProps> = (props: IInputProps) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(props.error);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);
    const [symbol, setSymbol] = useState<string>();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function format(value: any) {
        if (props.format === Format.Numeric) {
            return toNumericSpacedFormat(value.trim());//output: 123 456 789
        }

        return value;
    }

    useEffect(() => {
        setError(props.error);

        if (props.errorMessage !== ' ' || props.errorMessage !== undefined) {
            setErrorMessage(props.errorMessage);
        }

        switch (props.symbol) {
            case Symbol.Euro: setSymbol(style.euroSymbol); break;
            case Symbol.Percentage: setSymbol(style.percentageSymbol); break;
            default: setSymbol(""); break;
        }
    }, [props.errorMessage, props.error]);

    return (<>
        <div className={style.inputWrapper} >
            <label htmlFor={props.id} id={props.id} className={style.label}>
                {props.label}
                {props.required &&
                    <span className={style.required}>*</span>
                }
                {props.info &&
                    <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
                }
            </label>
            <input
                id={props.id}
                readOnly={props.readOnly}
                name={props.name}
                placeholder={props.placeholder}
                className={clsx(style.input, style.symbols, symbol, props.inputClasses, error ? style.error : "")}
                value={format(props.value)}
                onChange={props.onChange}
                maxLength={props.maxLength}
                disabled={props.disabled}
            >
            </input>
        </div>
        {error && props.errorMessage !== ' ' && props.errorMessage !== undefined &&
            <span className={clsx(style.error, props.errorMessageClasses)}>{errorMessage}</span>
        }
        <DialogModal modalContentLabel={props.modalContentLabel} info={props.info || ""} id={"modalFor_" + props.id} modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </>
    )
};

export default TextInput;
