import IAddress from "./IAddress";
import ActionType from "./enums/ActionType";

export interface ILegalRepresentative {
  id: string,
  partnerUserId: string, // email of the logged in user
  birthCity: string,
  address: IAddress,
  email: string,
  mobileNumber: string,
  isBeneficialOwner: boolean,
  representationType: string,
  salutation: string,
  givenName: string,
  familyName: string,
  birthDate: string,
  nationality: string,
  actionType?: ActionType,
  isConductingKycProcess: boolean
}

export const NewLegalRepresentative: ILegalRepresentative = {
  address: {
    city: "",
    countryCode: "",
    streetName: "",
    streetNumber: "",
    postalCode: ""
  },
  birthCity: "",
  birthDate: "",
  email: "",
  mobileNumber: "",
  givenName: "",
  familyName: "",
  partnerUserId: "",
  representationType: "",
  salutation: "",
  nationality: "",
  isBeneficialOwner: false,
  isConductingKycProcess: false,
  id: "",
  actionType: ActionType.Added
}