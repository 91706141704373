import { useEffect, useState } from "react";
import Keycloak from "keycloak-js";
import { ReactKeycloakWebContext } from "./context";
import {
  AuthClientError,
  AuthClientEvent,
  AuthClientInitOptions,
  IKeycloakProviderProps,
} from "./types";


export default function KeycloakProvider(props: IKeycloakProviderProps) {
  const { children, authClient, LoadingComponent } = props;
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const defaultInitOptions: AuthClientInitOptions = {
    onLoad: "check-sso",
  };

  useEffect(() => {
    const { initOptions, authClient } = props;

    authClient.onReady = updateState("onReady");
    authClient.onAuthSuccess = updateState("onAuthSuccess");
    authClient.onAuthError = onError("onAuthError");
    authClient.onAuthRefreshSuccess = updateState("onAuthRefreshSuccess");
    authClient.onAuthRefreshError = onError("onAuthRefreshError");
    authClient.onAuthLogout = updateState("onAuthLogout");
    authClient.onTokenExpired = refreshToken("onTokenExpired");

    authClient
      .init({ ...defaultInitOptions, ...initOptions })
      .catch(onError("onInitError"));
  }, []);

  const onError = (event: AuthClientEvent) => (error?: AuthClientError) => {
    const { onEvent } = props;
    onEvent && onEvent(event, error);
  };

  const isUserAuthenticated = (authClient: Keycloak) => {
    return !!authClient.idToken && !!authClient.token;
  };

  const updateState = (event: AuthClientEvent) => () => {
    const { authClient, onEvent, onTokens, isLoadingCheck } = props;

    onEvent && onEvent(event);
    const loading = isLoadingCheck ? isLoadingCheck(authClient) : false;
    const authenticated = isUserAuthenticated(authClient);
    
    if (
      !initialized ||
      isAuthenticated !== authenticated ||
      isLoading !== loading
    ) {
      setInitialized(true);
      setIsAuthenticated(authenticated);
      setIsLoading(loading);
    }

    const { idToken, refreshToken, token } = authClient;
    onTokens && onTokens({ idToken, refreshToken, token });
  };

  const refreshToken = (event: AuthClientEvent) => () => {
    const { autoRefreshToken, authClient, onEvent } = props;

    onEvent && onEvent(event);

    if (autoRefreshToken !== false) {
      authClient.updateToken(5);
    }
  };

  return (
    <>
      {(!initialized || isLoading) && props.LoadingComponent}
      {initialized && !isLoading && (
        <ReactKeycloakWebContext.Provider value={{ initialized, authClient }}>
          {children}
        </ReactKeycloakWebContext.Provider>
      )}
    </>
  );
}
