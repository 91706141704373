import httpService from "./http.service";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../configuration/appInsigths";

class FrameworkAgreementService {
  async get(processId: string) {
    const url = `process/${processId}/frameworkAgreement`;
    return httpService
    .getAxiosClient()
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
      throw err;
    });
  }

  async patch(processId: string) {
    const url = `process/${processId}/frameworkAgreement/sign`;
    
    return httpService
      .getAxiosClient()
      .patch(url)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
        throw err;
      });
  }
}

const service = new FrameworkAgreementService();
export default service;
