import { SeverityLevel } from "@microsoft/applicationinsights-web";

import AuthenticationProviderTypes from "../models/enums/AuthenticationProviderTypes";
import IAuthenticationClient from "../models/IAuthenticationClient";

import { appInsights } from "../configuration/appInsigths";

import AzureAuthenticationClient from "./azure/AzureAuthenticationClient";

import KeycloakAuthenticationClient from "./keycloak/KeycloakAuthenticationClient";
import keycloak from './keycloak/keycloak';

import Loading from '../componentsLibrary/Loading/Loading';
import httpService from '../services/http.service';
import KeycloakProvider from "./keycloak/KeycloakProvider";


const keycloakProviderInitConfig = {
    onLoad: 'check-sso',
    useNonce: false,
    enableLogging: true,
    pkceMethod: 'S256',
    checkLoginIframe: false
}

const eventLogger = (event: any, error: any) => {
    if (event === 'onTokenExpired') {
        appInsights.trackException({ error: error, severityLevel: SeverityLevel.Warning });
        authenticationClient.updateToken(() => { appInsights.trackTrace({ message: "Token expired was updated", severityLevel: SeverityLevel.Information });});
    }
    else if (event === 'onInitError' || event === 'onAuthError' || event === 'onAuthRefreshError') {
        appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
    } else {
        appInsights.trackTrace({ message: event, severityLevel: SeverityLevel.Information });
    }
}

const tokenLogger = (tokens: any) => {
    appInsights.trackTrace({ message: tokens, severityLevel: SeverityLevel.Information });
}

let authenticationClient: IAuthenticationClient = new KeycloakAuthenticationClient();

interface IAuthenticationProviderProps {
    children: React.ReactNode
}

export default function AuthenticationProvider(props: IAuthenticationProviderProps) {
    let provider = (window as any).env.ProviderType  ?? AuthenticationProviderTypes.KEYCLOAK;
    let isAuthenticationEnabled: boolean = (window as any).env.Authentication === '1';
    
    httpService.configure();
    if (!isAuthenticationEnabled) {
        return <>{props.children}</>;
    }

    switch (provider) {
        case AuthenticationProviderTypes.KEYCLOAK: {
            authenticationClient = new KeycloakAuthenticationClient();

            return <KeycloakProvider 
                        authClient={keycloak} 
                        initOptions={keycloakProviderInitConfig} 
                        LoadingComponent={<Loading show={true} overlay={true} />}         
                        onEvent={eventLogger}  
                        autoRefreshToken={true}        
                        onTokens={tokenLogger} >
                            {props.children}
                    </KeycloakProvider>;
        } 
        case AuthenticationProviderTypes.AZURE: {
            authenticationClient = new AzureAuthenticationClient();
            return <>  {props.children}</>;
        } 
        default: {
            authenticationClient = new KeycloakAuthenticationClient();
            return <KeycloakProvider 
                        authClient={keycloak} 
                        initOptions={keycloakProviderInitConfig} 
                        LoadingComponent={<Loading show={true} overlay={true}/>}         
                        onEvent={eventLogger}    
                        autoRefreshToken={true}         
                        onTokens={tokenLogger} >
                        {props.children}
                 </KeycloakProvider>;
        } 
    }
} 

export const useAuthenticationClient = () => {
    return authenticationClient;
};