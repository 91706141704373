import { useTranslation } from 'react-i18next';

import Button from '../../../componentsLibrary/Button/Button';

import SuccessImage from '../../../assets/img/success.png';
import style from './SuccessfulVideoIdentification.module.scss';
import {  updateStep } from '../../../reducers/kycProcess';
import Step from '../../../models/enums/Step';
import { useAppDispatch } from '../../../store/store';
import ButtonType from '../../../models/enums/ButtonType';

export default function SuccessfulVideoIdentification() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const moveToNextStep = () => {
        dispatch(updateStep(Step.Contract));
    };

    const onBack = () => {
        dispatch(updateStep(Step.Document));
    };

    return <div className={style.main}>
         <div className={style.header}>
            <p className={style.title}>{t("successfulVideoIdentificationPage.title")}</p>
        </div>   
        <div className={style.content}>
            <img src={SuccessImage} alt="success" className={style.image} />
            <div className={style.description}>{t('successfulVideoIdentificationPage.description')}</div> 
        </div>
        <div className={style.footer}>
            <div className={style.buttonSection}>
              <Button onClick={onBack} type={ButtonType.Button} color="secondary">
                {t("general.back")}
              </Button>
              <Button onClick={moveToNextStep}>Next</Button>
            </div>
        </div> 
    </div>
}