enum Step {
    SellerEligibility = 0,
    Organisation = 1,
    LegalRepresentative = 2,
    BeneficialOwner = 3,
    Document = 4,
    Video = 5,
    Contract = 6,
    ProcessCompleted = 7,
    ProcessRejected = 8,
    PreEligibilityPending = 9,
    PreEligibilityRejected = 10,
    SellerEligibilityRejected = 11,
    LandingPage = 12
}

export default Step;