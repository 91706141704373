import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { FormikErrors, FormikProps } from 'formik';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { ILegalRepresentative } from '../../models/ILegalRepresentative';
import CountryOptions from '../../models/enums/CountryOptions';
import SalutationOptions from '../../models/enums/SalutationOptions';
import TypeOfRepresentationOptions from '../../models/enums/TypeOfRepresentationOptions';

import Calendar from '../../componentsLibrary/Calendar/Calendar';
import TextInput from '../../componentsLibrary/TextInput/TextInput';
import Dropdown from '../../componentsLibrary/Dropdown/Dropdown';
import SwitchBox from '../../componentsLibrary/SwitchBox/SwitchBox';
import PhoneInput from '../../componentsLibrary/PhoneInput/PhoneInput';
import Section from '../../componentsLibrary/Section/Section';
import Column from '../../componentsLibrary/Column/Column';
import DialogModal from '../../componentsLibrary/DialogModal/DialogModal';

import InfoImage from '../../assets/img/info.png';
import 'react-phone-number-input/style.css';
import style from './LegalRepresentativeItem.module.scss';
import { ILegalRepresentatives } from '../../models/ILegalRepresentatives';

export interface ILegalRepresentativeItemProps {
    legalRepresentative: ILegalRepresentative
    index: number,
    legalRepresentativesCount: number,
    removeLegalRepresentative?: any,
    accountEmail: string,
    formikProps: FormikProps<ILegalRepresentatives>,
    arrayHelpers?: any,
    disabled: boolean
}

export default function LegalRepresentativeItem(props: ILegalRepresentativeItemProps) {
    const { t } = useTranslation();
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function onChange(event: ChangeEvent<HTMLInputElement>, formikProps: FormikProps<ILegalRepresentatives>): void {
        formikProps.handleChange(event);
    }

    function onPhoneInputChange(event: any): void {
        props.formikProps.setFieldValue(`legalRepresentatives.${props.index}.mobileNumber`, event);
    }

    function onDropdownChange(event: ChangeEvent<HTMLSelectElement>, formikProps: FormikProps<ILegalRepresentatives>): void {
        formikProps.handleChange(event);
    }

    function onCalendarChange(event: Date | null): void {
        props.formikProps.setFieldValue(`legalRepresentatives.${props.index}.birthDate`, event);
    }

    const maximumDate = ()=>{
        let date = new Date();
        date.setUTCFullYear(date.getUTCFullYear() - 18);
        return date;
    }

    return <div className={style.legalRepresentativeItem}>
        <AccordionItem uuid={props.index} className={style.accordionItem}>
            <AccordionItemHeading className={style.accordionHeading}>
                <AccordionItemButton className={style.accordionButton}>
                    <div className={style.itemButtonContent}>
                        <span className={style.left}>
                            {
                                props.legalRepresentative.givenName || props.legalRepresentative.familyName ?
                                    `${props.legalRepresentative.givenName} ${props.legalRepresentative.familyName}` :
                                    t("notificationMessages.pleaseFillInAllTheDetails")
                            }
                        </span>
                        {((props.legalRepresentativesCount > 1 && props.legalRepresentative.email !== props.accountEmail) && !props.disabled) &&
                            <span
                                className={style.trash}
                                onClick={event => props.removeLegalRepresentative(event, props.formikProps, props.index, props.arrayHelpers)}>
                            </span>
                        }
                    </div>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={style.accordionPanel}>
                <div className={style.content}>
                    <Section title={t('legalRepresentativePage.personalInformation.title')}>
                        <Column>
                            <Dropdown
                                name={`legalRepresentatives.${props.index}.salutation`}
                                id={`legalRepresentatives.${props.index}.salutation`}
                                label={t('legalRepresentativePage.personalInformation.salutation.label')}
                                options={SalutationOptions}
                                onChange={event => onDropdownChange(event, props.formikProps)}
                                value={props.legalRepresentative.salutation}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.salutation) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.salutation}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.salutation as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                            <TextInput
                                name={`legalRepresentatives.${props.index}.givenName`}
                                id={`legalRepresentatives.${props.index}.givenName`}
                                label={t('legalRepresentativePage.personalInformation.firstName.label')}
                                maxLength={50}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.legalRepresentative.givenName}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.givenName) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.givenName}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.givenName as string)}
                                required
                                errorMessageClasses={style.error}
                                info={t("legalRepresentativePage.personalInformation.firstName.info")}
                                disabled={props.disabled}
                            />

                            <TextInput
                                name={`legalRepresentatives.${props.index}.familyName`}
                                id={`legalRepresentatives.${props.index}.familyName`}
                                label={t('legalRepresentativePage.personalInformation.lastName')}
                                maxLength={50}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.legalRepresentative.familyName}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.familyName) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.familyName}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.familyName as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                        </Column>
                        <Column>
                            <Dropdown
                                name={`legalRepresentatives.${props.index}.nationality`}
                                id={`legalRepresentatives.${props.index}.nationality`}
                                label={t('legalRepresentativePage.personalInformation.nationality')}
                                options={CountryOptions}
                                onChange={event => onDropdownChange(event, props.formikProps)}
                                value={props.legalRepresentative.nationality}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.nationality) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.nationality}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.nationality as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                            <TextInput
                                name={`legalRepresentatives.${props.index}.birthCity`}
                                id={`legalRepresentatives.${props.index}.birthCity`}
                                label={t('legalRepresentativePage.personalInformation.birthCity')}
                                maxLength={50}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.legalRepresentative.birthCity}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.birthCity) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.birthCity}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.birthCity as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                            <Calendar
                                name={`legalRepresentatives.${props.index}.birthDate`}
                                id={`legalRepresentatives.${props.index}.birthDate`}
                                label={t('legalRepresentativePage.personalInformation.birthDate.label')}
                                value={props.legalRepresentative.birthDate}
                                onChange={onCalendarChange}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.birthDate) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.birthDate}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.birthDate as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                                maximumDate={maximumDate()}
                                info={t("legalRepresentativePage.personalInformation.birthDate.info")}
                            />
                        </Column>
                    </Section>
                    <Section title={t('legalRepresentativePage.personalAddress.title')}>
                        <Column>
                            <TextInput
                                name={`legalRepresentatives.${props.index}.address.postalCode`}
                                id={`legalRepresentatives.${props.index}.address.postalCode`}
                                label={t('general.postalCode')}
                                maxLength={10}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.legalRepresentative.address.postalCode}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.postalCode) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.address?.postalCode}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.postalCode as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                            <TextInput
                                name={`legalRepresentatives.${props.index}.address.city`}
                                id={`legalRepresentatives.${props.index}.address.city`}
                                label={t('general.city')}
                                maxLength={35}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.legalRepresentative.address.city}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.city) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.address?.city}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.city as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                            <Dropdown
                                name={`legalRepresentatives.${props.index}.address.countryCode`}
                                id={`legalRepresentatives.${props.index}.address.countryCode`}
                                label={t('general.country')}
                                options={CountryOptions}
                                onChange={event => onDropdownChange(event, props.formikProps)}
                                value={props.legalRepresentative.address.countryCode}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.countryCode) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.address?.countryCode}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.countryCode as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                            <TextInput
                                name={`legalRepresentatives.${props.index}.address.streetName`}
                                id={`legalRepresentatives.${props.index}.address.streetName`}
                                label={t('general.streetName')}
                                maxLength={35}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.legalRepresentative.address.streetName}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.streetName) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.address?.streetName}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.streetName as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                            <TextInput
                                name={`legalRepresentatives.${props.index}.address.streetNumber`}
                                id={`legalRepresentatives.${props.index}.address.streetNumber`}
                                label={t('general.streetNumber')}
                                maxLength={35}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.legalRepresentative.address.streetNumber}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.streetNumber) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.address?.streetNumber}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.address?.streetNumber as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                        </Column>
                        <Column>
                            <TextInput
                                name={`legalRepresentatives.${props.index}.email`}
                                id={`legalRepresentatives.${props.index}.email`}
                                label={t('legalRepresentativePage.personalAddress.email')}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.legalRepresentative.email}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.email) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.email}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.email as string)}
                                disabled={props.legalRepresentative.email === props.accountEmail || props.disabled}
                                required
                                errorMessageClasses={style.error}
                            />
                            <PhoneInput
                                name={`legalRepresentatives.${props.index}.mobileNumber`}
                                id={`legalRepresentatives.${props.index}.mobileNumber`}
                                label={t('legalRepresentativePage.personalAddress.mobileNumber.label')}
                                onChange={event => onPhoneInputChange(event)}
                                value={props.legalRepresentative.mobileNumber}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.mobileNumber) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.mobileNumber}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.mobileNumber as string)}
                                required
                                errorMessageClasses={style.error}
                                info={t('legalRepresentativePage.personalAddress.mobileNumber.info')}
                                disabled={props.disabled}
                            />
                        </Column>
                    </Section>
                    <Section title={t('legalRepresentativePage.legalInformation.title')}>
                        <Column>
                            <Dropdown
                                name={`legalRepresentatives.${props.index}.representationType`}
                                id={`legalRepresentatives.${props.index}.representationType`}
                                label={t('legalRepresentativePage.legalInformation.typeOfRepresentation.label')}
                                options={TypeOfRepresentationOptions}
                                onChange={event => onDropdownChange(event, props.formikProps)}
                                value={props.legalRepresentative.representationType}
                                error={Boolean(props.formikProps.errors.legalRepresentatives &&
                                    (props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.representationType) &&
                                    props.formikProps.touched.legalRepresentatives && props.formikProps.touched.legalRepresentatives[props.index]?.representationType}
                                errorMessage={props.formikProps.errors.legalRepresentatives && 
                                    t((props.formikProps.errors.legalRepresentatives[props.index] as FormikErrors<ILegalRepresentative>)?.representationType as string)}
                                required
                                errorMessageClasses={style.error}
                                disabled={props.disabled}
                            />
                        </Column>
                        <Column>
                            <div className={style.row}>
                                <span className={style.label}>
                                    {t("legalRepresentativePage.legalInformation.isBeneficialOwner.label")}
                                    <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
                                </span>
                                <div className={style.switchBoxWrapper}>
                                    <p className={style.inline}>{t('general.no')}</p>
                                    <SwitchBox
                                        name={`legalRepresentatives.${props.index}.isBeneficialOwner`}
                                        id={`legalRepresentatives.${props.index}.isBeneficialOwner`}
                                        checked={props.legalRepresentative.isBeneficialOwner}
                                        sliderStyle={style.slider}
                                        onChange={event => { onChange(event, props.formikProps) }}
                                        inputStyle={style.input}
                                        disabled={props.disabled}
                                    />
                                    <p className={style.inline}>{t('general.yes')}</p>
                                </div>
                            </div>
                        </Column>
                    </Section>
                </div>
            </AccordionItemPanel>
        </AccordionItem>
        <DialogModal info={t("legalRepresentativePage.legalInformation.isBeneficialOwner.info")} id={"modalFor_legalRep"} modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </div>
}