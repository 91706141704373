import clsx from 'clsx';

import style from './Checkbox.module.scss';

interface ICheckboxProps {
    checked: boolean
    classes?: object,
    disabled?: boolean,
    id?: string,
    inputProps?: object,
    value?: string | number
    required?: boolean
    children?: any
    onChange: any
}


export default function Checkbox(props: ICheckboxProps) {
    return <div className={style.container}>
        <input id={props.id}
            type="checkbox"
            className={clsx(style.checkbox, style.primary, { [style.disabled]: props.disabled }, props.classes)}
            disabled={props.disabled ?? false}
            value={props.value}
            checked={props.checked}
            onChange={props.onChange}
            required={props.required}
            {...props.inputProps}
        />
        {props.required &&
            <span className={style.required}> * </span>
        }
        {props.children}
    </div>;
}