import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion';
import { FormikErrors, FormikProps } from 'formik';

import CountryOptions from '../../models/enums/CountryOptions';
import { IBeneficialOwner } from '../../models/IBeneficialOwner';
import { IBeneficialOwners } from '../../models/IBeneficialOwners';
import Symbol from '../../models/enums/Symbol';

import Calendar from '../../componentsLibrary/Calendar/Calendar';
import TextInput from '../../componentsLibrary/TextInput/TextInput';
import Dropdown from '../../componentsLibrary/Dropdown/Dropdown';
import Section from '../../componentsLibrary/Section/Section';
import Column from '../../componentsLibrary/Column/Column';
import DialogModal from '../../componentsLibrary/DialogModal/DialogModal';

import InfoImage from '../../assets/img/info_white.png';
import 'react-phone-number-input/style.css';
import style from './BeneficialOwnerItem.module.scss';

export interface IBeneficialOwnerItemProps {
    BeneficialOwner: IBeneficialOwner,
    index: number,
    beneficialOwnersCount: number,
    removeBeneficialOwner?: any,
    formikProps: FormikProps<IBeneficialOwners>,
    arrayHelpers?: any,
    disabled: boolean
}

export default function BeneficialOwnerItem(props: IBeneficialOwnerItemProps) {
    const { t } = useTranslation();
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function onChange(event: ChangeEvent<HTMLInputElement>, formikProps: FormikProps<IBeneficialOwners>): void {
        formikProps.handleChange(event);
    }

    function onDropdownChange(event: ChangeEvent<HTMLSelectElement>, formikProps: FormikProps<IBeneficialOwners>): void {
        formikProps.handleChange(event);
    }

    function onCalendarChange(event: Date | null): void {
        props.formikProps.setFieldValue(`beneficialOwners.${props.index}.birthDate`, event);
    }

    const maximumDate = () => {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        return date;
    }

    return <div className={style.beneficialOwnerItem}>
        <AccordionItem uuid={props.index} className={style.accordionItem}>
            <AccordionItemHeading className={style.accordionHeading}>
                <AccordionItemButton className={style.accordionButton}>
                    <div className={style.itemButtonContent}>
                        <span className={style.left}>
                            {
                                props.BeneficialOwner.givenName || props.BeneficialOwner.familyName ?
                                    `${props.BeneficialOwner.givenName} ${props.BeneficialOwner.familyName}` :
                                    t("notificationMessages.pleaseFillInAllTheDetails")
                            }
                            {
                                props.BeneficialOwner.isLegalRepresentative &&
                                <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
                            }
                        </span>
                        {
                            (!props.BeneficialOwner.isLegalRepresentative && (props.beneficialOwnersCount > 1)) && !props.disabled &&
                            <span
                                className={style.trash}
                                onClick={event => props.removeBeneficialOwner(event, props.formikProps, props.index, props.arrayHelpers)}>
                            </span>
                        }
                    </div>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={style.accordionPanel}>
                <div className={style.content}>
                    <Section title={t('beneficialOwnerPage.personalInformation.title')}>
                        <Column>
                            <TextInput
                                name={`beneficialOwners.${props.index}.givenName`}
                                id={`beneficialOwners.${props.index}.givenName`}
                                label={t('beneficialOwnerPage.personalInformation.firstName')}
                                maxLength={50}
                                onChange={event => onChange(event, props.formikProps)}
                                disabled={props.BeneficialOwner.isLegalRepresentative || props.disabled}
                                value={props.BeneficialOwner.givenName}
                                error={Boolean(props.formikProps.errors.beneficialOwners &&
                                    (props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.givenName) &&
                                    props.formikProps.touched.beneficialOwners && props.formikProps.touched.beneficialOwners[props.index]?.givenName}
                                errorMessage={props.formikProps.errors.beneficialOwners && t((props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.givenName as string)}
                                required
                                errorMessageClasses={style.error}
                            />
                            <TextInput
                                name={`beneficialOwners.${props.index}.familyName`}
                                id={`beneficialOwners.${props.index}.familyName`}
                                label={t('beneficialOwnerPage.personalInformation.lastName')}
                                maxLength={50}
                                onChange={event => onChange(event, props.formikProps)}
                                disabled={props.BeneficialOwner.isLegalRepresentative || props.disabled}
                                value={props.BeneficialOwner.familyName}
                                error={Boolean(props.formikProps.errors.beneficialOwners &&
                                    (props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.familyName) &&
                                    props.formikProps.touched.beneficialOwners && props.formikProps.touched.beneficialOwners[props.index]?.familyName}
                                errorMessage={props.formikProps.errors.beneficialOwners && t((props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.familyName as string)}
                                required
                                errorMessageClasses={style.error}
                            />
                        </Column>
                        <Column>
                            <Dropdown
                                name={`beneficialOwners.${props.index}.nationality`}
                                id={`beneficialOwners.${props.index}.nationality`}
                                label={t("beneficialOwnerPage.personalInformation.nationality")}
                                options={CountryOptions}
                                onChange={event => onDropdownChange(event, props.formikProps)}
                                disabled={props.BeneficialOwner.isLegalRepresentative || props.disabled}
                                value={props.BeneficialOwner.nationality}
                                error={Boolean(props.formikProps.errors.beneficialOwners &&
                                    (props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.nationality) &&
                                    props.formikProps.touched.beneficialOwners && props.formikProps.touched.beneficialOwners[props.index]?.nationality}
                                errorMessage={props.formikProps.errors.beneficialOwners && t((props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.nationality as string)}
                                required
                                errorMessageClasses={style.error}
                            />
                            <Calendar
                                name={`beneficialOwners.${props.index}.birthDate`}
                                id={`beneficialOwners.${props.index}.birthDate`}
                                label={t('beneficialOwnerPage.personalInformation.birthDate')}
                                value={props.BeneficialOwner.birthDate}
                                onChange={onCalendarChange}
                                disabled={props.BeneficialOwner.isLegalRepresentative || props.disabled}
                                error={Boolean(props.formikProps.errors.beneficialOwners &&
                                    (props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.birthDate) &&
                                    props.formikProps.touched.beneficialOwners && props.formikProps.touched.beneficialOwners[props.index]?.birthDate}
                                errorMessage={props.formikProps.errors.beneficialOwners && t((props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.birthDate as string)}
                                required
                                errorMessageClasses={style.error}
                                maximumDate={maximumDate()}
                            />
                        </Column>
                    </Section>
                    <Section title={t('beneficialOwnerPage.legalInformation.title')}>
                        <Column>
                            <TextInput
                                name={`beneficialOwners.${props.index}.votingShare`}
                                id={`beneficialOwners.${props.index}.votingShare`}
                                label={t('beneficialOwnerPage.legalInformation.votingShare')}
                                info={t('beneficialOwnerPage.legalInformation.votingShare')}
                                onChange={event => onChange(event, props.formikProps)}
                                value={props.BeneficialOwner.votingShare}
                                error={Boolean(props.formikProps.errors.beneficialOwners &&
                                    (props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.votingShare) &&
                                    props.formikProps.touched.beneficialOwners && props.formikProps.touched.beneficialOwners[props.index]?.votingShare}
                                errorMessage={props.formikProps.errors.beneficialOwners && t((props.formikProps.errors.beneficialOwners[props.index] as FormikErrors<IBeneficialOwner>)?.votingShare as string)}
                                required
                                errorMessageClasses={style.error}
                                symbol={Symbol.Percentage}
                                disabled={props.disabled}
                            />
                        </Column>
                    </Section>
                </div>
            </AccordionItemPanel>
        </AccordionItem>
        <DialogModal
            modalContentLabel={""}
            info={t('beneficialOwnerPage.legalRepresentativeInfo')}
            id={"modalFor_beneficialOwner"}
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
        />
    </div>
}