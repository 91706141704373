import { Navigate } from 'react-router-dom';
import { useAuthenticationClient } from './AuthenticationProvider';

const AuthenticatedRoute = ({ children }: any) => {

    let isAuthenticationEnabled: boolean = (window as any).env.Authentication === '1';
    let authenticationClient = useAuthenticationClient();

    if (!!!isAuthenticationEnabled) {
        return children;
    }

    return authenticationClient.isAuthenticated() ? children : <Navigate to="/not-found" />;
};

export default AuthenticatedRoute;

