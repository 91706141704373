import httpService from "./http.service";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

import { appInsights } from "../configuration/appInsigths";

import ILegalRepresentativePostRequest from "../models/ILegalRepresentativePostRequest";
import ILegalRepresentativeResponse from "../models/ILegalRepresentativeResponse";
import ILegalRepresentativePutRequest from "../models/ILegalRepresentativePutRequest";

class LegalRepresentativeService {
  async getAll(processId: string): Promise<ILegalRepresentativeResponse[]> {
    const url = `process/${processId}/legalrepresentatives`;

    return httpService
      .getAxiosClient()
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }

  async post(processId: string, legalRepresentative: ILegalRepresentativePostRequest) {
    const url = `process/${processId}/legalrepresentative`;

    return httpService
      .getAxiosClient()
      .post(url, legalRepresentative)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }

  async delete(legalRepresentativeId: string) {
    const url = `legalrepresentative/${legalRepresentativeId}`;

    return httpService
      .getAxiosClient()
      .delete(url)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }

  async put(legalRepresentative: ILegalRepresentativePutRequest) {
    const url = `legalrepresentative/${legalRepresentative.id}`;

    return httpService
      .getAxiosClient()
      .put(url, legalRepresentative)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }
}

const service = new LegalRepresentativeService();
export default service;
