import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { css, registerStyles } from "@vaadin/vaadin-themable-mixin";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { updateStatus, updateStep } from "../../reducers/kycProcess";
import { deleteFile, getAllMappedDocuments, updateDocument } from "../../reducers/documents";
import organisationService from '../../services/organisation.service';
import kycProcessService from '../../services/kycProcess.service';

import IDocumentRequest from "../../models/IDocumentRequest";
import ProcessStatuses from "../../models/enums/ProcessStatuses";
import IDocument from "../../models/IDocument";
import Step from "../../models/enums/Step";
import ButtonType from "../../models/enums/ButtonType";

import Loading from "../../componentsLibrary/Loading/Loading";
import Button from "../../componentsLibrary/Button/Button";
import DialogModal from '../../componentsLibrary/DialogModal/DialogModal';
import FileUpload from "../FileUpload/FileUpload";

import style from "./Documents.module.scss";


import CheckedIcon from '../../assets/img/checked.png';
import InfoImage from '../../assets/img/info_white.png';

export default function Documents() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { documents } = useAppSelector((state) => state.documents);
  const { extraDocuments } = useAppSelector((state) => state.documents);
  const { processId, dataReadonly } = useAppSelector((state) => state.process);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  let [disableSubmit, setDisableSubmit] = useState(true);
  let [legalForm, setLegalForm] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    dispatch(updateStep(Step.Document));
    organisationService.get(processId).then((response) => {
      setLegalForm(response.legalForm);
      let documentsRequest: IDocumentRequest = {
        processId: processId,
        legalForm: response.legalForm
      }
      dispatch(getAllMappedDocuments(documentsRequest));
    }).catch((err) => {
      toast.error(`${t("general.somethingWentWrong")} ${t("notificationMessages.pleaseTryAgain")}`);
    }).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (documents.length === 0) {
      setDisableSubmit(false);
    } else {
      let requiredDocuments = documents.filter((document) => document.required);
      if (requiredDocuments.length > 0) {
        if (requiredDocuments.every((document) => document.name !== undefined)) {
          setDisableSubmit(false);
        } else {
          setDisableSubmit(true);
        }
      } else {
        let atLeastOneDocumentUploaded = documents.find((document) => document.name !== undefined);
        if (atLeastOneDocumentUploaded !== undefined) {
          setDisableSubmit(false);
        }
        else {
          setDisableSubmit(true);
        }
      }
    }

  }, [documents])

  const onSubmit = async () => {
    if (dataReadonly) {
      return;
    }
    setLoading(true);
    kycProcessService.post(processId)
      .then((response) => {
        dispatch(updateStatus(ProcessStatuses.ConfirmedByUser));
        toast.info(t("notificationMessages.kycDataSubmitted") as string);
        dispatch(updateStep(Step.Video));
        return;
      })
      .catch((err) => toast.error(t("general.somethingWentWrong") as string))
      .finally(() => setLoading(false));
  };

  const onBack = () => {
    dispatch(updateStep(Step.BeneficialOwner));
  };

  const onFileDeleted = (document: IDocument) => {
    if (document.id !== undefined) {
      dispatch(deleteFile(document.id));
    }

    let updatedDocument = { ...document };
    updatedDocument.name = undefined;
    updatedDocument.id = undefined;

    dispatch(updateDocument(updatedDocument));
  };

  const onFileUploaded = (document: IDocument) => {
    dispatch(updateDocument(document))
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //vaadin-component requires this approach in styling web component shadow root
  //cannot be moved inside FileUpload component due to rendering warning
  registerStyles(
    "vaadin-icon",
    css`
      :host {
        color: red;
        height: 15px;
        width: 15px;
      }
    `
  );

  registerStyles(
    "vaadin-upload",
    css`
      :host {
        background-color: #ffffff;
        width: 100%;
        border: none;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding-right: 5px; 
      }

      [part="primary-buttons"] {
        display: flex;
        flex-direction: row-reverse;
      }

      [part="drop-label"] {
        display: flex;
        flex-direction: row;
        align-content: center;
      }

      [part="file-list"] {
        width: 100%;
      }
    `
  );

  registerStyles(
    "vaadin-upload-file",
    css`
      [part="done-icon"] {
        display: none;
      }


      [part="remove-button"]:hover {
        color: #b8b8b8;
        cursor: pointer;
      }

      [part="name"] {
        color: #b8b8b8;
      }

      [part="retry-button"] {
        display: none;
      }

      [part="error"] {
        color: red;
        font-family: inherit;
        font-size: 16px;
      }

      [part="row"] {
        align-items: center;
        text-align: right;

      }

      [part="info"] {
        padding-right: 10px;
      }

      [part="warning-icon"] {
        display: none;
      }

      [part="name"] {
        color: #b8b8b8;
      }
    `
  );

  return (
    <>
    <div className={style.main}>
      <div className={style.header}>
        <p className={style.title}>
          {t("documentsPage.title")}
          <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
        </p>
      </div>
      <Loading show={loading} />
              {documents.length === 0 && <div className={style.contentEmpty}>
                <img src={CheckedIcon} alt="pending" className={style.image} />
                <div className={style.text}>{t("documentsPage.noRequiredDocuments", { legalForm: t(`organisationPage.legalForm.values.${legalForm}`) })}</div>
              </div>}
              {extraDocuments && <div className={style.message}>
                <div className={style.text}>{t("documentsPage.documentsOptional", { legalForm: t(`organisationPage.legalForm.values.${legalForm}`) })}</div> 
              </div>}
              {documents.length > 0 && <div className={style.content}>{documents.map((document: IDocument, index: number) => {
                return (
                  <FileUpload
                    id={`file-${index}`}
                    metadata={document}
                    required={!!document.required}
                    label={legalForm}
                    onUpload={onFileUploaded}
                    onDelete={onFileDeleted}
                    key={index}
                    processId={processId}
                    disabled={dataReadonly}
                  />
                );
              })} 
              </div>}
     
          <div className={clsx(style.footer,{ [style.empty]: documents.length === 0})}>
            <div className={style.buttonSection}>
              <Button onClick={onBack} type={ButtonType.Button} color="secondary">
                {t("general.back")}
              </Button>
              {dataReadonly && <Button onClick={() => dispatch(updateStep(Step.Video))} type={ButtonType.Button}>{t("general.next")}</Button>}
              {!dataReadonly && <Button onClick={onSubmit} type={ButtonType.Submit} disabled={disableSubmit}>{t("general.submit")}</Button>}
            </div>
          </div> 
    </div>
    <DialogModal modalContentLabel={""} info={t('documentsPage.info')} id={"modalFor_page"} modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </>
  );
}


