import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAzureAddressResponse } from '../models/IAzureAddress';
import { RootState } from './index';
import AzureAddressDataService from '../services/azureAddress.service';
import IAddress from '../models/IAddress';

const initialState: IAzureAddressResponse= {
  results: [],
  summary: {}
}

export const getAzureAddress = createAsyncThunk(
  'addresses/getAddress',
  async (azureAddressRequest: IAddress) => {
      return await AzureAddressDataService.get<IAzureAddressResponse>(azureAddressRequest);
  }
)

const azureAddressesSlice = createSlice({
  name: 'azureAddresses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAzureAddress.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
    builder.addCase(getAzureAddress.rejected, (state, action) => {
    })
  }
});

export const selectAzureAddresses = (state: RootState) => state.azureAddresses

export default azureAddressesSlice.reducer