
import SolarisLogo from '../../assets/img/logo_solaris.png';
import style from "./Error.module.scss";

interface IErrorHandlerProps {
  message: string;
}

export default function ErrorHandler(props: IErrorHandlerProps) {
  return (
    <div className={style.main}>
      <div className={style.content}>
        <div className={style.sign}>
          <span className={style.text}>!</span>{" "}
        </div>
        <p className={style.message}>
          {props.message}
        </p>
      </div>
      <img src={SolarisLogo} className={style.solarisLogo} alt="solaris" />
    </div>
  );
}
