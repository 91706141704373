import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import ProcessStatuses from '../models/enums/ProcessStatuses';
import Step from '../models/enums/Step';
import IProcess from '../models/IProcess';
import { RootState } from './index';
import kycProcessService from '../services/kycProcess.service';
import IKycProcess from '../models/IKycProcess';

const initialState: IProcess = {
    status: ProcessStatuses.New,
    dataReadonly: false,
    processId: "",
    step: Step.LandingPage
}

export const getKycProcessBySellerId = createAsyncThunk(
    'kycProcess/getKycProcessBySellerId',
    async (sellerId: string): Promise<IKycProcess> => {
        return await kycProcessService.getBySellerId(sellerId);
    }
)

export const submitKycProcess = createAsyncThunk(
    'kycProcess/submitKycProcess',
    async (processId: string) => {
        return await kycProcessService.post(processId);
    }
)

const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        updateStatus: (state, action: PayloadAction<ProcessStatuses>) => {
            state.status = action.payload;
            state.dataReadonly = isDataSubmitted(action.payload)
        },
        //TODO: step description should be something fetched based on step and not manually send as parameter
        updateStep: (state, action: PayloadAction<Step>) => {
            state.step = action.payload;
        },
        updateProcessId: (state, action: PayloadAction<string>) => {
            state.processId = action.payload;
        },
        updateProcess: (state, action: PayloadAction<IProcess>) => {
            state.status = action.payload.status;
            state.processId = action.payload.processId;
            state.step = action.payload.step;
            state.dataReadonly = isDataSubmitted(action.payload.status)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getKycProcessBySellerId.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.processId = action.payload.processId;
        state.dataReadonly = isDataSubmitted(action.payload.status)
        return state;
      })
    }
});

const isDataSubmitted = (status: ProcessStatuses) => {
    return !(Number(status) === ProcessStatuses.New || Number(status) === ProcessStatuses.Draft)     
}

export const { updateStatus, updateStep, updateProcessId } = processSlice.actions

export const selectProcess = (state: RootState) => state.process

export default processSlice.reducer