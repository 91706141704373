import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik, FormikProps } from 'formik';
import { Accordion } from 'react-accessible-accordion';
import { isEqual } from 'lodash';

import { useAuthenticationClient } from '../../authentication/AuthenticationProvider';

import { useAppSelector, useAppDispatch } from '../../store/store';
import { updateStep } from '../../reducers/kycProcess';
import { getLegalRepresentatives, processLegalRepresentatives } from '../../reducers/legalRepresentative';
import { getAzureAddress } from '../../reducers/azureAddress';
import { validateAddress } from '../../validations/validations';
import { sanitizeObject } from '../../helpers/helpers';

import { IAzureAddressResponse } from '../../models/IAzureAddress';
import { ILegalRepresentative, NewLegalRepresentative } from '../../models/ILegalRepresentative';
import { ILegalRepresentatives } from '../../models/ILegalRepresentatives';
import Step from '../../models/enums/Step';
import ButtonType from '../../models/enums/ButtonType';
import ActionType from '../../models/enums/ActionType';
import IAddress, { AddressValidationResult } from '../../models/IAddress';

import { validationSchemaForLegalRepresentativePage as validationSchema } from '../../validations/legalRepresentativesPageValidations';

import LegalRepresentativeItem from '../LegalRepresentativeItem/LegalRepresentativeItem';
import FormPage from '../../componentsLibrary/FormPage/FormPage';
import Form from '../../componentsLibrary/Form/Form';
import FormFooter from '../../componentsLibrary/Form/FormFooter';
import Button from '../../componentsLibrary/Button/Button';
import Loading from '../../componentsLibrary/Loading/Loading';

import style from './LegalRepresentative.module.scss';
import CountryOptions from '../../models/enums/CountryOptions';

export default function LegalRepresentative() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { processId, dataReadonly } = useAppSelector((state) => state.process);
    const [loading, setLoading] = useState(false);
    let authenticationClient = useAuthenticationClient();
    let [legalRepresentativeList, setLegalRepresentativeList] = useState<ILegalRepresentatives>({ legalRepresentatives: [], processId })
    let [legalRepresentativesToBeRemoved, setLegalRepresentativesToBeRemoved] = useState<ILegalRepresentative[]>([]);

    useEffect(() => {
        setLoading(true);

        dispatch(getLegalRepresentatives(processId)).then((response) => {
            let legalReps = structuredClone(response.payload) as ILegalRepresentative[];
            if (!legalReps) {
                legalReps = [];
            }

            let account = authenticationClient.getCurrentUser();
            if (legalReps.find(l => l.email === account?.email) === undefined) {
                let defaultLegalRepresentative = {
                    ...NewLegalRepresentative,
                    partnerUserId: account?.userId as string,
                    email: account?.email as string,
                    givenName: account?.firstName as string,
                    familyName: account?.lastName as string,
                    isLegalRepresentative: true,
                    ActionType: ActionType.Added,
                    isConductingKycProcess: true
                };

                legalReps.unshift(defaultLegalRepresentative);
            }
            let list: ILegalRepresentatives = {
                processId: processId,
                legalRepresentatives: legalReps ?? []
            };
            setLegalRepresentativeList(list);
            setLoading(false);
        }).finally(() => setLoading(false));
    }, []);

    function addLegalRepresentative(props: FormikProps<ILegalRepresentatives>) {
        return () => {
            props.values.legalRepresentatives = [...props.values.legalRepresentatives, NewLegalRepresentative]
            props.setValues(props.values);
        };
    }

    function removeLegalRepresentative(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, props: FormikProps<ILegalRepresentatives>, index: number, arrayHelpers: any): void {
        event.stopPropagation();

        if (dataReadonly) {
            return;
        }
        if (props.values.legalRepresentatives[index].id) {
            legalRepresentativesToBeRemoved.push(props.values.legalRepresentatives[index]);
            setLegalRepresentativesToBeRemoved(legalRepresentativesToBeRemoved);
        }

        arrayHelpers.remove(index);
    }

    function navigateToOrganisationPage(): void {
        dispatch(updateStep(Step.Organisation));
    }

    async function onSubmit(legalRepresentatives: ILegalRepresentatives) {
        if (dataReadonly) {
            dispatch(updateStep(Step.BeneficialOwner));
            return;
        }
        
        setLoading(true);
   
        if (isEqual(legalRepresentativeList, legalRepresentatives)) {
            dispatch(updateStep(Step.BeneficialOwner));
            setLoading(false);
            return;
        }

        let fieldsAreValid = false;
        let error = {};
        legalRepresentatives.legalRepresentativesToBeRemoved = legalRepresentativesToBeRemoved;

        let legalRepresentativesSanitized = sanitizeObject(legalRepresentatives) as ILegalRepresentatives;

        try {
            await Promise.all(legalRepresentativesSanitized.legalRepresentatives.map(legalRepresentative => (dispatch(getAzureAddress(legalRepresentative.address))
                .then((response) => {
                    fieldsAreValid = validateAddressFields(legalRepresentative.givenName, legalRepresentative.familyName, legalRepresentative.address, response.payload as IAzureAddressResponse, response.type);

                    if (!fieldsAreValid) {
                        setLoading(false);
                        throw error;
                    }
                })
            ))).then(() => {
                dispatch(processLegalRepresentatives(legalRepresentativesSanitized)).then(() => {
                    dispatch(updateStep(Step.BeneficialOwner));
                    return;
                }).catch((err) => toast.error(`${t("general.somethingWentWrong")} ${t("notificationMessages.pleaseTryAgain")}`))
                    .finally(() => setLoading(false))
            })
        }
        catch { }
    }

    function validateAddressFields(givenName: string, familyName: string, address: IAddress, azureAddress: IAzureAddressResponse, responseType: string): boolean {

        let name = givenName || familyName ? `${givenName} ${familyName}` : t('legalRepresentativePage.aNewlyIntroducedLegalRepresentative');

        let validatedAddress: AddressValidationResult = validateAddress(address, azureAddress, responseType);

        if (!!!validatedAddress.isValid) {
            //we want to display the error, then the info, so the following order (info, then error) counts, as they are dispalyed in reverse order
            if (validatedAddress.address !== null) {
                toast.info(`${t('notificationMessages.theBestMatchWeFound')} ${t('general.for')} ${name}: 
                ${validatedAddress.address.postalCode}, 
                ${validatedAddress.address.streetName} 
                ${validatedAddress.address.streetNumber}, 
                ${validatedAddress.address.city}, 
                ${CountryOptions[address.countryCode.toLocaleUpperCase()]}`); //TODO: replace this with returned API value once we implement the translations on CountryOptions values; we need to take the value from CountryOptions instead of the API one, as we do not translate CountryOptions values yet
            }

            toast.error(`${t('notificationMessages.addressNotFound')} ${t('general.for')} ${name}.`);

            return false;
        }

        return true;
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>, props: FormikProps<ILegalRepresentatives>): void {
        event.preventDefault();
        props.handleSubmit(event);
    }

    return <div className={style.legalRepresentative}>
        <Formik
            initialValues={legalRepresentativeList}
            validateOnChange={!dataReadonly}            
            validateOnBlur={!dataReadonly}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                onSubmit(values);
                actions.setSubmitting(false);
            }}
            enableReinitialize={true}
        >
            {props => {
                return (
                    <FormPage title={t('legalRepresentativePage.title')} info={t('legalRepresentativePage.info')} titleClasses={clsx(style.title)}>
                        <Loading show={loading} />
                        <Button type={ButtonType.Button}
                            onClick={addLegalRepresentative(props)}
                            disabled={dataReadonly}
                            classes={clsx(style.addButton, {[style.disabled]: dataReadonly})} >
                            <span className={style.buttonLabel}>{t('legalRepresentativePage.addLegalRepresentative')}</span>
                        </Button>
                        <Form onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event, props)} formClasses={style.legalRepresentativeForm} disabled={dataReadonly}>
                            <Accordion className={style.accordion} allowMultipleExpanded allowZeroExpanded preExpanded={[0]}>
                                <FieldArray name="legalRepresentatives">
                                    {({ ...arrayHelpers }) => (
                                        <>
                                            {!loading &&
                                                props.values.legalRepresentatives.map((legalRepresentative, index) => (
                                                    <LegalRepresentativeItem legalRepresentative={legalRepresentative}
                                                        index={index}
                                                        legalRepresentativesCount={props.values.legalRepresentatives.length}
                                                        removeLegalRepresentative={removeLegalRepresentative}
                                                        key={index}
                                                        accountEmail={authenticationClient.getCurrentUser()?.email as string}
                                                        formikProps={props}
                                                        arrayHelpers={arrayHelpers}
                                                        disabled={dataReadonly}
                                                    />
                                                ))
                                            }
                                        </>
                                    )}
                                </FieldArray>
                            </Accordion>
                            <FormFooter>
                                <Button type={ButtonType.Button} onClick={navigateToOrganisationPage} color="secondary">{t('general.back')}</Button>
                                <Button type={ButtonType.Submit}>{t('general.next')}</Button>
                            </FormFooter>
                        </Form>
                    </FormPage>
                )
            }}
        </Formik>
    </div>
}