import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { update } from '../../reducers/sellerEligibility';
import { updateStatus, updateStep } from '../../reducers/kycProcess';
import { useAppDispatch, useAppSelector } from '../../store/store';
import clientConfigurations from '../../configuration/clientConfigurations.json';

import { ISellerEligibility } from '../../models/ISellerEligibility';
import { IQuestion } from '../../models/IQuestion';
import { IQuestionConfig } from '../../models/IQuestionConfig';
import ButtonType from '../../models/enums/ButtonType';
import ProcessStatuses from '../../models/enums/ProcessStatuses';

import SwitchBox from '../../componentsLibrary/SwitchBox/SwitchBox';
import Button from '../../componentsLibrary/Button/Button';

import SolarisLogo from '../../assets/img/logo_solaris.png';

import style from './SellerEligibility.module.scss';
import clsx from 'clsx';
import Step from '../../models/enums/Step';

export default function SellerEligibility() {
    const { t, ready } = useTranslation();
    const dispatch = useAppDispatch();
    const { sellerPreEligibilityStatus } = useAppSelector((state) => state.sellerEligibility);
    const [checkboxStatuses, setCheckboxStatuses] = useState<Map<string, boolean>>(new Map());
    const [questions, setQuestions] = useState<IQuestion[]>([]);
    let questionConfigs: IQuestionConfig[] = clientConfigurations.sellerEligibilityPage.questions;

    useEffect(() => {
        let statuses = new Map();
        let translatedQuestions: IQuestion[] = [];
        if (ready) {
            translatedQuestions = t('sellerEligibilityPage.questions', { returnObjects: true });
        }
    
        if (questionConfigs) {
            questionConfigs.forEach(configQuestion => {
                statuses.set(configQuestion.id.toString(), true);
            });
            setCheckboxStatuses(statuses);
        }
    
        translatedQuestions.forEach((question, index) => {
            question.id = questionConfigs[index].id;
            question.isEligible = questionConfigs[index].isEligible;
        });

        setQuestions(translatedQuestions);
    },[t])


    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): any => {
        let statuses = structuredClone(checkboxStatuses);
        statuses.set(event.target.id, event.target.checked)
        setCheckboxStatuses(statuses);
    }

    function computeEligibility(): boolean {
        if (!questions) {
            return false;
        }

        let eligibilityCounter: number = 0;
        let statuses = structuredClone(checkboxStatuses);

        for (let i = 0; i < statuses.size; i++) {

            if (statuses.get(i.toString()) === undefined) {
                statuses.set(questions[i].id.toString(), false);
            }

            if (statuses.get(i.toString()) === questions[i].isEligible) {
                eligibilityCounter++;
            }
        }

        return eligibilityCounter === questions.length;
    }

    const onClickHandler = (): void => {
        let isEligible: boolean = computeEligibility();
        let sellerEligibilityObject: ISellerEligibility = { isEligible: isEligible, sellerPreEligibilityStatus: sellerPreEligibilityStatus };

        Promise.resolve(dispatch(update(sellerEligibilityObject))).then(
            () => {
                if (isEligible) {
                    dispatch(updateStatus(ProcessStatuses.Draft));
                    dispatch(updateStep(Step.Organisation));
                    return;
                } else {
                    dispatch(updateStep(Step.SellerEligibilityRejected));
                }
            })
    };

    return <div className={style.main}>
        <div className={style.title}>{t('sellerEligibilityPage.title')}</div>
        <table className={style.layout}>
            <tbody>
                {questions && questions.map((question: IQuestion, index: number) => (
                    <tr className={style.row} key={index}>
                        <td className={style.question}>
                            <p>{question.text}</p>
                            {question.extraInfo &&
                                <ul>
                                    {question.extraInfo.map((info: string, extraInfoIndex: number) => (
                                        <li key={extraInfoIndex}>
                                            {info}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </td>
                        <td className={style.answer}>
                            <p className={style.inline}>{t('general.no')}</p>
                            <SwitchBox id={question.id.toString()} checked={checkboxStatuses.get(question.id.toString()) || false} onChange={onChangeHandler} name="switchBox" />
                            <p className={style.inline}>{t('general.yes')}</p>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <Button onClick={onClickHandler} type={ButtonType.Button} classes={clsx(style.button)}>
            {t('general.continue')}
        </Button>
        <img src={SolarisLogo} className={style.solarisLogo} alt="solaris" />
    </div>;
}