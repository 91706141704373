import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import clsx from 'clsx';
import DialogModal from '../DialogModal/DialogModal';
import i18n from '../../language/i18n';

import InfoImage from '../../assets/img/info.png';

import style from './Calendar.module.scss';

interface IDateProps {
    id: string,
    label: string,
    required?: boolean,
    info?: string,
    modalContentLabel?: string,
    value: string | null,
    name: string,
    onChange?(e: Date | null): any | undefined,
    disabled?: boolean,
    error?: boolean,
    errorMessage?: string
    errorMessageClasses?: string,
    maximumDate?: Date
}

const Calendar = (props: IDateProps) => {
    const [date, setDate] = useState<Date | null>(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(props.error);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const minimumDate = new Date('1.1.1900');
    const maximumDate = new Date('12.31.2099');

    useEffect(() => {
        setError(props.error);

        if (props.errorMessage !== ' ' || props.errorMessage !== undefined) {
            setErrorMessage(props.errorMessage);
        }

        if (props.value) {
            setDate(new Date(props.value));
        }
        else {
            setDate(null);
        }
    }, [props.value, props.errorMessage, props.error]);

    function chainOnChangeEvents() {
        return (e) => { setDate(e); if (props.onChange) props.onChange(e) }
    }

    return (<>
        <div className={style.inputWrapper} >
            <label htmlFor={props.id} id={props.id}>
                {props.label}
                {props.required &&
                    <span className={style.required}>*</span>
                }
                {props.info &&
                    <img src={InfoImage} alt="calendar" className={style.info} onClick={openModal} />
                }
            </label>
            <DatePicker
                value={date}
                defaultActiveStartDate={props.maximumDate ?? maximumDate}
                onChange={chainOnChangeEvents()}
                format="dd/MM/y"
                minDate={minimumDate}
                maxDate={props.maximumDate ?? maximumDate}
                className={[style.reactDatePicker, error && date == null ? style.error : "", props.disabled ? style.disabled : ""]}
                calendarClassName={[style.reactCalendar, props.disabled ? style.disabled : ""]}
                name={props.name}
                locale={i18n.language}
                disabled={props.disabled}
                disableCalendar={props.disabled}
            />
        </div>
        {error && props.errorMessage !== ' ' && props.errorMessage !== undefined &&
            <span className={clsx(style.error, props.errorMessageClasses)}>{errorMessage}</span>
        }
        <DialogModal modalContentLabel={props.modalContentLabel} info={props.info || ""} id={"modalFor_" + props.id} modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </>
    )
};

export default Calendar;