import { SeverityLevel } from "@microsoft/applicationinsights-web";
import axios, { AxiosInstance } from "axios";
import { appInsights } from "../configuration/appInsigths";
import IAddress from "../models/IAddress";
import i18n from "../language/i18n";

class AzureAddressDataService {
    http: AxiosInstance;

    constructor() {

        this.http = axios.create({
            baseURL: (window as any).env.AzureAddressBaseUrl,
            headers: {
                "Content-type": "application/json"
            }
        });
    }

    get<IAzureAddressResponse>(address: IAddress) {
        let locale = i18n.language;

        let url = `address/structured/json?subscription-key=${(window as any).env.AzureAddressApiKey}&api-version=1.0&language=${locale}&streetNumber=${address.streetNumber}&streetName=${address.streetName}&municipality=${address.city}&postalCode=${address.postalCode}&countryCode=${address.countryCode}`;

        let addressResponse = this.http.get<IAzureAddressResponse>(url);
        return addressResponse.then(response => {
            return response.data;
        }).catch(err => {
            appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
            throw err.response.data;
        });
    }
}

const service = new AzureAddressDataService();
export default service;