import { createContext } from 'react';
import { AuthClient } from './types';


export type IAuthContextProps = {
    authClient?: AuthClient
    initialized: boolean
  }

  export function createAuthContext(
    initialContext?: Partial<IAuthContextProps>
  ): React.Context<IAuthContextProps> {
    return createContext({
      initialized: false,
      ...initialContext,
    })
  }


export const ReactKeycloakWebContext = createAuthContext()

export const ReactKeycloakWebContextConsumer = ReactKeycloakWebContext.Consumer