enum RegistrationIssuerOptions {
    Aachen = 'Aachen',
    Altenburg = 'Altenburg',
    Amberg = 'Amberg',
    AMTSGERICHT = 'Amtsgericht München',
    Ansbach = 'Ansbach',
    Apolda = 'Apolda',
    Arnsberg = 'Arnsberg',
    Arnstadt = 'Arnstadt',
    ARNSTADT_ZWEIGSTELLE_ILMENAU = 'Arnstadt Zweigstelle Ilmenau',
    Aschaffenburg = 'Aschaffenburg',
    Augsburg = 'Augsburg',
    Aurich = 'Augsburg',
    BAD_HERSFELD = 'Bad Hersfeld',
    BAD_HOMBURG_VDH = 'Bad Homburg vdH',
    Bad_Kreuznach = 'Bad Kreuznach',
    Bad_Oeynhausen = 'Bad Oeynhausen',
    Bad_Salzungen = 'Bad Salzungen',
    Bamberg = 'Bamberg',
    Bayreuth = 'Bayreuth',
    BERLIN_CHARLOTTENBURG = 'Berlin (Charlottenburg)',
    Bielefeld = 'Bielefeld',
    Bochum = 'Bochum',
    Bonn = 'Bonn',
    Braunschweig = 'Braunschweig',
    Bremen = 'Bremen',
    Chemnitz = 'Chemnitz',
    Coburg = 'Coburg',
    Coesfeld = 'Coesfeld',
    Cottbus = 'Cottbus',
    Darmstadt = 'Darmstadt',
    Deggendorf = 'Deggendorf',
    Dortmund = 'Dortmund',
    Dresden = 'Dresden',
    Duisburg = 'Duisburg',
    Dueren = 'Dueren',
    DUESSELDORF = 'Düsseldorf',
    Eisenach = 'Eisenach',
    Erfurt = 'Erfurt',
    Eschwege = 'Eschwege',
    Essen = 'Essen',
    Flensburg = 'Flensburg',
    FRANKFURT_AM_MAIN = 'Frankfurt am Main',
    FRANKFURT_ODER = 'Frankfurt / Oder',
    Freiburg = 'Freiburg',
    Friedberg = 'Friedberg',
    Fritzlar = 'Fritzlar',
    Fulda = 'Fulda',
    FUERTH = 'Fürth',
    Gelsenkirchen = 'Gelsenkirchen',
    Gera = 'Gera',
    GIESSEN = 'Gießen',
    Gotha = 'Gotha',
    GOETTINGEN = 'Göttingen',
    Greifswald = 'Greifswald',
    Greiz = 'Greiz',
    GUESTROW = 'Güstrow',
    GUETERSLOH = 'Gütersloh',
    Hagen = 'Hagen',
    Hamburg = 'Hamburg',
    Hamm = 'Hamm',
    Hanau = 'Hanau',
    Hannover = 'Hannover',
    HEILBAD_HEILIGENSTADT = 'Heilbad Heiligenstadt',
    Hildburghausen = 'Hildburghausen',
    Hildesheim = 'Hildesheim',
    Hof = 'Hof',
    Homburg = 'Homburg',
    Ingolstadt = 'Ingolstadt',
    Iserlohn = 'Iserlohn',
    Jena = 'Jena',
    Kaiserslautern = 'Kaiserslautern',
    Kassel = 'Kassel',
    KEMPTEN_ALLGAUE = 'Kempten(Allgäu)',
    Kiel = 'Kiel',
    Kleve = 'Kleve',
    Koblenz = 'Koblenz',
    KOELN = 'Köln',
    KOENIGSTEIN = 'Königstein',
    Korbach = 'Korbach',
    Krefeld = 'Krefeld',
    Landau = 'Landau',
    Landshut = 'Landshut',
    Lebach = 'Lebach',
    Leipzig = 'Leipzig',
    Lemgo = 'Lemgo',
    Limburg = 'Limburg',
    LUEBECK = 'Lübeck',
    LUDWIGSHAFEN_A_RHEIN_LUDWIGSHAFEN = 'Ludwigshafen aRhein(Ludwigshafen)',
    Ludwigslust = 'Ludwigslust',
    LUENEBURG = 'Lüneburg',
    Mainz = 'Mainz',
    Mannheim = 'Mannheim',
    Marburg = 'Marburg',
    Meiningen = 'Meiningen',
    Memmingen = 'Memmingen',
    Merzig = 'Merzig',
    MOENCHENGLADBACH = 'Mönchengladbach',
    Montabaur = 'Montabaur',
    MUEHLHAUSEN = 'Mühlhausen',
    MUENCHEN = 'München',
    MUENSTER = 'Münster',
    Neubrandenburg = 'Neubrandenburg',
    Neunkirchen = 'Neunkirchen',
    Neuruppin = 'Neuruppin',
    Neuss = 'Neuss',
    Nordhausen = 'Nordhausen',
    NUERNBERG = 'Nürnberg',
    OFFENBACH_AM_MAIN = 'Offenbach am Main',
    OLDENBURG_OLDENBURG = 'Oldenburg(Oldenburg)',
    OSNABRUECK = 'Osnabrück',
    Ottweiler = 'Ottweiler',
    Paderborn = 'Paderborn',
    Pasewalk = 'Pasewalk',
    Passau = 'Passau',
    Pinneberg = 'Pinneberg',
    POESSNECK = 'Pößneck',
    POESSNECK_ZWEIGSTELLE_BAD_LOBENSTEIN = 'Pößneck Zweigstelle Bad Lobenstein',
    Potsdam = 'Potsdam',
    Recklinghausen = 'Recklinghausen',
    Regensburg = 'Regensburg',
    RIBNITZ_DAMGARTEN = 'Ribnitz-Damgarten',
    Rostock = 'Rostock',
    Rudolstadt = 'Rudolstadt',
    RUDOLSTADT_ZWEIGSTELLE_SAALFELD = 'Rudolstadt Zweigstelle Saalfeld',
    SAARBRUECKEN = 'Saarbrücken',
    Saarlouis = 'Saarlouis',
    Schweinfurt = 'Schweinfurt',
    Schwerin = 'Schwerin',
    Siegburg = 'Siegburg',
    Siegen = 'Siegen',
    SOEMMERDA = 'Sömmerda',
    Sondershausen = 'Sondershausen',
    Sonneberg = 'Sonneberg',
    ST_INGBERT_ST_INGBERT = 'StIngbert(St Ingbert)',
    ST_WENDEL_ST_WENDEL = 'StWendel(St Wendel)',
    Stadthagen = 'Stadthagen',
    Stadtroda = 'Stadtroda',
    Steinfurt = 'Steinfurt',
    Stendal = 'Stendal',
    Stralsund = 'Stralsund',
    Straubing = 'Straubing',
    Stuttgart = 'Stuttgart',
    Suhl = 'Suhl',
    Tostedt = 'Tostedt',
    Traunstein = 'Traunstein',
    Ulm = 'Ulm',
    VOELKLINGEN = 'Völklingen',
    Walsrode = 'Walsrode',
    WAREN_MUERITZ = 'Waren(Müritz)',
    WEIDEN_ID_OPF = 'Weiden idOPf',
    Weimar = 'Weimar',
    Wetzlar = 'Wetzlar',
    Wiesbaden = 'Wiesbaden',
    Wismar = 'Wismar',
    Wittlich = 'Wittlich',
    Wuppertal = 'Wuppertal',
    WUERZBURG = 'Würzburg',
    ZWEIBRUECKEN = 'Zweibrücken'
};

export default RegistrationIssuerOptions;