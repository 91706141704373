import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mapToPutRequest, mapToPostRequest, mapToListModel } from "../mappers/beneficialOwner.mapper";
import ActionType from "../models/enums/ActionType";
import { IBeneficialOwner } from "../models/IBeneficialOwner";
import IBeneficialOwnerPostRequest from "../models/IBeneficialOwnerPostRequest";
import IBeneficialOwnerPutRequest from "../models/IBeneficialOwnerPutRequest";
import { IBeneficialOwners } from "../models/IBeneficialOwners";
import { IBeneficialOwnersList } from "../models/IBeneficialOwnersList";
import beneficialOwnerService from "../services/beneficialOwner.service";
import organisationService from '../services/organisation.service';
import { RootState } from "./index";

const initialState: IBeneficialOwnersList = {
  beneficialOwners: {
    beneficialOwners: [],
    contractsOutsideCompany: false,
    partnerOrganisationId: ""
  },
  beneficialOwnersToBeRemoved: [],
  processId: "",
  organisationId: ""
};

export const getBeneficialOwners = createAsyncThunk(
  "beneficialOwners/getAll",
  async (processId: string): Promise<IBeneficialOwners> => {
    let response = await beneficialOwnerService.getAll(processId);
    return mapToListModel(response);
  }
);

export const processBeneficialOwners = createAsyncThunk(
  "beneficialOwners/process",
  async (request: IBeneficialOwnersList) => {
    await Promise.all(request.beneficialOwnersToBeRemoved.map(async (beneficialOwner: IBeneficialOwner) => {
      if (beneficialOwner?.id) {
        return await beneficialOwnerService.delete(beneficialOwner.id);
      }
    }));
    await Promise.all(request.beneficialOwners.beneficialOwners.map(async (beneficialOwner: IBeneficialOwner) => {
      switch (beneficialOwner.actionType) {
        case ActionType.Added: {
          let beneficialOwnerPostRequest: IBeneficialOwnerPostRequest = mapToPostRequest(beneficialOwner)
          return await beneficialOwnerService.post(request.processId, beneficialOwnerPostRequest);
        };
        case ActionType.Updated: {
          let beneficialOwnerPutRequest: IBeneficialOwnerPutRequest = mapToPutRequest(beneficialOwner)
          return await beneficialOwnerService.put(beneficialOwnerPutRequest);
        };
      }
    }));
    await organisationService.putOutsideAgreements(request.organisationId, request.beneficialOwners.contractsOutsideCompany);
  }
);

const beneficialOwnerSlice = createSlice({
  name: "beneficialOwners",
  initialState,
  reducers: {
    updateContractsOutsideCompany: (state, action: PayloadAction<boolean>) => {
      state.beneficialOwners.contractsOutsideCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBeneficialOwners.fulfilled, (state, action) => {
      state.beneficialOwners = action.payload;
    });
  },
});

export const { updateContractsOutsideCompany } = beneficialOwnerSlice.actions;

export const selectBeneficialOwners = (state: RootState) => state.beneficialOwners;

export default beneficialOwnerSlice.reducer;
