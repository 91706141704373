import * as yup from "yup";
import * as helpers from "../helpers/helpers";

import TaxCountryOptions from "../models/enums/TaxCountryOptions";

const excludedNaceCodes = ["A 01.28", "B 05.10", "B 05.20", "B 08.11", "B 08.12", "C 25.40", "F 41.10", "F 41.20", "F 42.11", "F 42.12", "F 42.13", "F 42.21", "F 42.22", "F 42.91", "F 42.99", "R 92.00", "S 96.09"];

export const validationSchemaForOrganisation = yup.object().shape({
  name: yup
    .string()
    .required("notificationMessages.thisFieldIsRequired"),
  nrOfCustomers: yup
    .string()
    .typeError("general.onlyNumbersAllowed")
    .required("notificationMessages.thisFieldIsRequired"),
  combinedMonthlyLoanInstallments: yup
    .number()
    .transform((_, value) => {
      return +value.replace(/ /g, "");
    })
    .min(0, "notificationMessages.theValueMustBeGreaterThanOrEqualTo0")
    .typeError("general.onlyNumbersAllowed")
    .required("notificationMessages.thisFieldIsRequired"),
  turnover: yup
    .number()
    .transform((_, value) => {
      return +value.replace(/ /g, "");
    })
    .min(1_000_000, "notificationMessages.shouldBeMoreThanAMillionEuro")
    .typeError("general.onlyNumbersAllowed")
    .required("notificationMessages.thisFieldIsRequired"),
  crsCompanyType: yup
    .string()
    .typeError("general.onlyNumbersAllowed")
    .required("notificationMessages.thisFieldIsRequired"),
  lastYearGrossProfit: yup
    .number()
    .transform((_, value) => {
      return +value.replace(/ /g, "");
    })
    .min(0, "notificationMessages.theValueMustBeGreaterThanOrEqualTo0")
    .typeError("general.onlyNumbersAllowed")
    .required("notificationMessages.thisFieldIsRequired"),
  sector: yup
    .string()
    .required("notificationMessages.thisFieldIsRequired"),
  naceCode: yup
    .string()
    .required("notificationMessages.thisFieldIsRequired")
    .test(
      "naceCode",
      "notificationMessages.selectedNaceCodeFallsUnderTheExcludedNACECodesForOurProduct",
      (value) =>
        !excludedNaceCodes.includes(value ?? "") 
    ),
  businessPurpose: yup
    .string()
    .required("notificationMessages.thisFieldIsRequired"),
  legalForm: yup
    .string()
    .required("notificationMessages.thisFieldIsRequired"),
  address: yup.object().shape({
    postalCode: yup
      .number()
      .typeError("general.onlyNumbersAllowed")
      .required("notificationMessages.thisFieldIsRequired"),
    city: yup
      .string()
      .required("notificationMessages.thisFieldIsRequired")
      .matches(
        /^\p{L}+(-?\s?\p{L}+)*$/u,
        "notificationMessages.thisFieldAcceptsOnlyLettersHyphensAndSpaces"
      ),
    countryCode: yup
      .string()
      .required("notificationMessages.thisFieldIsRequired"),
    streetName: yup
      .string()
      .required("notificationMessages.thisFieldIsRequired"),
    streetNumber: yup
      .string()
      .required("notificationMessages.thisFieldIsRequired"),
  }),
  registrationNumber: yup
    .string()
    .required("notificationMessages.thisFieldIsRequired")
    .matches(
      /^[^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
      "notificationMessages.thisFieldAcceptsOnlyNumbersLettersAndSpaces"
    ),
  registrationIssuer: yup
    .string()
    .required("notificationMessages.thisFieldIsRequired"),
  taxCountryCode: yup
    .string()
    .required("notificationMessages.thisFieldIsRequired")
    .test(
      "taxCountryCode",
      "notificationMessages.onlyGermanyIsCurrentlySupported",
      (value) =>
        value ===
        helpers.getKeyByValue(
          TaxCountryOptions.DE,
          TaxCountryOptions
        )
    ),
  foundationDate: yup
    .date()
    .required("notificationMessages.thisFieldIsRequired")
    .nullable()
});
