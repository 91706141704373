import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ButtonType from '../../models/enums/ButtonType';
import MessageTypeOptions from '../../models/enums/MessageTypeOptions';
import Button from '../../componentsLibrary/Button/Button';

import SolarisLogo from '../../assets/img/logo_solaris.png';
import SuccessImage from '../../assets/img/success.png';
import ErrorImage  from '../../assets/img/circle-x_primary.svg';

import style from './GeneralMessage.module.scss';

interface IGeneralMessageProps {
    type: MessageTypeOptions,
    messages: string[],
    onBackClick?: any,
    messageStyle?: string
    backButtonVisible?: boolean,
    children?: React.ReactNode
}

export default function GeneralMessage(props: IGeneralMessageProps) {
    const { t } = useTranslation();

    return <div className={style.main}>
        <div className={style.content}>
            {(() => {
                switch (props.type) {
                    case MessageTypeOptions.KycCompleted:
                        return <div className={style.sign}><img src={SuccessImage} alt="success" /></div>;   
                    case MessageTypeOptions.KycRejected:
                        return <div className={style.sign}><img src={ErrorImage} alt="error" /></div>;                
                    case MessageTypeOptions.Warning:
                        return <div className={style.sign}><span className={style.text}>!</span> </div>;
                    case MessageTypeOptions.Error404:
                        return <div className={style.sign}><span className={style.text}>404</span> </div>;
                    default: return <div className={style.sign}><span className={style.text}>!</span> </div>;
                }
            })()
            }
            {props.messages && props.messages.map((message, index) => {
                return <p className={clsx(style.message, props.messageStyle)} key={index}>{message}</p>
            })
            }
            {props.children}
        </div>
        {props.backButtonVisible &&
            <div className={style.backNavigation}>
                <p className={style.goBackMessage}>{t('general.goBackMessage')}</p>
                <Button onClick={props.onBackClick} type={ButtonType.Button}>
                    {t('general.back')}
                </Button>
            </div>
        }
        <img src={SolarisLogo} className={style.solarisLogo} alt="solaris" />
    </div>
}