import { useTranslation } from 'react-i18next';
import Button from '../../componentsLibrary/Button/Button';
import MessageTypeOptions from '../../models/enums/MessageTypeOptions';
import GeneralMessage from '../GeneralMessage/GeneralMessage';


export default function ProcessCompleted(props: any) {
    const { t } = useTranslation();

    return <> 
        <GeneralMessage
            type={MessageTypeOptions.KycCompleted}
            messages={[t('processCompletedPage.congratulationsMessageLine1'), t('processCompletedPage.congratulationsMessageLine2')]}
        >
            <a href={(window as any).env.PartnerUrl}><Button>{t("processCompletedPage.redirectButton")}</Button></a>
        </GeneralMessage>
    </>;
}