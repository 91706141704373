import * as yup from 'yup';
import { IBeneficialOwner } from '../models/IBeneficialOwner';

export const VotingShareRuleError = "VotingShareRuleError";
export const AtLeastOneUboRuleError = "AtLeastOneUboRuleError";
export const VotingShareRuleForFullPercentageError = "VotingShareRuleForFullPercentageError";

export const validationSchemaForBeneficialOwnersPage = yup.object().shape({
    beneficialOwners: yup.array().of(yup.object().shape({
        familyName: yup
            .string()
            .required('notificationMessages.thisFieldIsRequired'),
        givenName: yup
            .string()
            .required('notificationMessages.thisFieldIsRequired'),
        birthDate: yup
            .date()
            .required('notificationMessages.thisFieldIsRequired')
            .nullable(),
        nationality: yup
            .string()
            .required('notificationMessages.thisFieldIsRequired'),
        votingShare: yup
            .number()
            .min(1, 'notificationMessages.theValueMustBeBetween1And100')
            .max(100, 'notificationMessages.theValueMustBeBetween1And100')
            .typeError('general.onlyNumbersAllowed')
            .required('notificationMessages.thisFieldIsRequired'),
    })).min(1, AtLeastOneUboRuleError)
        .test("votingShare", VotingShareRuleError, (beneficialOwners) =>
            beneficialOwners?.some(beneficialOwner => beneficialOwner?.votingShare ? beneficialOwner.votingShare >= 25 : false) || false
        )
        .test("votingShare", VotingShareRuleForFullPercentageError,
            (beneficialOwners) => {
                if (beneficialOwners) {
                    return totalVotingShare(beneficialOwners) <= 100
                }
                return false;
            }
        ),
    ContractsOutsideCompany: yup.boolean(),
    PartnerOrganisationId: yup.string()
});


function totalVotingShare(beneficialOwners: any) {
    const ubos = beneficialOwners as IBeneficialOwner[];

    return ubos.reduce((accumulator, object) => {
        return accumulator + object.votingShare;
    }, 0);

}