//npm package: https://www.npmjs.com/package/react-select
//examples: https://react-select.com/home

import { CSSProperties, FC, useEffect, useState } from 'react';
import Select, { components, DropdownIndicatorProps, StylesConfig } from 'react-select';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { IMultiselectOption } from '../../models/IMultiselectOption';
import DialogModal from '../DialogModal/DialogModal';

import InfoImage from '../../assets/img/info.png';
import style from './CustomMultiselect.module.scss';

interface ICustomMultiselectProps {
    id: string,
    label: string,
    name: string,
    info?: string,
    modalContentLabel?: string,
    options: IMultiselectOption[],
    required?: boolean,
    value: IMultiselectOption,
    onChange(e: any): any | undefined,
    selectWrapperClasses?: string,
    disabled?: boolean,
    error?: boolean,
    errorMessage?: string
    errorMessageClasses?: string
}

const CustomMultiselect: FC<ICustomMultiselectProps> = (props: ICustomMultiselectProps) => {
    const { t } = useTranslation();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(props.error);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);
    const [options, setOptions] = useState<IMultiselectOption[]>();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        setError(props.error);

        if (props.errorMessage !== ' ' || props.errorMessage !== undefined) {
            setErrorMessage(props.errorMessage);
        }

    }, [props.errorMessage, props.error]);

    useEffect(() => {
        let mappedOptions: IMultiselectOption[] = props.options.map((option) => {
            let mappedOption: IMultiselectOption = option;
            mappedOption.label = t(option.label);

            mappedOption.options.map((option) => {
                let localOption = option;

                localOption.label = t(option.label);
                localOption.value = option.value;

                return localOption;
            });

            return mappedOption;
        });

        setOptions(mappedOptions);
    }, []);

    /*Styles as described on the official page*/

    const requiredColor = "#FF0000";
    const disabledColor = "#d8d8d8";
    const contourColor = "#96989C";
    const darkerGreyColor = "#777777";
    const whiteColor = "#ffffff";

    const normalBorder = `1px solid ${contourColor}`;
    const redBorder = `1px solid ${requiredColor}`;

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        color: "rgba(0,0,0,0.75)",
    };

    const groupBadgeStyles: CSSProperties = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = (data: any) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const styles: StylesConfig = {
        control: (styles: any, props: any) => {
            return {
                ...styles,
                width: 357,
                height: 40,
                backgroundColor: whiteColor,
                border: !error ? normalBorder : redBorder,
                boxShadow: 'none',
                padding: 0,
                borderRadius: "2px",
                ':active': {
                    border: !error ? normalBorder : redBorder,
                },
                ':focus': {
                    border: !error ? normalBorder : redBorder,
                },
                ':focus-visible': {
                    border: !error ? normalBorder : redBorder,
                },
                ':hover': {
                    border: !error ? normalBorder : redBorder,
                }
            }
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                display: 'none'
            }
        },

        menu: (styles: any) => {
            return {
                ...styles,
                border: normalBorder,
            }
        },
        option: (styles: any, { isDisabled, isSelected }: any) => {
            return {
                ...styles,
                backgroundColor: isSelected ? darkerGreyColor : whiteColor,
                color: isSelected ? whiteColor : isDisabled ? disabledColor : contourColor,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isSelected ? darkerGreyColor : isDisabled ? disabledColor : darkerGreyColor,
                },
                ':hover': {
                    ...styles[':hover'],
                    backgroundColor: isSelected ? darkerGreyColor : isDisabled ? disabledColor : darkerGreyColor,
                    color: whiteColor
                }
            };
        },
        input: (styles: any, props: any) => ({ ...styles, color: props.isDisabled ? disabledColor : contourColor }),
        placeholder: (styles: any, props: any) => ({ ...styles, color: props.isDisabled ? disabledColor : contourColor, fontFamily: 'inherit' }),
        singleValue: (styles: any, props: any) => ({ ...styles, color: props.isDisabled ? disabledColor : contourColor }),
    };

    const DropdownIndicator = (
        props: DropdownIndicatorProps
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <svg height='10px' width='25px'>
                    <text x='9' y='2' fill='gray' fontSize='16' fontFamily='Consolas' fontWeight='bold' rotate='90'>{'>'}</text>
                </svg>
            </components.DropdownIndicator>
        );
    };

    /*End of styles as described on the official page*/

    return (<>
        <div className={style.inputWrapper}>
            <label htmlFor={props.id} className={style.label}>
                {props.label}
                {props.required &&
                    <span className={style.required}>*</span>
                }
                {props.info &&
                    <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
                }
            </label>
            <div className={clsx(style.selectWrapper, error ? style.error : "", props.selectWrapperClasses)}>
                <Select
                    options={options}
                    name={props.name}
                    formatGroupLabel={formatGroupLabel}
                    onChange={props.onChange}
                    styles={styles}
                    components={{ DropdownIndicator }}
                    isDisabled={props.disabled}
                    id={props.id}
                    isSearchable
                    backspaceRemovesValue
                    escapeClearsValue
                    value={props.value}
                    noOptionsMessage={() => t('general.multiselectEmptyRecordMessage')}
                    placeholder={t("general.pleaseSearchForAndSelectAValue")}
                />
            </div>
        </div >
        {error && props.errorMessage !== ' ' && props.errorMessage !== undefined &&
            <span className={clsx(style.error, props.errorMessageClasses)}>{errorMessage}</span>
        }
        <DialogModal modalContentLabel={props.modalContentLabel} info={props.info || ""} id={"modalFor_" + props.id} modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </>
    );
};

export default CustomMultiselect;