import clsx from 'clsx';
import style from './Column.module.scss';

export default function Column(props: any) {
    return (
        <div className={clsx(style.column, props.columnClasses)}>
            {props.children}
        </div>
    )
};
