import { useAppSelector } from "../../store/store";

import PendingVideoIdentification from "./PendingVideoIdentification/PendingVideoIdentification";
import SuccessfulVideoIdentification from "./SuccessfulVideoIdentification/SuccessfulVideoIdentification";

import ProcessStatuses from "../../models/enums/ProcessStatuses";

export default function VideoIdentification() {
  const { status } = useAppSelector((state) => state.process);

  return (
    <>
      {status == ProcessStatuses.ConfirmedByUser && <PendingVideoIdentification />}
      {status == ProcessStatuses.SubmittedToProvider && <PendingVideoIdentification />}  
      {status == ProcessStatuses.LegalIdentificationPassed && <PendingVideoIdentification />}
      {status == ProcessStatuses.ApprovedByProvider && <SuccessfulVideoIdentification />}
      {status == ProcessStatuses.Completed && <SuccessfulVideoIdentification />}
    </>
  );
}
