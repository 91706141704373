import httpService from "./http.service";
import DocumentTypes from "../models/enums/DocumentTypes";
import IDocuments from "../models/IDocuments";
import IDocumentRequest from "../models/IDocumentRequest";
import GermanLegalForms from "../models/enums/GermanLegalForms";
import IDocument from "../models/IDocument";
import IDocumentType from "../models/IDocumentType";
import { getKeyByValue } from "../helpers/helpers";
import { appInsights } from "../configuration/appInsigths";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

class DocumentsService {
  async getAll(processId: string): Promise<IDocuments> {
    const url = `process/${processId}/documentsmetadata`;
    return httpService
      .getAxiosClient()
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }

  async getAllMappedDocuments(documentsRequest: IDocumentRequest): Promise<IDocuments> {
    let hasExtraDocuments = false;
    let documentsResponse = await this.getAll(documentsRequest.processId);
    let documentTypes = this.getListOfDocumentTypes(
      documentsRequest.legalForm,
      documentsResponse.contractsOutsideCompany
    );
    let mappedDocuments: IDocument[];
    if (documentTypes.length === 0) {
      mappedDocuments = documentsResponse.documents.map((document) => {
        return {
          id: document?.id,
          name: document?.name,
          required: false,
          documentType: document.documentType,
        };
      });
      if (mappedDocuments.length > 0) {
        hasExtraDocuments = true;
      }
    } else {
      mappedDocuments = documentTypes.map(
        (documentType: IDocumentType): IDocument => {
          let document = documentsResponse.documents.find(
            (document: IDocument) =>
              document.documentType ===
              getKeyByValue(documentType.type, DocumentTypes)
          );
          return {
            id: document?.id,
            name: document?.name,
            required: documentType.required,
            documentType: documentType.type,
          };
        }
      );

      let extraDocuments = documentsResponse.documents
      .filter((document: IDocument) => documentTypes.map((documentType: IDocumentType) => getKeyByValue(documentType.type, DocumentTypes))
                                                    .indexOf(document.documentType) < 0)
      .map((document) => {
        return {
          id: document?.id,
          name: document?.name,
          required: false,
          documentType: document.documentType,
        };
      });

      mappedDocuments = mappedDocuments.concat(extraDocuments);
      if (extraDocuments.length > 0) {
        hasExtraDocuments = true;
      }
    }

    let response: IDocuments = {
      documents: mappedDocuments,
      kycProcessId: documentsRequest.processId,
      contractsOutsideCompany: documentsResponse.contractsOutsideCompany,
      extraDocuments: hasExtraDocuments
    };
    return response;
  }

  async delete(id: string) {
    let url = `document/${id}`;

    return httpService
      .getAxiosClient()
      .delete(url)
      .then((response) => {
        return {};
      })
      .catch(function (err) {
        throw err.response.data;
      });
  }

  private getListOfDocumentTypes(
    legalForm: string,
    contractOutsideCompany: boolean
  ): IDocumentType[] {
    let form = GermanLegalForms[legalForm];
    switch (form) {
      case GermanLegalForms.Ug:
      case GermanLegalForms.Kg:
      case GermanLegalForms.GmbhCoKg:
        return [
          { required: false, type: DocumentTypes.ShareholdersList },
          {
            required: contractOutsideCompany ?? false,
            type: DocumentTypes.ShareholdersAgreement,
          },
          { required: false, type: DocumentTypes.FoundationDocument },
        ];
      case GermanLegalForms.UgIGr:
      case GermanLegalForms.Ag:
      case GermanLegalForms.Se:
        return [
          { required: true, type: DocumentTypes.ShareholdersList },
          { required: true, type: DocumentTypes.ShareholdersAgreement },
          { required: true, type: DocumentTypes.FoundationDocument },
        ];
      default:
        return contractOutsideCompany
          ? [{ required: true, type: DocumentTypes.ShareholdersAgreement }]
          : [];
    }
  }
}

const service = new DocumentsService();
export default service;
