import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import clsx from 'clsx';

import 'react-circular-progressbar/dist/styles.css';
import style from './TimelineItem.module.scss';

interface ITimelineItemProps {
    step: number
    value: number | undefined,
    displayValue: string,
    label: string,
    isError?: boolean
    isSelected?: boolean
}

const TimelineItem = (props: ITimelineItemProps) => {

    const selectItem = () => {
        //Disabled for MVP.

        // navigate(`/process/step-${props.step.number}`)
    }
    return <div className={style.item} onClick={selectItem}>
        <div className={style.progressBar}>
            <CircularProgressbar
                className={clsx(style.bar, { [style.selected]: props.isSelected }, { [style.error]: props.isError })}
                value={props.value ?? 0}
                text={props.displayValue}
                strokeWidth={3}
                background
                styles={buildStyles({
                    pathTransitionDuration: 0.55
                })}
            />
        </div>

        <div className={style.label}>
            {props.label}
        </div>
    </div>
};

export default TimelineItem;