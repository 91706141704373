import clsx from "clsx";

import ButtonType from "../../models/enums/ButtonType";

import style from "./Button.module.scss";

interface IButtonProps {
  id?: string;
  type?: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | any;
  disabled?: boolean;
  classes?: string;
  children?: any;
  color?: string;
  [properties: string | number | symbol]: unknown;
}

const Button = (props: IButtonProps) => {
  return (
    <button
      className={clsx(
        style.button,
        props.classes,
        style[props.color ?? "primary"],
        { [style.disabled]: props.disabled }
      )}
      id={props.id}
      disabled={props.disabled ?? false}
      type={props.type ?? "button"}
      onClick={props.onClick}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
