import { isArray } from '@microsoft/applicationinsights-core-js';
import i18n from '../language/i18n';
import DocumentTypes from '../models/enums/DocumentTypes';
import NumberOfCustomerOptions from '../models/enums/NumberOfCustomerOptions';

export function isNumeric(value: string) {
    if (typeof value != "string") return false;
    return !isNaN(parseInt(value));
}

export function toNumericSpacedFormat(value: string) {
    let formattedValue = value.replaceAll(' ', '');

    if (!isNumeric(formattedValue)) {
        return value;
    }

    formattedValue = (parseInt(formattedValue)).toLocaleString(i18n.language);
    formattedValue = formattedValue.replaceAll(',', ' ').replaceAll('.', ' ');

    return formattedValue;
}

export function getValueFromRange(value: string): number {
    switch (value) {
        case NumberOfCustomerOptions['1-5']: return 2;
        case NumberOfCustomerOptions['6-50']: return 25;
        case NumberOfCustomerOptions['51-100']: return 75;
        case NumberOfCustomerOptions['101-500']: return 250;
        case NumberOfCustomerOptions['> 500']: return 1000;
        default: return 0;
    }
}

export function getRangeFromValue(value: number): NumberOfCustomerOptions {
    switch (value) {
        case 2: return NumberOfCustomerOptions['1-5'];
        case 25: return NumberOfCustomerOptions['6-50'];
        case 75: return NumberOfCustomerOptions['51-100'];
        case 250: return NumberOfCustomerOptions['101-500'];
        case 1000: return NumberOfCustomerOptions['> 500'];
        default: return NumberOfCustomerOptions['1-5'];
    }
}

export function getKeyByValue(value: string, enumValues: any): string {
    const indexOfValue = Object.values(enumValues).indexOf(value);
    const key = Object.keys(enumValues)[indexOfValue];
    return key;
};


export function getDocumentType(type: DocumentTypes): string {
    switch (type) {
        case DocumentTypes.FoundationDocument: return "0";
        case DocumentTypes.ShareholdersAgreement: return "7";
        case DocumentTypes.ShareholdersList: return "8";
        default: return "4";
    }
}

export function sanitizeObject<T>(data: T): T {
    if (!data) {
        return Object.create({});
    }

    if (isArray(data)) {
        const sanitizedArray = Object.create([]);

        data.map(element => {
            sanitizedArray.push(sanitizeObject(element));
        });

        return sanitizedArray;
    }

    const sanitizedData = Object.create({});

    for (const [key, value] of Object.entries(data as object)) {
        if (!value) {
            sanitizedData[key] = value;
        }
        else {
            switch (true) {
                case typeof value === 'object' && !(value instanceof Date): //in case of a Date value, typeof value would still be object, but we want just the value, so we cannot check for typeof for dates, instead we are using instanceof, which is true in case of a date value
                    sanitizedData[key] = sanitizeObject(value);
                    break;
                case typeof value === 'string':
                    sanitizedData[key] = (value as string).trim().replace(/\s{2,}/g, ' ');// this trims spaces and eliminates extra spaces between words
                    break;
                default: //treats all the others, like booleans, dates, numbers, etc
                    sanitizedData[key] = value;
                    break;
            }
        }
    }

    return sanitizedData;
}

export function getIsoDateTime(date: Date): string {
    let isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

    return isoDateTime;
}