enum GermanLegalForms {
    Ag = 'organisationPage.legalForm.values.Ag',
    Eg = 'organisationPage.legalForm.values.Eg',
    Ek = 'organisationPage.legalForm.values.Ek',
    Gmbh = 'organisationPage.legalForm.values.Gmbh',
    GmbhCoKg = 'organisationPage.legalForm.values.GmbhCoKg',
    Kg = 'organisationPage.legalForm.values.Kg',
    Kgaa = 'organisationPage.legalForm.values.Kgaa',
    Ltd = 'organisationPage.legalForm.values.Ltd',
    Ohg = 'organisationPage.legalForm.values.Ohg',
    Partg = 'organisationPage.legalForm.values.Partg',
    Se = 'organisationPage.legalForm.values.Se',
    SelfEmployed = 'organisationPage.legalForm.values.SelfEmployed', 
    Ug = 'organisationPage.legalForm.values.Ug',
    UgIGr = 'organisationPage.legalForm.values.UgIGr'
};

export default GermanLegalForms;