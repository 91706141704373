import IAuthenticationClient from "../../models/IAuthenticationClient";
import IUser from "../../models/IUser";
import keycloak from "./keycloak";

class KeycloakAuthenticationClient implements IAuthenticationClient {

    isAuthenticated(): boolean {
        return !!keycloak?.authenticated;
    }

    getCurrentUser(): IUser | undefined {
        if (this.isAuthenticated()) {
            return {
                email: keycloak?.tokenParsed?.email,
                firstName: keycloak?.tokenParsed?.given_name,
                lastName: keycloak?.tokenParsed?.family_name,
                userId: keycloak?.tokenParsed?.userId
            };
        }  

        // TODO: remove this and throw exception if user unauthenticated
        return {
            email: "test@banqup.com",
            firstName: "Test",
            lastName: "Test",
            userId: "Test"
        };
    }

    login(): void {
        keycloak.login();
    }

    logout(): void {
        keycloak.logout();
    }

    getToken(): string | undefined {
        return keycloak.token;
    }

    updateToken(successCallback: any): any {
       return keycloak.updateToken(15).then(successCallback);
    }
}

export default KeycloakAuthenticationClient;