import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../configuration/appInsigths";
import httpService from "./http.service";
import SellerPreEligibilityStatus from "../models/enums/SellerPreEligibilityStatus";
import { ISellerPreEligibilityRequest } from "../models/ISellerPreEligibilityRequest";

class SellerService {
  async get(sellerPreEligibilityPayload: ISellerPreEligibilityRequest): Promise<SellerPreEligibilityStatus> {
    let url = `seller/${sellerPreEligibilityPayload.source}/${sellerPreEligibilityPayload.externalId}`;
    return httpService
      .getAxiosClient()
      .get(url)
      .then((response) => {
        return response.data?.eligibilityStatus;
      })
        .catch((err) => {
            appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
            throw err;
      });
  }
}

let service =  new SellerService()
export default service;