import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../configuration/appInsigths";
import IKycProcess from "../models/IKycProcess";
import httpService from "./http.service";

class KycProcessService {
  async getBySellerId(sellerId: string): Promise<IKycProcess> {
    const mdmSellerId = `mdm://organisation@sparta/${sellerId}`;
    let url = `kycprocess?partnerOrganisationId=${mdmSellerId}`;

    return httpService
      .getAxiosClient()
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }

  async post(processId: string) {
    let url = `kycprocess/submit/${processId}`;

    return httpService
      .getAxiosClient()
      .post(url)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }
}

const service = new KycProcessService();
export default service;
