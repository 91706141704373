
import axios from 'axios';
import { useAuthenticationClient } from "../authentication/AuthenticationProvider";

const uploadBaseUrl: string = `${(window as any).env.ApiBaseUrl}/document`;
const _axios = axios.create();

const configure = () => {
     //TODO: add logging
    _axios.interceptors.request.use((config: any) => {
      config.baseURL = (window as any).env.ApiBaseUrl; 
      config.headers["Content-Type"] = "application/json-patch+json";
      config.headers.Accept = "text/plain";
      
      let isAuthenticationEnabled: boolean = (window as any).env.Authentication === '1';
      if (isAuthenticationEnabled) {
        let authenticationClient = useAuthenticationClient();
        if (authenticationClient.isAuthenticated()) {
          const callBack = () => {     
            config.headers.Authorization = `Bearer ${authenticationClient.getToken()}`;
            return Promise.resolve(config);
          };
          return authenticationClient.updateToken(callBack);
        } 
      }
  
      return config;
    });
};

const getAxiosClient = () => _axios;

const httpService = {
  configure,
  getAxiosClient,
  uploadBaseUrl 
};

export default httpService;