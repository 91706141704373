import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../store/store';

import TimelineItem from '../../componentsLibrary/TimelineItem/TimelineItem';

import SolarisLogo from '../../assets/img/logo_solaris.png';

import style from './NavigationMenu.module.scss';

const NavigationMenu = (props: any) => {
    const { t } = useTranslation();
    const { Organisation, LegalRepresentatives, BeneficialOwners, Documents, VideoIdentification, ContractSign } = useAppSelector((state) => state.timelineProgress);
    const { step } = useAppSelector((state) => state.process);

    let firstStep: number = 1;
    let secondStep: number = 2;
    let thirdStep: number = 3;
    let fourthStep: number = 4;
    let fifthStep: number = 5;
    let sixthStep: number = 6;

    const getStep = (step: Number) => {
        switch(step){
            case 1: return "firstStep";
            case 2: return "secondStep";
            case 3: return "thirdStep";
            case 4: return "fourthStep";
            case 5: return "fifthStep";
            case 6: return "sixthStep";
        }
    }

    return <div className={style.container}>
        <div className={style.stepDescription}>
            <h2>{t('navigationMenu.step')} {step || 1}</h2>
            <p>{t(`navigationMenu.${getStep(step)}.description`)}</p>
        </div>
        <div className={style.menu}>
            <TimelineItem
                step={firstStep}
                value={Organisation.completionPercentage}
                displayValue={firstStep.toString()}
                label={t('navigationMenu.firstStep.label')}
                isSelected={step === firstStep}
                isError={Organisation.isError} />
            <TimelineItem
                step={secondStep}
                value={LegalRepresentatives.completionPercentage}
                displayValue={secondStep.toString()}
                label={t('navigationMenu.secondStep.label')}
                isSelected={step === secondStep}
                isError={LegalRepresentatives.isError} />
            <TimelineItem
                step={thirdStep}
                value={BeneficialOwners.completionPercentage}
                displayValue={thirdStep.toString()}
                label={t('navigationMenu.thirdStep.label')}
                isSelected={step === thirdStep}
                isError={BeneficialOwners.isError} />
            <TimelineItem
                step={fourthStep}
                value={Documents.completionPercentage}
                displayValue={fourthStep.toString()}
                label={t('navigationMenu.fourthStep.label')}
                isSelected={step === fourthStep}
                isError={Documents.isError} />
            <TimelineItem
                step={fifthStep}
                value={VideoIdentification.completionPercentage}
                displayValue={fifthStep.toString()}
                label={t('navigationMenu.fifthStep.label')}
                isSelected={step === fifthStep}
                isError={VideoIdentification.isError} />
            <TimelineItem
                step={sixthStep}
                value={ContractSign.completionPercentage}
                displayValue={sixthStep.toString()}
                label={t('navigationMenu.sixthStep.label')}
                isSelected={step === sixthStep}
                isError={ContractSign.isError} />
        </div>
        <img src={SolarisLogo} className={style.solarisLogo} alt="solaris" />
    </div>
};

export default NavigationMenu;