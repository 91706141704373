import { IMultiselectOption, ISelectOption } from "../IMultiselectOption";

let valueCounter = 0;
let categoryCounter = 0;

const CategoryAOptions: ISelectOption[] = [
    { value: "A 01.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.15", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.16", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.25", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.26", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.27", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.28", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.43", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.44", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.45", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.46", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.47", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.49", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.50", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.61", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.62", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.63", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.64", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 01.70", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 02.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 02.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 02.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 02.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 03.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 03.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 03.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "A 03.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryBOptions: ISelectOption[] = [
    { value: "B 05.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 05.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 06.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 06.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 07.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 07.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 07.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 08.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 08.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 08.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 08.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 08.93", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 08.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 09.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "B 09.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryCOptions: ISelectOption[] = [
    { value: "C 10.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.39", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.61", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.62", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.71", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.72", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.73", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.81", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.82", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.83", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.84", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.85", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.86", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.89", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 10.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 11.01", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 11.02", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 11.03", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 11.04", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 11.05", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 11.06", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 11.07", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 12.00", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.93", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.94", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.95", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.96", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 13.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 14.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 14.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 14.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 14.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 14.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 14.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 14.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 14.39", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 15.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 15.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 15.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 16.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 16.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 16.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 16.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 16.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 16.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 17.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 17.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 17.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 17.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 17.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 17.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 17.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 18.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 18.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 18.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 18.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 18.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 19.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 19.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.15", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.16", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.17", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.53", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.59", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 20.60", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 21.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 21.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 22.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 22.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 22.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 22.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 22.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 22.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.43", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.44", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.49", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.61", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.62", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.63", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.64", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.65", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.69", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.70", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 23.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.33", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.34", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.43", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.44", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.45", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.46", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.53", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 24.54", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.50", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.61", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.62", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.71", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.72", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.73", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.93", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.94", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 25.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.60", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.70", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 26.80", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.33", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 27.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.15", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.25", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.49", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.93", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.94", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.95", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.96", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 28.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 29.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 29.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 29.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 29.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 30.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 30.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 30.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 30.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 30.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 30.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 30.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 30.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 31.01", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 31.02", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 31.03", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 31.09", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.50", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 32.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.15", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.16", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.17", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "C 33.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryDOptions: ISelectOption[] = [
    { value: "D 35.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "D 35.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "D 35.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "D 35.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "D 35.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "D 35.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "D 35.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "D 35.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryEOptions: ISelectOption[] = [
    { value: "E 36.00", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "E 37.00", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "E 38.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "E 38.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "E 38.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "E 38.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "E 38.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "E 38.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "E 39.00", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryFOptions: ISelectOption[] = [
    { value: "F 41.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 41.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 42.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 42.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 42.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 42.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 42.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 42.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 42.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.33", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.34", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.39", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "F 43.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryGOptions: ISelectOption[] = [
    { value: "G 45.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 45.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 45.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 45.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 45.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 45.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.15", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.16", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.17", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.18", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.33", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.34", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.35", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.36", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.37", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.38", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.39", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.43", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.44", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.45", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.46", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.47", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.48", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.49", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.61", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.62", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.63", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.64", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.65", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.66", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.69", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.71", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.72", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.73", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.74", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.75", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.76", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.77", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 46.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.25", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.26", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.43", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.53", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.54", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.59", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.61", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.62", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.63", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.64", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.65", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.71", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.72", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.73", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.74", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.75", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.76", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.77", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.78", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.79", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.81", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.82", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.89", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "G 47.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryHOptions: ISelectOption[] = [
    { value: "H 49.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 49.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 49.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 49.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 49.39", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 49.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 49.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 49.50", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 50.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 50.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 50.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 50.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 51.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 51.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 51.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 52.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 52.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 52.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 52.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 52.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 52.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 53.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "H 53.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryIOptions: ISelectOption[] = [
    { value: "I 55.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "I 55.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "I 55.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "I 55.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "I 56.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "I 56.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "I 56.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "I 56.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryJOptions: ISelectOption[] = [
    { value: "J 58.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 58.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 58.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 58.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 58.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 58.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 58.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 59.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 59.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 59.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 59.14", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 59.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 60.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 60.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 61.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 61.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 61.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 61.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 62.01", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 62.02", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 62.03", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 62.09", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 63.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 63.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 63.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "J 63.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryKOptions: ISelectOption[] = [
    { value: "K 64.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 64.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 64.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 64.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 64.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 64.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 64.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 65.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 65.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 65.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 65.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 66.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 66.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 66.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 66.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 66.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 66.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "K 66.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryLOptions: ISelectOption[] = [
    { value: "L 68.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "L 68.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "L 68.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "L 68.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryMOptions: ISelectOption[] = [
    { value: "M 69.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 69.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 70.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 70.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 70.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 71.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 71.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 71.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 72.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 72.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 72.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 73.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 73.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 73.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 74.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 74.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 74.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 74.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "M 75.00", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryNOptions: ISelectOption[] = [
    { value: "N 77.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.33", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.34", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.35", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.39", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 77.40", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 78.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 78.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 78.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 79.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 79.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 79.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 80.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 80.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 80.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 81.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 81.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 81.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 81.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 81.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 82.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 82.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 82.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 82.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 82.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 82.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "N 82.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]
const CategoryOOptions: ISelectOption[] = [
    { value: "O 84.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "O 84.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "O 84.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "O 84.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "O 84.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "O 84.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "O 84.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "O 84.25", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "O 84.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryPOptions: ISelectOption[] = [
    { value: "P 85.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.31", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.32", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.41", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.42", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.51", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.52", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.53", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.59", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "P 85.60", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
]

const CategoryQOptions: ISelectOption[] = [
    { value: "Q 86.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 86.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 86.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 86.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 86.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 87.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 87.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 87.30", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 87.90", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 88.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 88.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "Q 88.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryROptions: ISelectOption[] = [
    { value: "R 90.01", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 90.02", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 90.03", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 90.04", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 91.01", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 91.02", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 91.03", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 91.04", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 92.00", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 93.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 93.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 93.13", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 93.19", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 93.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "R 93.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategorySOptions: ISelectOption[] = [
    { value: "S 94.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 94.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 94.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 94.91", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 94.92", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 94.99", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 95.11", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 95.12", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 95.21", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 95.22", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 95.23", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 95.24", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 95.25", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 95.29", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 96.01", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 96.02", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 96.03", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 96.04", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "S 96.09", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryTOptions: ISelectOption[] = [
    { value: "T 97.00", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "T 98.10", label: `organisationPage.naceCode.values.${valueCounter++}.value` },
    { value: "T 98.20", label: `organisationPage.naceCode.values.${valueCounter++}.value` }
]

const CategoryUOptions: ISelectOption[] = [
    { value: "U 99.00", label: `organisationPage.naceCode.values.${valueCounter}.value` }
]

export const NaceCodeOptions: IMultiselectOption[] = [
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryAOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryBOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryCOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryDOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryEOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryFOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryGOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryHOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryIOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryJOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryKOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryLOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryMOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryNOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryOOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryPOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryQOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryROptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategorySOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryTOptions
    },
    {
        label: `organisationPage.naceCode.categories.${categoryCounter++}.value`,
        options: CategoryUOptions
    },
]