import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { IOrganisation } from "../models/IOrganisation";
import organisationService from "../services/organisation.service";
import { mapToModel, mapToPostRequest, mapToPutRequest } from "../mappers/organisation.mapper";
import IOrganisationResponse from "../models/IOrganisationResponse";
import { sanitizeObject } from "../helpers/helpers";

const initialState: IOrganisation = {
  address: {
    city: "",
    countryCode: "",
    streetName: "",
    streetNumber: "",
    postalCode: "",
  },
  hasOutsideAgreements: false,
  outsideAgreementsAnsweredAt: "",
  processId: "",
  crsCompanyType: "",
  nrOfCustomers: "",
  foundationDate: "",
  legalForm: "",
  naceCode: "",
  name: "",
  registrationIssuer: "",
  registrationNumber: "",
  sector: "",
  taxCountryCode: "",
  businessPurpose: "",
  combinedMonthlyLoanInstallments: "",
  lastYearGrossProfit: "",
  turnover: "",
  taxConfirmation: "",
  partnerOrganisationId: ""
}

export const getOrganisation = createAsyncThunk(
  "organisation/get",
  async (processId: string) => {
    return await organisationService.get(processId);
  }
);

export const processOrganisation = createAsyncThunk(
  "organisation/post",
  async (organisation: IOrganisation): Promise<IOrganisationResponse> => {

    if (organisation.id) {
      let organisationPutRequest = mapToPutRequest(organisation);
      return await organisationService.put(organisationPutRequest);
    }

    let organisationSanitized = sanitizeObject(organisation) as IOrganisation;

    let organisationPostRequest = mapToPostRequest(organisationSanitized);

    return await organisationService.post(organisationPostRequest);
  }
);

const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganisation.fulfilled, (state, action) => {
      state = mapToModel(action.payload);

      return state;
    });
  },
});

export const selectOrganisation = (state: RootState) => state.organisation;

export default organisationSlice.reducer;