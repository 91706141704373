import GermanLegalForms from '../models/enums/GermanLegalForms';
import SectorOptions from '../models/enums/SectorOptions';
import { getKeyByValue } from './helpers';

export function filterSectorsByLegalForm(legalForm: string): string[] {
    let sectors: string[] = [];
    switch(GermanLegalForms[legalForm]) {
        case GermanLegalForms.Ag: 
        case GermanLegalForms.Eg:
        case GermanLegalForms.Gmbh:
        case GermanLegalForms.GmbhCoKg:
        case GermanLegalForms.Kg: 
        case GermanLegalForms.Kgaa:
        case GermanLegalForms.Ohg:
        case GermanLegalForms.Partg:
        case GermanLegalForms.Se:
        case GermanLegalForms.Ug:
        case GermanLegalForms.UgIGr:
            sectors[getKeyByValue(SectorOptions.OtherCompanies, SectorOptions)] = SectorOptions.OtherCompanies;
            return sectors;

        case GermanLegalForms.Ek: 
            sectors[getKeyByValue(SectorOptions.OtherCompaniesWorkman, SectorOptions)] = SectorOptions.OtherCompaniesWorkman;
            sectors[getKeyByValue(SectorOptions.EconomicallySelfEmployed, SectorOptions)] = SectorOptions.EconomicallySelfEmployed;
            return sectors;
        
        case GermanLegalForms.Ltd: 
            sectors[getKeyByValue(SectorOptions.OtherCompanies, SectorOptions)] = SectorOptions.OtherCompanies;
            sectors[getKeyByValue(SectorOptions.OtherCompaniesWorkman, SectorOptions)] = SectorOptions.OtherCompaniesWorkman;
            return sectors;

        case GermanLegalForms.SelfEmployed: 
            sectors[getKeyByValue(SectorOptions.OtherCompaniesWorkman, SectorOptions)] = SectorOptions.OtherCompaniesWorkman;
            sectors[getKeyByValue(SectorOptions.EconomicallySelfEmployed, SectorOptions)] = SectorOptions.EconomicallySelfEmployed;
            return sectors;
   
        default: return sectors;
    }
}