import Header from "../Header/Header";

function App(props: any) {
    return (
        <>
            <Header />
            {props.children}
        </>
    );
}

export default App;
