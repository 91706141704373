import { useTranslation } from 'react-i18next';
import VideoImage from '../../../assets/img/video_ident.png';
import style from './PendingVideoIdentification.module.scss';
import Button from '../../../componentsLibrary/Button/Button';
import ButtonType from '../../../models/enums/ButtonType';
import { useAppDispatch } from '../../../store/store';
import { updateStep } from '../../../reducers/kycProcess';
import Step from '../../../models/enums/Step';

export default function PendingVideoIdentification() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    
    const onBack = () => {
        dispatch(updateStep(Step.Document));
      };
    return <div className={style.main}>     
        <div className={style.header}>
            <p className={style.title}>{t("pendingVideoIdentificationPage.title")}</p>
        </div>   
        <div className={style.content}>
            <img src={VideoImage} alt="pending" className={style.image} />
            <div className={style.title}>{t('pendingVideoIdentificationPage.content.line1')}</div>
            <div className={style.title}>{t('pendingVideoIdentificationPage.content.line2')}</div>
            <div className={style.description}>{t('pendingVideoIdentificationPage.content.description')}</div>
        </div>
        <div className={style.footer}>
            <div className={style.buttonSection}>
              <Button onClick={onBack} type={ButtonType.Button} color="secondary">
                {t("general.back")}
              </Button>
            </div>
          </div> 
    </div>
}