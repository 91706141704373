import { useEffect, useState } from 'react';
import i18n from 'i18next';
import ReactFlagsSelect from 'react-flags-select';

import languageConfig from '../../language/languageConfiguration.json'

import style from './LanguageSelector.module.scss';

export default function LanguageSelector() {
    const defaultSelection = languageConfig.supportedLanguages.filter(item => item.default)[0];
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<string>(defaultSelection.country.toUpperCase());
    const countries = languageConfig.supportedLanguages.map(item => item.country.toUpperCase());

    const onClickHandler = (country: string): void => {
        let language = languageConfig.supportedLanguages.filter(item => item.country.toLowerCase() === country.toLowerCase())[0]?.language;
        setSelectedCountry(country);
        i18n.changeLanguage(language);
        toggleDropDown();
    };

    useEffect(() => {
        i18n.changeLanguage(defaultSelection.language);
    }, [defaultSelection.language]);

    const toggleDropDown = () => {
        setShowDropDown(!showDropDown);
    };

    return <ReactFlagsSelect
        selected={selectedCountry}
        onSelect={(code) => onClickHandler(code)}
        showSelectedLabel={false}
        showOptionLabel={false}
        showSecondaryOptionLabel={false}
        className={style.dropdown}
        countries={countries} //use customlabels to store language and country 
    />;

}