import clsx from 'clsx';
import { PlacesType, Tooltip } from 'react-tooltip';

import style from './CustomTooltip.module.scss';

interface ICustomTooltipProps {
    id: string,
    place?: PlacesType,
    tooltipStyle?: string,
    arrowStyle?: string
}

export default function CustomTooltip(props: ICustomTooltipProps) {
    return <div className={style.container}>
        <Tooltip id={props.id} place={props.place ?? "right"} className={clsx(style.tooltip, props.tooltipStyle)} classNameArrow={clsx(style.arrow, props.arrowStyle)} />
    </div>
}