import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react';
import clsx from 'clsx';

import DialogModal from '../DialogModal/DialogModal';

import InfoImage from '../../assets/img/info.png';
import style from './TextArea.module.scss';

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    id: string,
    label: string,
    required?: boolean,
    name: string,
    info?: string,
    modalContentLabel?: string,
    rows?: number,
    cols?: number,
    error?: boolean,
    errorMessage?: string,
    errorMessageClasses?: string,
    value: string,
    disabled?: boolean,
    onChange?(e: ChangeEvent<HTMLTextAreaElement>): any | undefined
}

const TextArea: FC<ITextAreaProps> = (props: ITextAreaProps) => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    const [text, setText] = useState<string>(props.value);
    const [error, setError] = useState(props.error);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function chainOnChangeEvents(onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void, props: ITextAreaProps) {
        return (e: ChangeEvent<HTMLTextAreaElement>) => {
            onChange(e);
            if (props.onChange) {
                props.onChange(e);
            }
        };
    }

    function onChange(event: ChangeEvent<HTMLTextAreaElement>) {
        setText(event.target.value);
    }

    useEffect(() => {
        setError(props.error);

        if (props.errorMessage !== ' ' || props.errorMessage !== undefined) {
            setErrorMessage(props.errorMessage);
        }

        setText(props.value);
    }, [props.value, props.errorMessage, props.error]);

    return (<>
        <div className={style.inputWrapper} >
            <label htmlFor={props.id} id={props.id} className={style.label}>
                {props.label}
                {props.required &&
                    <span className={style.required}>*</span>
                }
                {props.info &&
                    <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
                }
            </label>
            <textarea
                id={props.id}
                name={props.name}
                rows={props.rows ?? 1}
                cols={props.cols ?? 40}
                className={clsx(style.textarea, error ? style.error : "")}
                value={text}
                disabled={props.disabled}
                onChange={chainOnChangeEvents(onChange, props)}>
            </textarea>
        </div>
        {error && props.errorMessage !== ' ' && props.errorMessage !== undefined &&
            <span className={clsx(style.error, props.errorMessageClasses)}>{errorMessage}</span>
        }
        <DialogModal modalContentLabel={props.modalContentLabel} info={props.info || ""} id={"modalFor_" + props.id} modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </>
    )
};

export default TextArea;