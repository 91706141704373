import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import clsx from "clsx";
import PulseLoader from "react-spinners/PulseLoader";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { createFileDownloadHyperlink } from "../../helpers/htmlElementBuilder";
import { updateStep } from "../../reducers/kycProcess";
import frameworkAgreementService from "../../services/frameworkAgreement.service";

import IFrameworkAgreement from "../../models/IFrameworkAgreement";
import ButtonType from "../../models/enums/ButtonType";
import ProcessStatuses from "../../models/enums/ProcessStatuses";
import Step from "../../models/enums/Step";

import Button from "../../componentsLibrary/Button/Button";
import Checkbox from "../../componentsLibrary/Checkbox/Checkbox";
import Loading from "../../componentsLibrary/Loading/Loading";

import style from "./FrameworkAgreement.module.scss";

export default function FrameworkAgreement() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { processId, status } = useAppSelector((state) => state.process);
  const [frameworkAgreement, setFrameworkAgreement] = useState<IFrameworkAgreement>();   //TODO: Handle translation and file name
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(updateStep(Step.Contract));
    if (status === ProcessStatuses.Completed) {
      setChecked(true);
      setDisabled(true);
    }
    frameworkAgreementService
      .get(processId)
      .then((response: any) => {
        setFrameworkAgreement(response);
      })
      .catch((err) =>{
         if (err.response.status !== 404) { 
          toast.error(t("general.somethingWentWrong") as string);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const onChange: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      downloadFile();
    }
  };

  function base64ToArrayBuffer(base64: any) {
    var binaryString = atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const onBack = () => {
    dispatch(updateStep(Step.Video));
  };

  const downloadFile = () => {
    try {
      var blob = new Blob(
        [base64ToArrayBuffer(frameworkAgreement?.fileBytes)],
        {
          type: "application/pdf",
        }
      );
      let url = window.URL.createObjectURL(blob);
      let downloadElement = createFileDownloadHyperlink(
        url,
        frameworkAgreement?.name as string
      );
      downloadElement.click();
    } catch (ex) {
      toast.error(t("notificationMessages.failedDownload") as string);
    }
  };

  const completeProcess = () => {
    setLoading(true);
    frameworkAgreementService
      .patch(processId)
      .then(() => {
        dispatch(updateStep(Step.ProcessCompleted));
        return;
      })
      .catch((error) =>
        toast.error(
          `${t("general.somethingWentWrong")} ${t(
            "notificationMessages.pleaseTryAgain"
          )}`
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <div className={style.main}>
      <div className={style.header}>
        <p className={style.title}>{t("frameworkAgreementPage.title")}</p>
      </div>
      <Loading show={loading} />
      {frameworkAgreement && (
        <>
          <div className={style.content}>
            <iframe
              src={`data:application/pdf;base64,${frameworkAgreement?.fileBytes}#navpanes=0`}
              loading="lazy"
              name={frameworkAgreement?.name}
              title={frameworkAgreement?.name}
            ></iframe>
          </div>
          <div className={style.footer}>
            <div className={style.checkboxContainer}>
              <Checkbox checked={checked} onChange={onChange} required disabled={disabled}/>
              <p>{t("frameworkAgreementPage.acceptanceText")}</p>
            </div>
            <div className={style.buttonSection}>
              <Button onClick={onBack} type={ButtonType.Button} color="secondary">
                  {t("general.back")}
              </Button>
              <Button
                disabled={!checked || disabled}
                onClick={completeProcess}
              >
                {t("frameworkAgreementPage.finalizeProcessButton")}
              </Button>
            </div>
          </div>
        </>
      )}
      {!frameworkAgreement && <>
        <div className={style.pending}>
            <PulseLoader color="#332B78" loading={true} aria-label="Loading Spinner" data-testid="loader" />
            <div className={style.text}>{t("frameworkAgreementPage.generatingFrameworkAgreement")}</div>       
         
        </div>
         <div className={clsx(style.footer, style.pending)}>
            <div className={style.buttonSection}>
              <Button onClick={onBack} type={ButtonType.Button} color="secondary">
                {t("general.back")}
              </Button>
            </div>
         </div>
      </>}
    </div>
  );
}
