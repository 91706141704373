import { SeverityLevel } from "@microsoft/applicationinsights-web";

import httpService from "./http.service";
import { appInsights } from "../configuration/appInsigths";

import IOrganisationPutRequest from "../models/IOrganisationPutRequest";
import IOrganisationPostRequest from "../models/IOrganisationPostRequest";
import IOrganisationResponse from "../models/IOrganisationResponse";

class OrganisationService {
  async get(processId: string): Promise<IOrganisationResponse> {
    const url = `process/${processId}/organisation`;

    return httpService
      .getAxiosClient()
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
        throw err;
      });
  }

  async post(organisation: IOrganisationPostRequest): Promise<IOrganisationResponse> {
    const url = `organisation`;

    return httpService
      .getAxiosClient()
      .post(url, organisation)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
        throw err;
      });
  }

  async put(organisation: IOrganisationPutRequest): Promise<IOrganisationResponse> {
    const url = `organisation/${organisation.id}`;

    return httpService
      .getAxiosClient()
      .put(url, organisation)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
        throw err;
      });
  }

  async putOutsideAgreements(organisationId: string, hasOutsideAgreements: boolean): Promise<IOrganisationResponse> {
    const url = `organisation/${organisationId}/outsideagreements`;
    const payload = {
      hasOutsideAgreements: hasOutsideAgreements
    };

    return httpService
      .getAxiosClient()
      .put(url, payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
        throw err;
      });
  }
}

const service = new OrganisationService();
export default service;
