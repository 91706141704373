import { useTranslation } from 'react-i18next';
import MessageTypeOptions from '../../models/enums/MessageTypeOptions';
import GeneralMessage from '../GeneralMessage/GeneralMessage';


export default function ProcessRejected(props: any) {
    const { t } = useTranslation();

    return <> 
        <GeneralMessage
            type={MessageTypeOptions.KycRejected}
            messages={[t('processRejectedPage.rejectedMessageLine1'), t('processRejectedPage.rejectedMessageLine2')]}
        >
        </GeneralMessage>
    </>;
}