import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISellerEligibility } from '../models/ISellerEligibility';
import SellerPreEligibilityStatus from '../models/enums/SellerPreEligibilityStatus';
import { ISellerPreEligibilityRequest } from '../models/ISellerPreEligibilityRequest';
import { RootState } from './index';
import sellerService from '../services/seller.service';

const initialState: ISellerEligibility = {
  isEligible: false,
  sellerPreEligibilityStatus: SellerPreEligibilityStatus.Unset,
  sellerId: null
}

export const getSellerPreEligibilityStatus = createAsyncThunk(
  'seller/get',
  async (sellerPreEligibilityPayload: ISellerPreEligibilityRequest) => {
    return await sellerService.get(sellerPreEligibilityPayload);
  }
);

const sellerEligibilitySlice = createSlice({
  name: 'sellerEligibility',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<ISellerEligibility>) => {
      state.isEligible = action.payload.isEligible;
    },
    updateSellerId: (state, action: PayloadAction<string>) => {
      state.sellerId = action.payload;
    },
    updatePreEligibilityStatus: (state, action: PayloadAction<SellerPreEligibilityStatus>) => {
      state.sellerPreEligibilityStatus = action.payload;
    },
    updateIsEligible: (state, action: PayloadAction<boolean>) => { 
      state.isEligible = action.payload;
    }

  },
  extraReducers: (builder) => {
      builder.addCase(getSellerPreEligibilityStatus.fulfilled, (state, action) => {
      state.sellerPreEligibilityStatus = action.payload;
      return state;
    })
  }
});

export const { update, updateSellerId, updatePreEligibilityStatus, updateIsEligible } = sellerEligibilitySlice.actions

export const sellerEligibility = (state: RootState) => state.sellerEligibility

export default sellerEligibilitySlice.reducer