import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../configuration/appInsigths";
import httpService from "./http.service";

class UserService {
  async isAuthorized(email: string, sellerId: string): Promise<boolean> {
    const mdmSellerId = `mdm://organisation@sparta/${sellerId}`;
    let url = `user/${email}?partnerOrganisationId=${mdmSellerId}`;

    return httpService
      .getAxiosClient()
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }
}

const service = new UserService();
export default service;
