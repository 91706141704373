import { getIsoDateTime } from "../helpers/helpers";
import ActionType from "../models/enums/ActionType";
import TypeOfRepresentationOptions from "../models/enums/TypeOfRepresentationOptions";
import { ILegalRepresentative } from "../models/ILegalRepresentative";
import ILegalRepresentativePostRequest from "../models/ILegalRepresentativePostRequest";
import ILegalRepresentativePutRequest from "../models/ILegalRepresentativePutRequest";
import ILegalRepresentativeResponse from "../models/ILegalRepresentativeResponse";

export function mapToPostRequest(legalRepresentative: ILegalRepresentative): ILegalRepresentativePostRequest {
  let isoDateTime = getIsoDateTime(new Date(legalRepresentative.birthDate));
  
  return {
      partnerUserId: legalRepresentative.partnerUserId, // to used email address of logged in user
      birthCity: legalRepresentative.birthCity,
      address: legalRepresentative.address,
      email: legalRepresentative.email,
      mobileNumber: legalRepresentative.mobileNumber,
      isBeneficialOwner: legalRepresentative.isBeneficialOwner,
      representationType:  Object.keys(TypeOfRepresentationOptions)[Object.values(TypeOfRepresentationOptions).indexOf(legalRepresentative.representationType as TypeOfRepresentationOptions)], 
      salutation: legalRepresentative.salutation,
      givenName: legalRepresentative.givenName,
      familyName: legalRepresentative.familyName,
      birthDate: isoDateTime,
      nationality: legalRepresentative.nationality,
      isConductingKycProcess: legalRepresentative.isConductingKycProcess as boolean
  }
}

export function mapToPutRequest(legalRepresentative: ILegalRepresentative): ILegalRepresentativePutRequest {
  let isoDateTime = getIsoDateTime(new Date(legalRepresentative.birthDate));
  
  return { 
    id: legalRepresentative.id,
    partnerUserId: legalRepresentative.partnerUserId, 
    birthCity: legalRepresentative.birthCity,
    address: legalRepresentative.address,
    email: legalRepresentative.email,
    mobileNumber: legalRepresentative.mobileNumber,
    isBeneficialOwner: legalRepresentative.isBeneficialOwner,
    representationType:  Object.keys(TypeOfRepresentationOptions)[Object.values(TypeOfRepresentationOptions).indexOf(legalRepresentative.representationType as TypeOfRepresentationOptions)], 
    salutation: legalRepresentative.salutation,
    givenName: legalRepresentative.givenName,
    familyName: legalRepresentative.familyName,
    birthDate: isoDateTime,
    nationality: legalRepresentative.nationality,
    isConductingKycProcess: legalRepresentative.isConductingKycProcess as boolean
  };
}

export function mapToModel(legalRepresentative: ILegalRepresentativeResponse): ILegalRepresentative {
  return { 
    id: legalRepresentative.id,
    partnerUserId: legalRepresentative.partnerUserId, 
    birthCity: legalRepresentative.birthCity,
    address: legalRepresentative.address,
    email: legalRepresentative.email,
    mobileNumber: legalRepresentative.mobileNumber,
    isBeneficialOwner: legalRepresentative.isBeneficialOwner,
    representationType: legalRepresentative.representationType.toUpperCase(),
    salutation: legalRepresentative.salutation,
    givenName: legalRepresentative.givenName,
    familyName: legalRepresentative.familyName,
    birthDate: legalRepresentative.birthDate,
    nationality: legalRepresentative.nationality,
    isConductingKycProcess: legalRepresentative.isConductingKycProcess,
    actionType: ActionType.Updated
  };
}
