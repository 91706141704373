//base component: https://catamphetamine.gitlab.io/react-phone-number-input/

import clsx from 'clsx';
import { FC, InputHTMLAttributes, useEffect, useState } from 'react';
import PhoneInputWithCountrySelect from 'react-phone-number-input';

import DialogModal from '../DialogModal/DialogModal';

import InfoImage from '../../assets/img/info.png';
import style from './PhoneInput.module.scss';
import 'react-phone-number-input/style.css';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string,
    info?: string,
    modalContentLabel?: string,
    value: any | undefined,
    name: string,
    error?: boolean,
    errorMessage?: string,
    errorMessageClasses?: string,
    onChange?(e: any): any | undefined,
    disabled?: boolean
}

const CustomPhoneInput: FC<IInputProps> = (props: IInputProps) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(props.value);
    const [error, setError] = useState(props.error);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function onChange(event: any) {
        setValue(event);
    }

    useEffect(() => {

        setError(props.error);

        if (props.errorMessage !== ' ' || props.errorMessage !== undefined) {
            setErrorMessage(props.errorMessage);
        }

        setValue(props.value);

    }, [props.value, props.errorMessage, props.error]);

    function chainOnChangeEvents(onChange: (event: any) => void) {
        return (e: any) => {
            onChange(e);

            if (props.onChange) {
                props.onChange(e);
            }
        };
    }

    return (<>
        <div className={style.inputWrapper} >
            <label htmlFor={props.id} id={props.id} >
                {props.label}
                {props.required &&
                    <span className={style.required}>*</span>
                }
                {props.info &&
                    <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
                }
            </label>
            <PhoneInputWithCountrySelect
                id={props.id}
                value={value}
                name={props.name}
                placeholder={props.placeholder}
                className={clsx(style.input, error ? style.error : "", props.disabled ? style.disabled : "")}
                onChange={chainOnChangeEvents(onChange)}
                disabled={props.disabled}
            />
        </div>
        {error && props.errorMessage !== ' ' && props.errorMessage !== undefined &&
            <span className={clsx(style.error, props.errorMessageClasses)}>{errorMessage}</span>
        }
        <DialogModal modalContentLabel={props.modalContentLabel} info={props.info || ""} id={"modalFor_" + props.id} modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </>
    )
};

export default CustomPhoneInput;