import { InputHTMLAttributes, useState } from 'react';

import DialogModal from '../DialogModal/DialogModal';
import clsx from 'clsx';
import InfoImage from '../../assets/img/info_white.png';

import style from './FormPage.module.scss';

interface IFormPage extends InputHTMLAttributes<HTMLInputElement> {
    title: string,
    info?: string,
    mainClasses?: string,
    titleClasses?: string,
    headerClasses?: string,
}

export default function FormPage(props: IFormPage) {
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <div className={clsx(style.main, props.mainClasses)}>
                <div className={clsx(style.header, props.headerClasses)}>
                    <p className={clsx(style.title, props.titleClasses)}>{props.title}
                        {
                            props.info && <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
                        }
                    </p>
                </div>
                {props.children}
            </div>
            <DialogModal modalContentLabel={""} info={props.info} id={"modalFor_page"} modalIsOpen={modalIsOpen} closeModal={closeModal} />
        </>
    )
};
