import { Routes, Route, Navigate, BrowserRouter as Router } from "react-router-dom";
import { useTranslation } from "react-i18next";

import GeneralMessage from "./GeneralMessage/GeneralMessage";
import App from './App/App';
import Main from './Main/Main';
import AuthenticatedRoute from "../authentication/AuthenticatedRoute";
import MessageTypeOptions from "../models/enums/MessageTypeOptions";

function AppRouter(): JSX.Element {
    const { t } = useTranslation();

    return <App>
        <Router>
            <Routes>
                <Route path="/" element={<AuthenticatedRoute><Main /></AuthenticatedRoute>} />
                <Route path="/not-found" element={<GeneralMessage messages={[t('errorsPage.404')]} type={MessageTypeOptions.Error404} />} />
                <Route path="/*" element={<Navigate to="/not-found" replace />} />
            </Routes>
        </Router>
    </App>;
}

export default AppRouter;