import { ChangeEvent, FC, SelectHTMLAttributes, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import DialogModal from '../DialogModal/DialogModal';
import InfoImage from '../../assets/img/info.png';
import style from './Dropdown.module.scss';
import CustomTooltip from '../Tooltip/CustomTooltip';

interface IDropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
    id: string,
    label: string,
    name: string,
    info?: string,
    modalContentLabel?: string,
    options: Object,
    required?: boolean,
    value: string,
    onChange?(e: ChangeEvent<HTMLSelectElement>): any | undefined,
    selectWrapperClasses?: string,
    selectClasses?: string,
    optionClasses?: string
    disabled?: boolean,
    error?: boolean,
    errorMessage?: string
    errorMessageClasses?: string
    selectTooltip?: string
}

const Dropdown: FC<IDropdownProps> = (props: IDropdownProps) => {
    const { t } = useTranslation();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(props.error);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        setError(props.error);

        if (props.errorMessage !== ' ' || props.errorMessage !== undefined) {
            setErrorMessage(props.errorMessage);
        }
    }, [props.errorMessage, props.error]);

    return (<>
        <div className={style.inputWrapper} >
            <label htmlFor={props.id} id={props.id} className={style.label}>
                {props.label}
                {props.required &&
                    <span className={style.required}>*</span>
                }
                {props.info &&
                    <img src={InfoImage} alt="info" className={style.info} onClick={openModal} />
                }
            </label>
            <div
                className={clsx(style.selectWrapper, error ? style.error : "", props.selectWrapperClasses)}
                data-tooltip-id="select-tooltip"
                data-tooltip-content={props.selectTooltip}
            >
                <select
                    name={props.name}
                    id={props.id}
                    onChange={props.onChange}
                    value={t(props.value ?? "")}
                    className={clsx(style.select, props.selectClasses, { [style.disabled]: props.disabled })}
                    disabled={props.disabled}
                >
                    <option
                        value=""
                        className={clsx(style.option, style.item, props.optionClasses)}
                        disabled={true}
                    >
                        {t("general.pleaseSelectAValue")}
                    </option>
                    {Object.entries(props.options).map((option, index) => {
                        return <option value={option[0]} className={style.item} key={index}>{t(option)}</option>
                    })
                    }
                </select>
                {
                    props.selectTooltip &&
                    <CustomTooltip id="select-tooltip" />
                }
            </div>
        </div >
        {error && props.errorMessage !== ' ' && props.errorMessage !== undefined &&
            <span className={clsx(style.error, props.errorMessageClasses)}>{errorMessage}</span>
        }
        <DialogModal modalContentLabel={props.modalContentLabel} info={props.info || ""} id={"modalFor_" + props.id} modalIsOpen={modalIsOpen} closeModal={closeModal} />

    </>
    )
};

export default Dropdown;