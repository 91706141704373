enum TaxCountryOptions {
    AT = "organisationPage.taxCountry.values.AT",
    BE = "organisationPage.taxCountry.values.BE",
    CZ = "organisationPage.taxCountry.values.CZ",
    FR = "organisationPage.taxCountry.values.FR",
    DE = "organisationPage.taxCountry.values.DE",
    IT = "organisationPage.taxCountry.values.IT",
    LU = "organisationPage.taxCountry.values.LU",
    PT = "organisationPage.taxCountry.values.PT",
    ES = "organisationPage.taxCountry.values.ES",
    NL = "organisationPage.taxCountry.values.NL",
    GB = "organisationPage.taxCountry.values.GB"
}

export default TaxCountryOptions;