import clsx from 'clsx';
import style from './FormFooter.module.scss';

export default function FormFooter(props: any) {
    return (
        <div className={clsx(style.footer, props.footerClasses)}>
            {props.children}
        </div>
    )
};
