import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import { AppInsightsErrorBoundary, AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import AuthenticationProvider from './authentication/AuthenticationProvider';
import AppRouter from './components/AppRouter';
import Error from './components/Error/Error';
import reportWebVitals from './reportWebVitals';
import i18n from './language/i18n';
import store from './store/store';

import { reactPlugin } from './configuration/appInsigths';

import './theme/index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';


let rootHtmlElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(
    rootHtmlElement
);

Modal.setAppElement(rootHtmlElement);

root.render(
    <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary appInsights={reactPlugin} onError={() => Error({ message: i18n.t('general.somethingWentWrong') })}>
            <AuthenticationProvider>
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <AppRouter />
                    </Provider>
                </I18nextProvider>
                <ToastContainer position="top-right"
                    autoClose={4500}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                    theme="light" />
            </AuthenticationProvider>
        </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
);

reportWebVitals();