import httpService from "./http.service";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

import { appInsights } from "../configuration/appInsigths";

import IBeneficialOwnerPutRequest from "../models/IBeneficialOwnerPutRequest";
import IBeneficialOwnerPostRequest from "../models/IBeneficialOwnerPostRequest";
import IBeneficialOwnerGetAllResponse from "../models/IBeneficialOwnerGetAllResponse";

class BeneficialOwnerService {
  async getAll(processId: string): Promise<IBeneficialOwnerGetAllResponse> {
    const url = `process/${processId}/beneficialowners`;
    return httpService
      .getAxiosClient()
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }

  async post(processId: string, beneficialOwner: IBeneficialOwnerPostRequest) {
    const url = `process/${processId}/beneficialowner`;

    return httpService
      .getAxiosClient()
      .post(url, beneficialOwner)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }

  async delete(beneficialOwnerId: string) {
    const url = `beneficialowner/${beneficialOwnerId}`;

    return httpService
      .getAxiosClient()
      .delete(url)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }

  async put(beneficialOwner: IBeneficialOwnerPutRequest) {
    const url = `beneficialowner/${beneficialOwner.id}`;

    return httpService
      .getAxiosClient()
      .put(url, beneficialOwner)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      });
  }
}

const service = new BeneficialOwnerService();
export default service;
