import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './index';
import { ILegalRepresentative } from '../models/ILegalRepresentative';
import { mapToModel, mapToPostRequest, mapToPutRequest } from '../mappers/legalRepresentative.mapper';
import ActionType from '../models/enums/ActionType';
import legalRepresentativeService from '../services/legalRepresentative.service';
import ILegalRepresentativePutRequest from '../models/ILegalRepresentativePutRequest';
import ILegalRepresentativePostRequest from '../models/ILegalRepresentativePostRequest';
import { ILegalRepresentatives } from '../models/ILegalRepresentatives';

const initialState: ILegalRepresentative[] = [];

export const getLegalRepresentatives = createAsyncThunk(
  'legalRepresentatives/getAll',
  async (processId: string): Promise<ILegalRepresentative[]> => {
    return (await legalRepresentativeService.getAll(processId)).map(mapToModel);
  }
);

export const processLegalRepresentatives = createAsyncThunk(
  'legalRepresentatives/process',
  async (request: ILegalRepresentatives) => {
    if (request.legalRepresentativesToBeRemoved) {
      await Promise.all(request.legalRepresentativesToBeRemoved.map(async (legalRepresentative: ILegalRepresentative) => {
        if (legalRepresentative?.id) {
          return await legalRepresentativeService.delete(legalRepresentative.id);
        }
      }));
    }
    await Promise.all(request.legalRepresentatives.map(async (legalRepresentative: ILegalRepresentative) => {
      switch (legalRepresentative.actionType) {
        case ActionType.Added: {
          let legalRepresentativePostRequest: ILegalRepresentativePostRequest = mapToPostRequest(legalRepresentative);
          return await legalRepresentativeService.post(request.processId, legalRepresentativePostRequest);
        };

        case ActionType.Updated: {
          let legalRepresentativePutRequest: ILegalRepresentativePutRequest = mapToPutRequest(legalRepresentative);
          return await legalRepresentativeService.put(legalRepresentativePutRequest);
        };
      }
    }));
  }
);

const legalRepresentativeSlice = createSlice({
  name: 'legalRepresentatives',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLegalRepresentatives.fulfilled, (state, action) => {
      state = action.payload;
    })
  }
});


export const selectLegalRepresentatives = (state: RootState) => state.legalRepresentatives;

export default legalRepresentativeSlice.reducer;