import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../../store/store';

import { updateStatus, updateStep } from '../../reducers/kycProcess';

import Step from '../../models/enums/Step';
import ProcessStatuses from '../../models/enums/ProcessStatuses';

import Button from '../../componentsLibrary/Button/Button';
import Checkbox from '../../componentsLibrary/Checkbox/Checkbox';

import SearchImage from '../../assets/img/search.png';
import SolarisLogo from '../../assets/img/logo_solaris.png';
import style from './LandingPage.module.scss';

export default function LandingPage() {
    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
    const { status, step } = useAppSelector((state) => state.process);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onStartProcess = (): void => {
        switch(Number(status)) {
            case ProcessStatuses.Draft:
            case ProcessStatuses.Completed:   { dispatch(updateStep(Step.Organisation)); return; };  //take into account process completion

            case ProcessStatuses.ConfirmedByUser:
            case ProcessStatuses.SubmittedToProvider: 
            case ProcessStatuses.LegalIdentificationPassed:
            case ProcessStatuses.ApprovedByProvider: { dispatch(updateStep(Step.Video)); return; }

            case ProcessStatuses.Rejected: { dispatch(updateStep(Step.ProcessRejected)); return; };

            case ProcessStatuses.New: {
                if (!termsAndConditionsChecked) {
                    toast.info(t('landingPage.checkMandatory') as string);
                    break;
                } else {
    
                    dispatch(updateStatus(ProcessStatuses.Draft));
                    dispatch(updateStep(Step.SellerEligibility));
                    return;
                }
            };
            default: return navigate('/not-found');
        }
    };

    const onTermsAndConditionsCheckboxChange: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
        setTermsAndConditionsChecked(event.target.checked);
    };

    return <div className={style.main}>
       
        {(status === ProcessStatuses.New) &&
            <>
                <h1 className={style.title}>{t('landingPage.title')}</h1>
                <img src={SearchImage} className={style.image} alt="search" />
                <div className={style.text}>
                    <p className={style.description}>{t('landingPage.description')}</p>
                    <p className={style.dataProcessingAgreement} dangerouslySetInnerHTML={{
                        __html: t('landingPage.dataProcessingAgreement.text',
                            {
                                dataProcessingAgreement: `<a href="${t('landingPage.dataProcessingAgreement.url')}" target="_blank" rel="noopener noreferrer" >${t('landingPage.dataProcessingAgreement.linkText')}</a>`
                            }
                        )
                    }}
                    />
                    <div className={style.checkboxSection}>
                        <Checkbox checked={termsAndConditionsChecked} onChange={onTermsAndConditionsCheckboxChange} required />
                        <p className={style.termsAndConditions} dangerouslySetInnerHTML={{
                            __html: t('landingPage.termsAndConditions.text',
                                {
                                    termsAndConditions: `<a href="${t('landingPage.termsAndConditions.termsAndConditionsUrl')}" target="_blank" rel="noopener noreferrer" >${t('landingPage.termsAndConditions.termsAndConditionsText')}</a>`
                                }
                            )
                        }}
                        />
                    </div>
                    <Button classes={style.landingPageButton} disabled={!termsAndConditionsChecked} onClick={onStartProcess}>{t('landingPage.startProcess')}</Button>
                </div>
            </>
        }
        {(status !== ProcessStatuses.New) &&
            <div className={style.padding}>
                <h1 className={style.title}>{t('landingPage.title')}</h1>
                <img src={SearchImage} className={style.image} alt="search" />
                <div className={style.resumeButtonPadding}>
                    <Button classes={style.landingPageButton} onClick={onStartProcess}>{t('landingPage.resumeProcess')}
                    </Button>
                </div>
            </div>
        }
        <img src={SolarisLogo} className={style.solarisLogo} alt="solaris" />
    </div>;
}