import "@vaadin/upload";
import clsx from 'clsx';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import httpService from "../../services/http.service";
import { useAuthenticationClient } from "../../authentication/AuthenticationProvider";
import { getDocumentType } from "../../helpers/helpers";

import IDocument from "../../models/IDocument";

import Button from "../../componentsLibrary/Button/Button";

import DocumentIcon from "../../assets/img/file-text.svg";
import CheckedIcon from "../../assets/img/checked.png";
import FailedIcon from "../../assets/img/error.png";
import RemoveIcon from "../../assets/img/trash.svg";

import style from "./FileUpload.module.scss";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "vaadin-upload": any;
    }
  }
}

interface FileUploadProps {
  id?: string;
  required: boolean;
  label: string;
  metadata: IDocument;
  onUpload?: React.MouseEventHandler<HTMLButtonElement> | any;
  onDelete?: React.MouseEventHandler<HTMLButtonElement> | any;
  processId: string,
  disabled: boolean
}

enum FileUploadState {
  Successful,
  Failed,
  NotUploaded,
}

export default function FileUpload(props: FileUploadProps) {
  const { t } = useTranslation();
  let authenticationClient = useAuthenticationClient();
  let [fileUploadState, setFileUploadState] = useState(props.metadata.id ? FileUploadState.Successful : FileUploadState.NotUploaded);
  let [filename, setFilename] = useState(props.metadata.name || "");
  const [uploadError, setUploadError] = useState<string>("");

  useEffect(() => {
    let uploadContainer = document.querySelector(`vaadin-upload#${props.id}`);
    uploadContainer?.addEventListener("upload-response", handleUploadResponse);
    uploadContainer?.addEventListener("upload-error", handleUploadError);
    uploadContainer?.addEventListener("file-abort", handleDeleteFile);
    uploadContainer?.addEventListener("file-reject", handleUploadError);
    uploadContainer?.addEventListener("upload-start", hideUploadButton);
    uploadContainer?.addEventListener("upload-request", requestStarted);

    //See comment at line 213
    const script = document.createElement("script");
    script.type = 'text/javascript';
    script.async = true;

    if (uploadContainer) {
      script.innerHTML = `document.querySelector('vaadin-upload#${props.id}').i18n={"dropFiles":{"one":"${t('documentsPage.labels.dropFiles.one')}","many":"${t('documentsPage.labels.dropFiles.many')}"},"addFiles":{"one":"${t('documentsPage.labels.addFiles.one')}","many":"${t('documentsPage.labels.addFiles.many')}"}, "error":{"tooManyFiles":"${t('documentsPage.labels.error.tooManyFiles')}","fileIsTooBig":"${t('documentsPage.labels.error.fileIsTooBig')}","incorrectFileType":"${t('documentsPage.labels.error.incorrectFileType')}"},"uploading":{"status":{"connecting":"${t('documentsPage.labels.uploading.status.connecting')}","stalled":"${t('documentsPage.labels.uploading.status.stalled')}","processing":"${t('documentsPage.labels.uploading.status.processing')}","held":"${t('documentsPage.labels.uploading.status.held')}"},"remainingTime":{"prefix":"${t('documentsPage.labels.uploading.remainingTime.prefix')}","unknown":"${t('documentsPage.labels.uploading.remainingTime.unknown')}"},"error":{"serverUnavailable":"${t('documentsPage.labels.uploading.error.serverUnavailable')}","unexpectedServerError":"${t('documentsPage.labels.uploading.error.unexpectedServerError')}","forbidden":"${t('documentsPage.labels.uploading.error.forbidden')}"}},"units":{"size":["B","kB","MB","GB","TB","PB","EB","ZB","YB"],sizeBase: 1000}}`;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
  }, [props.metadata.id]);

  const requestStarted = (event: Event): void => {
    setUploadError("");

    let customEvent = event as CustomEvent;
    let type = getDocumentType(props.metadata.documentType);
    let fileName = customEvent.detail.file.name;
    setFilename(fileName);
    let token: any = '';

    if (authenticationClient.isAuthenticated()) {
      token = authenticationClient.getToken();
    }

    let metadata = { processId: props.processId, documentType: type, name: fileName };
    customEvent.detail.xhr.setRequestHeader("Metadata", JSON.stringify(metadata));
    customEvent.detail.xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  }

  const hideUploadButton = (): void => {
    let button = document.querySelector(`#upload-${props.id}`) as HTMLElement;
    if (button != null) {
      button.style.display = "none";
    }
  };

  const showUploadButton = (): void => {
    let button = document.querySelector(`#upload-${props.id}`) as HTMLElement;
    if (button != null) {
      button.style.display = "block";
    }
  };

  const handleUploadError = (event): void => {
    setUploadError(event.detail.error);
    setFileUploadState(FileUploadState.Failed);
  };

  const handleUploadResponse = (event: Event): void => {
    let customEvent = event as CustomEvent;
    if (customEvent.detail.xhr.status === 201 || customEvent.detail.xhr.status === 200) {
      setFileUploadState(FileUploadState.Successful);

      let updatedDocument = { ...props.metadata };
      updatedDocument.name = customEvent.detail.file.name;

      props.onUpload(updatedDocument);
    } else {
      setFileUploadState(FileUploadState.Failed);
    }
  };

  const handleDeleteFile = (): void => {
    if (props.disabled) {
      return;
    }
    props.onDelete(props.metadata);
    setFileUploadState(FileUploadState.NotUploaded);
    showUploadButton();
  };

  const maxFileSizeInMB = 50;
  const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024;

  //The MIME Types causes additional sub-types to be added, like *.dot for Word, and some other extensions that might confuse a user, as they are not so common, like *.jfij (JPEG File Interchange Format (JFIF)) 
  //const allowedFileExtensions = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png";
  
  const allowedFileExtensions = ".pdf, .doc, .docx, .jpeg, .jpg, .png";

  // const translatedLabels: UploadI18n = {
  //   dropFiles: {
  //     one: t('documentsPage.labels.dropFiles.one'),
  //     many: t('documentsPage.labels.dropFiles.many')
  //   },
  //   addFiles: {
  //     one: t('documentsPage.labels.addFiles.one'),
  //     many: t('documentsPage.labels.addFiles.many')
  //   },
  //   error: {
  //     tooManyFiles: t('documentsPage.labels.error.tooManyFiles'),
  //     fileIsTooBig: t('documentsPage.labels.error.fileIsTooBig'),
  //     incorrectFileType: t('documentsPage.labels.error.incorrectFileType')
  //   },
  //   uploading: {
  //     status: {
  //       connecting: t('documentsPage.labels.uploading.status.connecting'),
  //       stalled: t('documentsPage.labels.uploading.status.stalled'),
  //       processing: t('documentsPage.labels.uploading.status.processing'),
  //       held: t('documentsPage.labels.uploading.status.held')
  //     },
  //     remainingTime: {
  //       prefix: t('documentsPage.labels.uploading.remainingTime.prefix'),
  //       unknown: t('documentsPage.labels.uploading.remainingTime.unknown')
  //     },
  //     error: {
  //       serverUnavailable: t('documentsPage.labels.uploading.error.serverUnavailable'),
  //       unexpectedServerError: t('documentsPage.labels.uploading.error.unexpectedServerError'),
  //       forbidden: t('documentsPage.labels.uploading.error.forbidden')
  //     }
  //   },
  //   units: {
  //     size: ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
  //     sizeBase: 1000
  //   }
  // }

  return (
    <div id={props.id} className={style.main}>
      <div className={clsx(style.description, style.disabled)}>
        {fileUploadState === FileUploadState.NotUploaded && (
          <img src={DocumentIcon} className={style.icon} alt="file" />
        )}
        {fileUploadState === FileUploadState.Successful && (
          <img src={CheckedIcon} className={style.icon} alt="file" />
        )}
        {fileUploadState === FileUploadState.Failed && (
          <img src={FailedIcon} className={style.icon} alt="file" />
        )}
        <div className={style.text}>
          <span className={style.title}>
            {t(`${props.metadata.documentType}`)}{" "}
            {props.required && <span className={style.required}>*</span>}
          </span>
          <span className={style.subTitle}>{t(`${props.label}`)}</span>
        </div>
      </div>
      {props.metadata.id && (
        <div className={style.uploadContainer}>
          <div className={style.fileName}>{filename}</div>
          {!props.disabled &&
          <img
            src={RemoveIcon}
            className={style.removeIcon}
            alt="file"
            onClick={handleDeleteFile}
          />}
        </div>
      )}
      {props.metadata.id === undefined && !props.disabled && (
        <vaadin-upload
          id={props.id}
          method="POST"
          accept={allowedFileExtensions}
          target={httpService.uploadBaseUrl}
          max-files="1"
          noDrop
          max-file-size={maxFileSizeInBytes}
          disabled={props.disabled}

        //i18n={translatedLabels} <= causes  Uncaught TypeError: Cannot read properties of undefined (reading 'one') at Upload._i18nPlural (vaadin-upload.js:880:1) at runMethodEffect (property-effects.js:1048:1) at Upload._evaluateBinding. Every other tentative of assigning translatedLabel object directly into this component fails. A valid implementation is at line 61.  TODO Remove this comment once the issue is resolved or if we stick with the current implementation
        >
          <Button id={`upload-${props.id}`} slot="add-button">
            {t('documentsPage.uploadButton')}
          </Button>
          {uploadError && uploadError !== '' &&
            <span className={clsx(style.error)}>{uploadError}</span>
          }
        </vaadin-upload>
      )}



    </div>
  );
}
