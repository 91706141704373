import {combineReducers} from "redux";
import process from "./kycProcess";
import sellerEligibility from "./sellerEligibility";
import azureAddresses from "./azureAddress";
import organisation from "./organisation";
import legalRepresentatives from "./legalRepresentative";
import timelineProgress from './timelineProgress';
import beneficialOwners from "./beneficialOwner";
import documents from './documents';

const appReducer = combineReducers({
    process,
    sellerEligibility,
    azureAddresses,
    timelineProgress,
    organisation,
    legalRepresentatives,
    beneficialOwners,
    documents
});

const rootReducer = (state:any, action: any) => {
    if(action.type === "CLEAR_STATE")
    state = undefined;
 
    return appReducer(state, action);
}

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
