import IAddress, { AddressValidationResult } from '../models/IAddress';
import { IAzureAddressResponse } from '../models/IAzureAddress';

export function validateAddress(address: IAddress, azureAddressResponse: IAzureAddressResponse, responseType: string): AddressValidationResult {
    if (!address && !azureAddressResponse && !responseType) {
        return { address: null, isValid: false };
    }

    if (responseType === "addresses/getAddress/fulfilled") {
        if (azureAddressResponse.summary?.totalResults === 0) {
            return { address: null, isValid: false };
        }

        if (!azureAddressResponse.results) {
            return { address: null, isValid: false };
        }

        let highestScoreAddress = azureAddressResponse.results.filter(a => a.type !== "Cross Street")[0].address;

        if (highestScoreAddress?.countryCode?.toLowerCase() !== address.countryCode?.toLowerCase() ||
            highestScoreAddress?.streetNumber?.toLowerCase() !== address.streetNumber?.toLowerCase() ||
            highestScoreAddress?.postalCode === undefined || highestScoreAddress?.postalCode?.toLowerCase() !== address.postalCode?.toLowerCase() ||
            highestScoreAddress?.municipality === undefined || !highestScoreAddress?.municipality?.toLowerCase().includes(address.city?.toLowerCase()) ||
            highestScoreAddress?.streetName === undefined || !highestScoreAddress?.streetName?.toLowerCase().includes(address.streetName?.toLowerCase())) {

                return {
                    address: {
                        city: highestScoreAddress?.municipality ?? "",
                        postalCode: highestScoreAddress?.postalCode ?? "",
                        countryCode: highestScoreAddress?.countryCode ?? "",
                        streetName: highestScoreAddress?.streetName ?? "",
                        streetNumber: highestScoreAddress?.streetNumber ?? ""
                    },
                    isValid: false
                };
        }
    }

    if (responseType === "addresses/getAddress/rejected") {
        return { address: null, isValid: false };
    }

    return { address: address, isValid: true };
};

