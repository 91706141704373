

const createFileDownloadHyperlink = (filePath: string, fileName: string): HTMLAnchorElement => {
    let alink = document.createElement('a');
    alink.href = filePath;
    alink.download = fileName;
    return alink;
}


export {
    createFileDownloadHyperlink
}