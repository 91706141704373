import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSelector from '../LanguageSelector/LanguageSelector';

import LogoImage from '../../assets/img/logo_banqup.png';
import style from './Header.module.scss';
import { useAuthenticationClient } from '../../authentication/AuthenticationProvider';

export default function Header(): JSX.Element {
    const { t } = useTranslation();
    let [open, setOpen] = useState(false);
    let authenticationClient = useAuthenticationClient();

    const getAcronym = () => {
        let user = authenticationClient.getCurrentUser();
        return `${user?.firstName?.charAt(0).toLocaleUpperCase()}${user?.lastName?.charAt(0).toLocaleUpperCase()}`;
    }

    const handleDropdown = (): void => {
        setOpen(!open);
    }

    return <div className={style.header}>
        <img src={LogoImage} alt="logo" className={style.image} />
       
        <div className={style.account}>
            <div className={style.title}>
                {t('header.title')}
            </div>
            {/* Temporary solution since we don't know yet if we will support logout */}
           {authenticationClient.isAuthenticated() && 
                <div className={style.accountMenuContainer}>
                    <button className={style.accountMenu} onClick={handleDropdown}>{getAcronym()}</button>
                    {/* {open && <ul className={style.menu}>
                            <li className={style.menuItem}>
                                <button onClick={() => authenticationClient.logout()}>Logout</button>
                            </li>
                        </ul> 
                    } */}
                </div>
           }      
            <LanguageSelector />
        </div>
    </div>;
}